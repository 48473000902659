<div class="re-absolute re-top-0 re-left-0 re-bg-white re-w-full re-h-230 re-pt-50 md:re-pt-150">
  <div class="re-flex re-flex-col re-items-center">
    <div class="re-mb-30">
      <svg-icon class="icon" src="assets/icons/smileys/Icon_Smiley_5_sparkling.svg"></svg-icon>
    </div>

    <h4 class="re-text-center re-mb-45">{{ 'satisfactionPoll.success' | translate }}</h4>
  </div>

</div>
<div class="re-fixed re-bottom-0 re-left-0 re-p-30 lg:re-absolute lg:re-bottom-60 lg:re-pb-0 lg:re-px-0">
  <ng-content></ng-content>
</div>
