import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { AddressData, Account, AccountInfo } from '../../../../../assets/js/com/ts_api_client';
import { Utilities } from '../../../../shared/utils/utilities';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { BdoApiService } from '../../../services/bdo-api.service';
import { CustomerStoreService } from '../../../services/customer-store.service';
import { DetailedBillingPlan, DetailedBillingPlanService } from '../../../services/detailed-billingplan.service';
import { TariffAdvisorService } from '../../../services/tariff-advisor.service';
import { TenantService } from '../../../services/tenant.service';
import { TrackingService } from '../../../services/tracking.service';
import { PromoLinkData } from '../../../../shared/molecules/promo-link-wrapper/promo-link-wrapper.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'bdo-billing-plan',
  templateUrl: './billing-plan.component.html',
  styleUrls: ['./billing-plan.component.scss']
})

export class BillingPlanComponent implements OnInit, OnDestroy {
  public state: LOADING_STATE = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;
  public detailedBillingPlan: DetailedBillingPlan;
  public total: number;
  public accountId: string;
  public address: AddressData;
  public hasRestrictedRegio: boolean = true;
  public promoLinkDataSet: Array<PromoLinkData>;
  public isInEditMode = false;
  private subscription: Subscription;

  constructor(
    public tenantService: TenantService,
    public customerStore: CustomerStoreService,
    public tariffAdvisorService: TariffAdvisorService,
    private detailedBillingPlanService: DetailedBillingPlanService,
    private trackingService: TrackingService,
    private apiService: BdoApiService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.state = LOADING_STATE.LOADING;
    this.trackingService.postTracking(TRACKING.LOCATION.BILLING_PLAN, TRACKING.ACTION.ENTER);
    this.accountId = this.customerStore.getAccountId();

    this.subscription = this.translateService.stream('payment.linkTo')
      .subscribe({
        next: (res) => {
          this.promoLinkDataSet = [
            {
              linkText: res,
              routerUrl: '../../zahlungen'
            }
          ];
        }
      });

    this.apiService.getAccounts()
    .subscribe({
      next: (accounts: Account[]) => {
        this.hasRestrictedRegio = accounts[0]?.eingeschraenkteRegio;
      }
    });

    this.apiService.getAccountInfo()
    .pipe(
      switchMap((resCustomer: AccountInfo) => {
        this.address = Utilities.flattenAddress(resCustomer.address);
        return this.detailedBillingPlanService.getDetailedBillingPlan();
      })
    ).subscribe({
      next: (detailedBillingPlan: DetailedBillingPlan) => {
        this.detailedBillingPlan = detailedBillingPlan;
        this.total = detailedBillingPlan.cumulAmount;
        this.state = LOADING_STATE.IDLE;
      },
      error: () => {
        this.state = LOADING_STATE.ERROR;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onEditDone(hasChanges: boolean) {
    this.isInEditMode = false;
    if (hasChanges) {
      this.detailedBillingPlanService.getDetailedBillingPlan().subscribe({
        next: (detailedBillingPlan: DetailedBillingPlan) => {
          this.detailedBillingPlan = detailedBillingPlan;
          this.total = detailedBillingPlan.cumulAmount;
          this.state = LOADING_STATE.IDLE;
        },
        error: () => {
          this.state = LOADING_STATE.ERROR;
        }
      });
    }
  }
}
