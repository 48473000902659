import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { TenantService } from '../../bdo/services/tenant.service';
import { TrackingService } from '../../bdo/services/tracking.service';
import { Utilities } from '../../shared/utils/utilities';
import { Environment } from '../../../environments/environment';
import { TENANT } from '../../bdo/enums/tenant.enum';

@Component({
  selector: 'bdo-cookiebanner',
  templateUrl: './cookiebanner.component.html',
  styleUrl: './cookiebanner.component.scss'
})
export class CookiebannerComponent implements OnInit {
  public Utilities = Utilities;
  public colorPrimary="#e30613";
  public colorSecondary="#383838";
  public colorText="#383838";

  constructor(
    public tenantService: TenantService,
    private trackingService: TrackingService,
    @Inject(DOCUMENT) private doc: any
  ) {
    this.initCookiebanner();
  }

  ngOnInit(): void {
    this.doc.querySelector('cookie-banner-component').addEventListener('action', (evt) => {
      // Details stored in the custom event details
      const eventDetails = evt.detail;
      // To check which action is triggered the action type is transferred
      // in the event details as a string, e.g. 'primary' or 'secondary'
      const triggeredAction = eventDetails.type;
      // primary = Cookies accepted
      // secondary = Cookies denied
      if (triggeredAction === 'primary') {
        this.trackingService.updateCookieHandling(true);
      } else if (triggeredAction === 'secondary') {
        this.trackingService.updateCookieHandling(false);
      }
    });
  }

  /* Init Colors - Copy from environmentStyles */
  private initCookiebanner() {
    switch (Environment.tenant) {
      case TENANT.Belkaw:
        this.colorPrimary="#004070";
        this.colorSecondary="#95C11F";
        this.colorText="#004070";
        break;
      case TENANT.StadtwerkeLeichlingen:
        this.colorPrimary="#005da9";
        break;
      case TENANT.StadtwerkeSanktAugustin:
        this.colorPrimary="#213a8f";
        this.colorSecondary="#ffcc00";
        this.colorText="#213a8f";
        break;
      default:
        break;
    }
  }
}
