<div class="bold re-mb-15 md:re-mb-30">
  {{questionData?.questionText}}
</div>

<div class="re-h-230 re-flex re-flex-col re-justify-center">
  <ng-container [ngSwitch]="questionData?.questionType">
    <ng-container *ngSwitchCase="POLLQUESTIONTYPE['1-10_SCALE']">
      <bdo-scale-question [questionData]="questionData" (sendAnswer)="sendAnswer.emit($event)"></bdo-scale-question>
    </ng-container>
    <ng-container *ngSwitchCase="POLLQUESTIONTYPE['1-5_SCALE']">
      <bdo-agree-question [questionData]="questionData" (sendAnswer)="sendAnswer.emit($event)"></bdo-agree-question>
    </ng-container>
    <ng-container *ngSwitchCase="POLLQUESTIONTYPE.FREE_TEXT">
      <bdo-freetext-question [questionData]="questionData" (sendAnswer)="sendAnswer.emit($event)"></bdo-freetext-question>
    </ng-container>
    <ng-container *ngSwitchCase="POLLQUESTIONTYPE.BOOLEAN">
      <bdo-boolean-question [questionData]="questionData" (sendAnswer)="sendAnswer.emit($event)"></bdo-boolean-question>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <!-- Unknown -->
    </ng-container>
  </ng-container>
</div>

<div class="re-absolute re-top-0 re-left-0 re-bg-white re-w-full re-h-200" *ngIf="questionData?.questionNo > 1"></div>
