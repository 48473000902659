<div class="re-flex re-justify-center re-mb-15 lg:re-mb-0 re-text-xs">
  <div class="re-hidden lg:re-block lg:re-mr-30 re-text-right scale bdo-break-after-word re-leading-normal">{{questionData?.scaleLow}}</div>
  <div class="re-w-full lg:re-w-440 re-pr-20 lg:re-px-20">
    <div class="re-w-full re-relative legend">
      <div class="re-absolute" *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]" [style.left]="(i-1)*(100/9) + '%'" [ngClass]="{
       're-opacity-50': selectedValue < i,
       're-opacity-100 bold': selectedValue >= i
      }">{{i}}</div>
    </div>
    <mat-slider class="mat-slider-hide-last-tick" [min]="1" [max]="10" [step]="1">
      <input matSliderThumb [(ngModel)]="selectedValue" (valueChange)="disabled = false">
    </mat-slider>
  </div>
  <div class="re-hidden lg:re-block lg:re-ml-30 scale bdo-break-after-word re-leading-normal">{{questionData?.scaleHigh}}</div>
</div>

<div class="re-w-full re-flex re-mt-10 lg:re-hidden re-text-xs bdo-break-after-word">
  <div class="re-w-1/2 re-pr-15 re-leading-normal">{{questionData?.scaleLow}}</div>
  <div class="re-w-1/2 re-pl-15 re-text-right re-leading-normal">{{questionData?.scaleHigh}}</div>
</div>

<div class="re-flex re-justify-center re-mt-10">
  <button class="bdo-button-tertiary--inverse re-w-full md:re-w-auto md:re-mt-45"
          [disabled]="disabled"
          (click)="sendAnswer.emit(selectedValue.toString())">
    {{ 'satisfactionPoll.nextQuestion' | translate }}
  </button>
</div>
