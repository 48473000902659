/**
 * NLB-GW
 * Diese fantastische API stellt verschiedene Services fuer den neuen RheinEnergie Online Service zur Verfuegung.  Insbesondere sind hier Services zum Zugriff auf das SAP iCos implementiert. 
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TariffSummaryContractLevel { 
    optionId?: string;
    description?: string;
    baseGross?: number;
    baseNet?: number;
    workGross?: number;
    workNet?: number;
    icon?: string;
    /**
     * The level describes the nature of what the prices refer to. (e.g. 1 = basic price, 15 = meter price, 3 = Rheinland Option).
     */
    level?: number;
    link?: string;
    infoText?: string;
    priceDate?: string;
}

