/**
 * NLB-GW
 * Diese fantastische API stellt verschiedene Services fuer den neuen RheinEnergie Online Service zur Verfuegung.  Insbesondere sind hier Services zum Zugriff auf das SAP iCos implementiert. 
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * PriceCap or -Preisbremse- as we say in germany
 */
export interface TariffSummaryPriceCap { 
    validUntil?: string;
    /**
     * Total savings net
     */
    totalSavingsNet?: number;
    /**
     * Total savings in gross
     */
    totalSavingsGross?: number;
    /**
     * Considered month
     */
    consideredMonth?: number;
    /**
     * considered consumption for given tariff
     */
    consideredConsumption?: string;
    workGross?: number;
    workNet?: number;
    /**
     * Calculated monthly price
     */
    annualPriceGross?: number;
    /**
     * Calculated monthly price
     */
    annualPriceNet?: number;
    /**
     * Calculated monthly price
     */
    monthlyPriceDuringPriceCapPeriodNet?: number;
    /**
     * Calculated monthly price
     */
    monthlyPriceDuringPriceCapPeriodGross?: number;
    /**
     * Calculated monthly price
     */
    monthlyPriceFirstYearNet?: number;
    /**
     * Calculated monthly price
     */
    monthlyPriceFirstYearGross?: number;
}

