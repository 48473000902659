import moment from 'moment';
import { COMPETITION_ID } from '../enums/competitionId.enum';
import { Competition } from '../models/competition';

const competitionStartDate = moment('2023-12-01T00:00:00+01:00').toDate();
const competitionEndDate = moment('2024-01-31T00:00:00+01:00').toDate();
export const activeCompetition: Competition = {
  competitionId: COMPETITION_ID.X2_FCKOELN_DEZ_2023,
  startDate: competitionStartDate,
  endDate: competitionEndDate
};
