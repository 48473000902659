import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { CustomerStoreService } from '../../../services/customer-store.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { TrackingService } from '../../../services/tracking.service';

@Component({
  selector: 'bdo-zaehlerstand-saved',
  templateUrl: './zaehlerstand-saved.component.html',
  styleUrls: ['./zaehlerstand-saved.component.scss']
})
export class ZaehlerstandSavedComponent implements OnInit {
  @Output() goToNewCapture: EventEmitter<any> = new EventEmitter();
  public TRACKING = TRACKING;

  constructor(    private trackingService: TrackingService,
                  private customerStore: CustomerStoreService) { }

  ngOnInit() {
    this.trackingService.postTracking(TRACKING.LOCATION.METERREADINGS_SUCCESS, TRACKING.ACTION.ENTER);
  }

  newCaptureSelected() {
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.METERREADINGS_SUCCESS, 'WeiterenZaehlerstandErfassen');
    this.goToNewCapture.emit();
  }

  getKundennummer(): string {
    return this.customerStore.getAccountId();
  }

}
