import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../login.service';
import { LoginResponse } from '../../bdo/models/loginResponse';
import { TRACKING } from '../../bdo//enums/trackingParts.enum';
import { TrackingService } from '../../bdo/services/tracking.service';

export interface Credentials {
  username: string;
  password: string;
}

@Component({
  selector: 'bdo-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {
  @Output() submitFormEvent: EventEmitter<LoginResponse> = new EventEmitter();
  @Output() submitLogin: EventEmitter<Credentials> = new EventEmitter();
  @Output() hideErrorEvent: EventEmitter<any> = new EventEmitter();
  @Input() name: string;
  @Input() isFetching: boolean = false;

  public password: string;
  public userExistsInCognito: boolean = false;
  public error : 'general' | 'notfound' | '' = '';

  validateForm: boolean = false;

  validateAll: boolean = false;
  validationIconHidden: boolean = false;
  public TRACKING = TRACKING;

  constructor(
    private loginService: LoginService,
    private router: Router,
    public trackingService: TrackingService
  ) { }


  login(form: any) {
    if (!this.name) {
      this.validateAll = true;
    } else if (!this.userExistsInCognito) {
      this.checkUsername(this.name);
    } else if (form.valid) {
      this.submitLogin.emit({
        password: this.password,
        username: this.name
      });
    } else {
      this.validateAll = true;
    }
  }

  checkUsername(username: string) {
    this.error = '';
    if (username) {
      this.loginService.checkUsernameAvailable(username).subscribe({
        next: (res) => {
          if (!res.available) {
            this.userExistsInCognito = true;
          } else {
            // TODO: Check Salesforce Username
            // Show Error Message
            this.error = 'notfound';
          }
        },
        error: () => {
          this.error = 'general';
        }
      });
    } else {
      this.validateAll = true;
    }
  }

  goTo(destination: string) {
    this.router.navigate([destination]);
  }

  onFocus() {
    this.hideErrorEvent.emit();
    this.validationIconHidden = false;
    this.error = '';
  }
}
