/**
 * NLB-GW
 * Diese fantastische API stellt verschiedene Services fuer den neuen RheinEnergie Online Service zur Verfuegung.  Insbesondere sind hier Services zum Zugriff auf das SAP iCos implementiert. 
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PaymentData { 
    /**
     * z.B. Akontozahlung, Abschlagsforderung, Gebühr Simulationsrechnung
     */
    title?: string;
    /**
     * identifies a payment item
     */
    id?: string;
    dateDue?: Date;
    isDeferrable?: boolean;
    deferrableUpTo?: Date;
    deferredTo?: Date;
    deferralText?: string;
    amount?: number;
}

