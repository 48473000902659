/**
 * NLB-GW
 * Diese fantastische API stellt verschiedene Services fuer den neuen RheinEnergie Online Service zur Verfuegung.  Insbesondere sind hier Services zum Zugriff auf das SAP iCos implementiert. 
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Object to verify/check a meter
 */
export interface MetersCheck { 
    /**
     * number of a meter
     */
    meterNumber?: string;
    /**
     * moveInDate, if NULL - backend uses now as default
     */
    moveInDate?: Date;
    /**
     * postCode
     */
    postCode?: string;
    /**
     * city
     */
    city?: string;
    /**
     * street
     */
    street?: string;
    /**
     * division of product e.g. S4 for power, G6 for gas or T4 for water
     */
    division?: string;
    /**
     * describes the type of delivery: MOVE (Umzug), CHANGE (Wechsel)
     */
    deliveryType?: string;
}

