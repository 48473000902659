import { Component } from '@angular/core';

@Component({
  selector: 'bdo-poll-done',
  templateUrl: './poll-done.component.html',
  styleUrls: ['./poll-done.component.scss']
})
export class PollDoneComponent {

}
