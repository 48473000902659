<div class="info--text">
{{ 'meterreadings.confirmation.savedDescriptionLong' | translate }}</div>

<div class="re-mb-10">
  <bdo-text-icon
    text="Weiteren Zählerstand erfassen"
    iconPosition="right"
    (itemClicked)="newCaptureSelected()"
  ></bdo-text-icon>
</div>
<div class="link">
  <bdo-text-link-icon
		[linkText]="'billing.linkTo' | translate"
    [routerUrl]="'/abschlag/'+getKundennummer()"
    [iconPosition]="'right'"
    [tracking]="[TRACKING.LOCATION.METERREADINGS_SUCCESS, TRACKING.ACTION.GOTO, TRACKING.LOCATION.BILLING_PLAN]">
  </bdo-text-link-icon>
</div>
