/**
 * NLB-GW
 * Diese fantastische API stellt verschiedene Services fuer den neuen RheinEnergie Online Service zur Verfuegung.  Insbesondere sind hier Services zum Zugriff auf das SAP iCos implementiert. 
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { BankAssignment } from '../model/bankAssignment';
// @ts-ignore
import { BankName } from '../model/bankName';
// @ts-ignore
import { CancelReasonData } from '../model/cancelReasonData';
// @ts-ignore
import { CompetitionData } from '../model/competitionData';
// @ts-ignore
import { Contract } from '../model/contract';
// @ts-ignore
import { ContractCancelData } from '../model/contractCancelData';
// @ts-ignore
import { ContractChangeResponse } from '../model/contractChangeResponse';
// @ts-ignore
import { ContractChangeTariff } from '../model/contractChangeTariff';
// @ts-ignore
import { ContractConsumptionSummary } from '../model/contractConsumptionSummary';
// @ts-ignore
import { ContractInformationSummary } from '../model/contractInformationSummary';
// @ts-ignore
import { ContractMoveData } from '../model/contractMoveData';
// @ts-ignore
import { EmailVerificationData } from '../model/emailVerificationData';
// @ts-ignore
import { FullAddress } from '../model/fullAddress';
// @ts-ignore
import { LoadProfileCustomerMode } from '../model/loadProfileCustomerMode';
// @ts-ignore
import { OfferData } from '../model/offerData';
// @ts-ignore
import { OfferDataResponse } from '../model/offerDataResponse';
// @ts-ignore
import { PartnerEmail } from '../model/partnerEmail';
// @ts-ignore
import { StatusResponse } from '../model/statusResponse';
// @ts-ignore
import { TariffChangeRequest } from '../model/tariffChangeRequest';
// @ts-ignore
import { VebStatusData } from '../model/vebStatusData';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class AnonymousService {

    protected basePath = 'http://localhost:8080/BdoSpiders/orca';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * returns address of customer based on accountId and meterNumber
     * returns address of customer based on accountId and meterNumber
     * @param xTenant 
     * @param accountId 
     * @param meterNumber Valid characters are: \&#39;A-Z\&#39;,\&#39;0-9\&#39; and \&#39;-./_&#x60;Ä\&#39;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAddressAnonymous(xTenant: string, accountId: string, meterNumber: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<FullAddress>;
    public getAddressAnonymous(xTenant: string, accountId: string, meterNumber: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<FullAddress>>;
    public getAddressAnonymous(xTenant: string, accountId: string, meterNumber: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<FullAddress>>;
    public getAddressAnonymous(xTenant: string, accountId: string, meterNumber: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getAddressAnonymous.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getAddressAnonymous.');
        }
        if (meterNumber === null || meterNumber === undefined) {
            throw new Error('Required parameter meterNumber was null or undefined when calling getAddressAnonymous.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/address/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "meterNumber", value: meterNumber, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<FullAddress>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns a list of tariff infos the customer can choose as change target
     * @param xTenant 
     * @param accountId 
     * @param meterNumber Valid characters are: \&#39;A-Z\&#39;,\&#39;0-9\&#39; and \&#39;-./_&#x60;Ä\&#39;
     * @param contractId 
     * @param division 
     * @param consumption 
     * @param customerMode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAnonymousContractChangeTariffOptionsInformation(xTenant: string, accountId: string, meterNumber: string, contractId: string, division?: string, consumption?: number, customerMode?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<Array<ContractChangeTariff>>;
    public getAnonymousContractChangeTariffOptionsInformation(xTenant: string, accountId: string, meterNumber: string, contractId: string, division?: string, consumption?: number, customerMode?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<ContractChangeTariff>>>;
    public getAnonymousContractChangeTariffOptionsInformation(xTenant: string, accountId: string, meterNumber: string, contractId: string, division?: string, consumption?: number, customerMode?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<ContractChangeTariff>>>;
    public getAnonymousContractChangeTariffOptionsInformation(xTenant: string, accountId: string, meterNumber: string, contractId: string, division?: string, consumption?: number, customerMode?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getAnonymousContractChangeTariffOptionsInformation.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getAnonymousContractChangeTariffOptionsInformation.');
        }
        if (meterNumber === null || meterNumber === undefined) {
            throw new Error('Required parameter meterNumber was null or undefined when calling getAnonymousContractChangeTariffOptionsInformation.');
        }
        if (contractId === null || contractId === undefined) {
            throw new Error('Required parameter contractId was null or undefined when calling getAnonymousContractChangeTariffOptionsInformation.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (division !== undefined && division !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>division, 'division');
        }
        if (consumption !== undefined && consumption !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>consumption, 'consumption');
        }
        if (customerMode !== undefined && customerMode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerMode, 'customerMode');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/contract/change/tariffOptions/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "meterNumber", value: meterNumber, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "contractId", value: contractId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<Array<ContractChangeTariff>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns contract consumption information for accountId
     * returns contract consumption information for accountId
     * @param xTenant 
     * @param accountId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAnonymousContractConsumptionInfo(xTenant: string, accountId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<ContractConsumptionSummary>;
    public getAnonymousContractConsumptionInfo(xTenant: string, accountId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ContractConsumptionSummary>>;
    public getAnonymousContractConsumptionInfo(xTenant: string, accountId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ContractConsumptionSummary>>;
    public getAnonymousContractConsumptionInfo(xTenant: string, accountId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getAnonymousContractConsumptionInfo.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getAnonymousContractConsumptionInfo.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/contract/consumption/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<ContractConsumptionSummary>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns anonymous contract information for accountId
     * returns anonymous contract information for accountId
     * @param xTenant 
     * @param accountId 
     * @param meterNumber Valid characters are: \&#39;A-Z\&#39;,\&#39;0-9\&#39; and \&#39;-./_&#x60;Ä\&#39;
     * @param annualConsumption estimated annual consumption of units for customer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAnonymousContractInformation(xTenant: string, accountId: string, meterNumber: string, annualConsumption?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<ContractInformationSummary>;
    public getAnonymousContractInformation(xTenant: string, accountId: string, meterNumber: string, annualConsumption?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ContractInformationSummary>>;
    public getAnonymousContractInformation(xTenant: string, accountId: string, meterNumber: string, annualConsumption?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ContractInformationSummary>>;
    public getAnonymousContractInformation(xTenant: string, accountId: string, meterNumber: string, annualConsumption?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getAnonymousContractInformation.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getAnonymousContractInformation.');
        }
        if (meterNumber === null || meterNumber === undefined) {
            throw new Error('Required parameter meterNumber was null or undefined when calling getAnonymousContractInformation.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (annualConsumption !== undefined && annualConsumption !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>annualConsumption, 'annualConsumption');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/contract/info/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "meterNumber", value: meterNumber, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<ContractInformationSummary>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns a list of tariff offers
     * @param xTenant 
     * @param offerId 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAnonymousContractOffer(xTenant: string, offerId: string, token: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<Contract>;
    public getAnonymousContractOffer(xTenant: string, offerId: string, token: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Contract>>;
    public getAnonymousContractOffer(xTenant: string, offerId: string, token: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Contract>>;
    public getAnonymousContractOffer(xTenant: string, offerId: string, token: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getAnonymousContractOffer.');
        }
        if (offerId === null || offerId === undefined) {
            throw new Error('Required parameter offerId was null or undefined when calling getAnonymousContractOffer.');
        }
        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling getAnonymousContractOffer.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (offerId !== undefined && offerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offerId, 'offerId');
        }
        if (token !== undefined && token !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>token, 'token');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/offer`;
        return this.httpClient.request<Contract>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Liefert die Bankdaten für Zahlungen und Erstattungen zu einer Kundennummer
     * Liefert die Bankdaten für Zahlungen und Erstattungen zu einer Kundennummer
     * @param xTenant 
     * @param accountId 
     * @param meterNumber Valid characters are: \&#39;A-Z\&#39;,\&#39;0-9\&#39; and \&#39;-./_&#x60;Ä\&#39;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBankingAssignmentAnonymous(xTenant: string, accountId: string, meterNumber: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<BankAssignment>;
    public getBankingAssignmentAnonymous(xTenant: string, accountId: string, meterNumber: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<BankAssignment>>;
    public getBankingAssignmentAnonymous(xTenant: string, accountId: string, meterNumber: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<BankAssignment>>;
    public getBankingAssignmentAnonymous(xTenant: string, accountId: string, meterNumber: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getBankingAssignmentAnonymous.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getBankingAssignmentAnonymous.');
        }
        if (meterNumber === null || meterNumber === undefined) {
            throw new Error('Required parameter meterNumber was null or undefined when calling getBankingAssignmentAnonymous.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/banking/assignment/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "meterNumber", value: meterNumber, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<BankAssignment>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Liefert den Namen einer Bank aus einer IBAN oder einen Fehler bei invalider IBAN
     * E2E test in BankAccountsTest
     * @param xTenant 
     * @param iban 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBanknameFromIBAN(xTenant: string, iban: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<BankName>;
    public getBanknameFromIBAN(xTenant: string, iban: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<BankName>>;
    public getBanknameFromIBAN(xTenant: string, iban: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<BankName>>;
    public getBanknameFromIBAN(xTenant: string, iban: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getBanknameFromIBAN.');
        }
        if (iban === null || iban === undefined) {
            throw new Error('Required parameter iban was null or undefined when calling getBanknameFromIBAN.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/banking/iban/${this.configuration.encodeParam({name: "iban", value: iban, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<BankName>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns unix timestamp of workday offset based on NOW taking bdew calendar into account
     * returns unix timestamp of workday offset based on NOW taking bdew calendar into account
     * @param xTenant 
     * @param workdayOffset valid values are +10 or -15 to indicate difference in working days
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBdewWorkdayOffsetAnonymous(xTenant: string, workdayOffset: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<StatusResponse>;
    public getBdewWorkdayOffsetAnonymous(xTenant: string, workdayOffset: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<StatusResponse>>;
    public getBdewWorkdayOffsetAnonymous(xTenant: string, workdayOffset: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<StatusResponse>>;
    public getBdewWorkdayOffsetAnonymous(xTenant: string, workdayOffset: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getBdewWorkdayOffsetAnonymous.');
        }
        if (workdayOffset === null || workdayOffset === undefined) {
            throw new Error('Required parameter workdayOffset was null or undefined when calling getBdewWorkdayOffsetAnonymous.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/bdew/${this.configuration.encodeParam({name: "workdayOffset", value: workdayOffset, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<StatusResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns a list of cancelReasons
     * @param xTenant 
     * @param accountId 
     * @param meterNumber Valid characters are: \&#39;A-Z\&#39;,\&#39;0-9\&#39; and \&#39;-./_&#x60;Ä\&#39;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCancelReasonsAnonymous(xTenant: string, accountId: string, meterNumber: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<Array<CancelReasonData>>;
    public getCancelReasonsAnonymous(xTenant: string, accountId: string, meterNumber: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<CancelReasonData>>>;
    public getCancelReasonsAnonymous(xTenant: string, accountId: string, meterNumber: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<CancelReasonData>>>;
    public getCancelReasonsAnonymous(xTenant: string, accountId: string, meterNumber: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getCancelReasonsAnonymous.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getCancelReasonsAnonymous.');
        }
        if (meterNumber === null || meterNumber === undefined) {
            throw new Error('Required parameter meterNumber was null or undefined when calling getCancelReasonsAnonymous.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/cancelReasons/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "meterNumber", value: meterNumber, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<Array<CancelReasonData>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns customer type PRIV -&gt; private customer; GEWE -&gt; business customer
     * returns customer type PRIV -&gt; private customer; GEWE -&gt; business customer
     * @param xTenant 
     * @param accountId 
     * @param meterNumber Valid characters are: \&#39;A-Z\&#39;,\&#39;0-9\&#39; and \&#39;-./_&#x60;Ä\&#39;
     * @param atDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomerModeAnonymous(xTenant: string, accountId: string, meterNumber: string, atDate?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<StatusResponse>;
    public getCustomerModeAnonymous(xTenant: string, accountId: string, meterNumber: string, atDate?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<StatusResponse>>;
    public getCustomerModeAnonymous(xTenant: string, accountId: string, meterNumber: string, atDate?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<StatusResponse>>;
    public getCustomerModeAnonymous(xTenant: string, accountId: string, meterNumber: string, atDate?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getCustomerModeAnonymous.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getCustomerModeAnonymous.');
        }
        if (meterNumber === null || meterNumber === undefined) {
            throw new Error('Required parameter meterNumber was null or undefined when calling getCustomerModeAnonymous.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (atDate !== undefined && atDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>atDate, 'atDate');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/customermode/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "meterNumber", value: meterNumber, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<StatusResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns email address of customer based on accountId and meterNumber
     * returns email address of customer based on accountId and meterNumber
     * @param xTenant 
     * @param accountId 
     * @param meterNumber Valid characters are: \&#39;A-Z\&#39;,\&#39;0-9\&#39; and \&#39;-./_&#x60;Ä\&#39;
     * @param atDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEmailAnonymous(xTenant: string, accountId: string, meterNumber: string, atDate?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<PartnerEmail>;
    public getEmailAnonymous(xTenant: string, accountId: string, meterNumber: string, atDate?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PartnerEmail>>;
    public getEmailAnonymous(xTenant: string, accountId: string, meterNumber: string, atDate?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PartnerEmail>>;
    public getEmailAnonymous(xTenant: string, accountId: string, meterNumber: string, atDate?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getEmailAnonymous.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getEmailAnonymous.');
        }
        if (meterNumber === null || meterNumber === undefined) {
            throw new Error('Required parameter meterNumber was null or undefined when calling getEmailAnonymous.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (atDate !== undefined && atDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>atDate, 'atDate');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/email/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "meterNumber", value: meterNumber, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<PartnerEmail>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint for checking if veb out exists for current partner (and meter if specified)
     * Endpoint for checking if veb out exists for current partner (and meter if specified)
     * @param xTenant 
     * @param accountId 
     * @param meterNumber Valid characters are: \&#39;A-Z\&#39;,\&#39;0-9\&#39; and \&#39;-./_&#x60;Ä\&#39;
     * @param filterMeter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHasVebOut(xTenant: string, accountId: string, meterNumber: string, filterMeter?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<StatusResponse>;
    public getHasVebOut(xTenant: string, accountId: string, meterNumber: string, filterMeter?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<StatusResponse>>;
    public getHasVebOut(xTenant: string, accountId: string, meterNumber: string, filterMeter?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<StatusResponse>>;
    public getHasVebOut(xTenant: string, accountId: string, meterNumber: string, filterMeter?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getHasVebOut.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getHasVebOut.');
        }
        if (meterNumber === null || meterNumber === undefined) {
            throw new Error('Required parameter meterNumber was null or undefined when calling getHasVebOut.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (filterMeter !== undefined && filterMeter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterMeter, 'filterMeter');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/hasVebOut/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "meterNumber", value: meterNumber, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<StatusResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns loadprofilemode for accountId (Bedarfsart)
     * returns loadprofilemode for accountId (Bedarfsart)
     * @param xTenant 
     * @param accountId 
     * @param meterNumber Valid characters are: \&#39;A-Z\&#39;,\&#39;0-9\&#39; and \&#39;-./_&#x60;Ä\&#39;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLoadProfileModeAnonymous(xTenant: string, accountId: string, meterNumber: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<LoadProfileCustomerMode>;
    public getLoadProfileModeAnonymous(xTenant: string, accountId: string, meterNumber: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<LoadProfileCustomerMode>>;
    public getLoadProfileModeAnonymous(xTenant: string, accountId: string, meterNumber: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<LoadProfileCustomerMode>>;
    public getLoadProfileModeAnonymous(xTenant: string, accountId: string, meterNumber: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getLoadProfileModeAnonymous.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getLoadProfileModeAnonymous.');
        }
        if (meterNumber === null || meterNumber === undefined) {
            throw new Error('Required parameter meterNumber was null or undefined when calling getLoadProfileModeAnonymous.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/account/loadprofilemode/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "meterNumber", value: meterNumber, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<LoadProfileCustomerMode>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint for status data retrieval
     * Collects data for contracts of partner and returns it
     * @param xTenant 
     * @param accountId 
     * @param meterNumber Valid characters are: \&#39;A-Z\&#39;,\&#39;0-9\&#39; and \&#39;-./_&#x60;Ä\&#39;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVebStatus(xTenant: string, accountId: string, meterNumber: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<VebStatusData>;
    public getVebStatus(xTenant: string, accountId: string, meterNumber: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<VebStatusData>>;
    public getVebStatus(xTenant: string, accountId: string, meterNumber: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<VebStatusData>>;
    public getVebStatus(xTenant: string, accountId: string, meterNumber: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getVebStatus.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getVebStatus.');
        }
        if (meterNumber === null || meterNumber === undefined) {
            throw new Error('Required parameter meterNumber was null or undefined when calling getVebStatus.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/vebStatus/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "meterNumber", value: meterNumber, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<VebStatusData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns customer employee status
     * returns customer employee status
     * @param xTenant 
     * @param accountId 
     * @param meterNumber Valid characters are: \&#39;A-Z\&#39;,\&#39;0-9\&#39; and \&#39;-./_&#x60;Ä\&#39;
     * @param atDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public isEmployeeAnonymous(xTenant: string, accountId: string, meterNumber: string, atDate?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<StatusResponse>;
    public isEmployeeAnonymous(xTenant: string, accountId: string, meterNumber: string, atDate?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<StatusResponse>>;
    public isEmployeeAnonymous(xTenant: string, accountId: string, meterNumber: string, atDate?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<StatusResponse>>;
    public isEmployeeAnonymous(xTenant: string, accountId: string, meterNumber: string, atDate?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling isEmployeeAnonymous.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling isEmployeeAnonymous.');
        }
        if (meterNumber === null || meterNumber === undefined) {
            throw new Error('Required parameter meterNumber was null or undefined when calling isEmployeeAnonymous.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (atDate !== undefined && atDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>atDate, 'atDate');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/isemployee/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "meterNumber", value: meterNumber, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<StatusResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * writes cancel contract to SAP system
     * writes cancel contract to SAP system
     * @param xTenant 
     * @param contractCancelData CancelContractForm
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAnonymousContractCancel(xTenant: string, contractCancelData: ContractCancelData, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<StatusResponse>;
    public postAnonymousContractCancel(xTenant: string, contractCancelData: ContractCancelData, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<StatusResponse>>;
    public postAnonymousContractCancel(xTenant: string, contractCancelData: ContractCancelData, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<StatusResponse>>;
    public postAnonymousContractCancel(xTenant: string, contractCancelData: ContractCancelData, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling postAnonymousContractCancel.');
        }
        if (contractCancelData === null || contractCancelData === undefined) {
            throw new Error('Required parameter contractCancelData was null or undefined when calling postAnonymousContractCancel.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/contract/cancel`;
        return this.httpClient.request<StatusResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: contractCancelData,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * send emailVerification to mark an idp user as email_verified
     * @param xTenant 
     * @param emailVerificationData EmailVerification
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAnonymousEmailVerification(xTenant: string, emailVerificationData: EmailVerificationData, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<StatusResponse>;
    public postAnonymousEmailVerification(xTenant: string, emailVerificationData: EmailVerificationData, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<StatusResponse>>;
    public postAnonymousEmailVerification(xTenant: string, emailVerificationData: EmailVerificationData, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<StatusResponse>>;
    public postAnonymousEmailVerification(xTenant: string, emailVerificationData: EmailVerificationData, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling postAnonymousEmailVerification.');
        }
        if (emailVerificationData === null || emailVerificationData === undefined) {
            throw new Error('Required parameter emailVerificationData was null or undefined when calling postAnonymousEmailVerification.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/email/verify`;
        return this.httpClient.request<StatusResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: emailVerificationData,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * anonymous move contract within SAP system
     * anonymous move contract within SAP system
     * @param xTenant 
     * @param contractMoveData SendContractMoveForm
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAnonymousMoveContract(xTenant: string, contractMoveData: ContractMoveData, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<ContractMoveData>;
    public postAnonymousMoveContract(xTenant: string, contractMoveData: ContractMoveData, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ContractMoveData>>;
    public postAnonymousMoveContract(xTenant: string, contractMoveData: ContractMoveData, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ContractMoveData>>;
    public postAnonymousMoveContract(xTenant: string, contractMoveData: ContractMoveData, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling postAnonymousMoveContract.');
        }
        if (contractMoveData === null || contractMoveData === undefined) {
            throw new Error('Required parameter contractMoveData was null or undefined when calling postAnonymousMoveContract.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/contract/move`;
        return this.httpClient.request<ContractMoveData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: contractMoveData,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * send offerData to generate an offer link / mail
     * send offerData to generate an offer link / mail
     * @param xTenant 
     * @param offerData OfferDataForm
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAnonymousOfferData(xTenant: string, offerData: OfferData, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<OfferDataResponse>;
    public postAnonymousOfferData(xTenant: string, offerData: OfferData, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<OfferDataResponse>>;
    public postAnonymousOfferData(xTenant: string, offerData: OfferData, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<OfferDataResponse>>;
    public postAnonymousOfferData(xTenant: string, offerData: OfferData, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling postAnonymousOfferData.');
        }
        if (offerData === null || offerData === undefined) {
            throw new Error('Required parameter offerData was null or undefined when calling postAnonymousOfferData.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/offer/create`;
        return this.httpClient.request<OfferDataResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: offerData,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * changes contracts within sap system for customer
     * changes contracts within sap system for customer
     * @param xTenant 
     * @param tariffChangeRequest changes contract for accountId and given productId(s) an 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postChangeContractAnonymous(xTenant: string, tariffChangeRequest: TariffChangeRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<ContractChangeResponse>;
    public postChangeContractAnonymous(xTenant: string, tariffChangeRequest: TariffChangeRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ContractChangeResponse>>;
    public postChangeContractAnonymous(xTenant: string, tariffChangeRequest: TariffChangeRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ContractChangeResponse>>;
    public postChangeContractAnonymous(xTenant: string, tariffChangeRequest: TariffChangeRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling postChangeContractAnonymous.');
        }
        if (tariffChangeRequest === null || tariffChangeRequest === undefined) {
            throw new Error('Required parameter tariffChangeRequest was null or undefined when calling postChangeContractAnonymous.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/contract/change`;
        return this.httpClient.request<ContractChangeResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: tariffChangeRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint for competition data
     * Collects data used for competitions and writes it to the database
     * @param xTenant 
     * @param accountId 
     * @param meterNumber Valid characters are: \&#39;A-Z\&#39;,\&#39;0-9\&#39; and \&#39;-./_&#x60;Ä\&#39;
     * @param competitionData 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postCompetitionData(xTenant: string, accountId: string, meterNumber: string, competitionData?: CompetitionData, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<StatusResponse>;
    public postCompetitionData(xTenant: string, accountId: string, meterNumber: string, competitionData?: CompetitionData, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<StatusResponse>>;
    public postCompetitionData(xTenant: string, accountId: string, meterNumber: string, competitionData?: CompetitionData, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<StatusResponse>>;
    public postCompetitionData(xTenant: string, accountId: string, meterNumber: string, competitionData?: CompetitionData, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling postCompetitionData.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling postCompetitionData.');
        }
        if (meterNumber === null || meterNumber === undefined) {
            throw new Error('Required parameter meterNumber was null or undefined when calling postCompetitionData.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/competition/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "meterNumber", value: meterNumber, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<StatusResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: competitionData,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
