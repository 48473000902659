import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerStoreService } from '../../services/customer-store.service';
import { TenantService } from '../../services/tenant.service';
import { TRACKING } from '../../enums/trackingParts.enum';
import { TrackingService } from '../../services/tracking.service';

@Component({
  selector: 'bdo-construction-site',
  templateUrl: './construction-site.component.html',
  styleUrls: ['./construction-site.component.scss']
})
export class ConstructionSiteComponent implements OnInit {
  public linkToContactHref: string = '';
  public linkToContactRouter: string = '';

  constructor(
    public tenantService: TenantService,
    private trackingService: TrackingService,
    private customerStoreService: CustomerStoreService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.CONSTRUCTION, TRACKING.ACTION.ENTER);
    let firstAccountId: string = '';
    if (Array.isArray(this.activatedRoute?.snapshot?.data[`customerData`])) {
      firstAccountId = this.activatedRoute.snapshot.data[`customerData`][0].accountId;
    }
    if (firstAccountId) {
      this.linkToContactRouter = '/kontakt/' + (this.customerStoreService.getAccountId() || firstAccountId);
    } else {
      this.linkToContactHref = this.tenantService.getCurrentTenantData().hrefContactForm;
    }
  }

}
