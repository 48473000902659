import { AutosuggestItem } from '../atoms/autosuggest/models/autosuggest-item.model';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Address, AddressData } from '../../../assets/js/com/ts_api_client';
import { COOKIE_STATUS } from '../../bdo/enums/cookieStatus.enum';
import { Location, LocationStrategy } from '@angular/common';
import { NavigationState } from '../../bdo/models/navigationState';

export class Utilities {

  static maxDate: Date = new Date(8640000000000000); // maximum possible value for a Date
  static blackList: RegExp = /[^A-Za-z\ \-\:\.,_0-9äÄöÖ@üÜß]/gi;
  static maxLength: number = 4095;
  static inputMasks = {
    iban: 'UUAA AAAA AAAA AAAA AAAA AAAA AAAA AAAA',
    date: '00.00.0000'
  };

  static removeNonprintableCharacters(str: string) {
    return str.replace(/[\u00AD\u002D\u2011]+/g, '');
  }

  static createCookie(name: string, value: string, days: number) {
    let date: Date, expires: string;
    if (days) {
      date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toUTCString();
    } else {
      expires = '';
    }
    document.cookie = name + '=' + value + expires + '; path=/';
  }

  static getCookie(cookieName: string): string {
    const cookies = Utilities.getCookiesMap(document.cookie);
    return cookies[cookieName];
  }

  static getCookiesMap(cookiesString: string): any {
    return cookiesString.split(';')
      .map((cookieString) => {
        return cookieString.trim().split('=');
      })
      .reduce((acc, curr) => {
        acc[curr[0]] = curr[1];
        return acc;
      }, {});
  }

  static cookiesAccepted() {
    return Utilities.getCookie('cookiesAccepted')?.toString() === COOKIE_STATUS.ACCEPTED.toString();
  }

  /**
   * find all invalid controls recursive in a layered form
   * @param formToInvestigate
   */
  public static findInvalidControlsRecursive(formToInvestigate: UntypedFormGroup|UntypedFormArray): string[] {
    const invalidControls: string[] = [];
    const recursiveFunc = (form: UntypedFormGroup|UntypedFormArray) => {
      Object.keys(form.controls).forEach(field => {
        const control = form.get(field);
        if (control.invalid) { invalidControls.push(field + JSON.stringify(control.errors)); }
        if (control instanceof UntypedFormGroup) {
          recursiveFunc(control);
        } else if (control instanceof UntypedFormArray) {
          recursiveFunc(control);
        }
      });
    };
    recursiveFunc(formToInvestigate);
    return invalidControls;
  }

  static deleteCookie(name: string) {
    document.cookie = name + '=' + ' =; expires = Thu, 01 Jan 1970 00:00:00 UTC;path=/;';
  }

  /* Cookies are deleted by setting the expiration Date */
  static deleteCookies() {
    const cookies = document.cookie.split('; ');
    for (const item of cookies) {
      const key = item.split('=');
      if (key[0] !== 'cookiesAccepted') {
        Utilities.deleteCookie(key[0]);
      }
    }
  }

  static getInputMask(type: 'iban' | 'date' | null): string {
    return Utilities?.inputMasks?.[type] || '';
  }

  /**
   * Sort Autosuggest Result:
   * Place the strings that begin with the searchstring first, then the other items from a-z
   * Example:
   * searchstring: su
   * result before sort: asu, bsu, sua, sub
   * result after sort: sua, sub, asu, bsu
   * @param {AutosuggestItem} a
   * @param {AutosuggestItem} b
   * @returns {number}
   */
  static sortAutosuggest = function sortAutosuggest(a: AutosuggestItem, b: AutosuggestItem) {
    if (a.index === 0) { return -1; }
    return 0;
  };

  static flattenAddress (nestedAddress: Address | undefined): AddressData {
    const flatAddress: AddressData = {
      cityName: nestedAddress?.street?.city?.name,
      houseNum: nestedAddress?.houseNum,
      postCode: nestedAddress?.street?.city?.postCode,
      streetName: nestedAddress?.street?.name
    };
    return flatAddress;
  }

  static nestAddress (flatAddress: AddressData | undefined): Address {
    if (!flatAddress) { return {}; }
    const nestedAddress: Address = {
      houseNum: flatAddress.houseNum,
      street:  {
        name : flatAddress.streetName,
        city : {
          name: flatAddress.cityName,
          postCode: flatAddress.postCode
        }
      }
    };
    return nestedAddress;
  }

  static getStateOfCurrentRoute(location: Location): NavigationState {
    return location.getState() as NavigationState;
  }

  /* Disabling going back for processes that are done, like delivery, move, change, end etc. */
  static disableBrowsersBackButton(locationStrategy: LocationStrategy) {
    history.pushState(null, null, window.location.href);
    locationStrategy.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }
}
