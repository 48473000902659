import { Component, ElementRef, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { INPUT_TYPE } from '../../../bdo/enums/inputType.enum';
import { TRACKING } from '../../../bdo/enums/trackingParts.enum';
import { FormValidationError } from '../../../bdo/models/formValidationError';
import { FormFieldIdentifierTracking, FormTracking, TrackingService } from '../../../bdo/services/tracking.service';
import { Utilities } from '../../utils/utilities';
import { ControlValueAccessorConnectorDirective } from './control-value-accessor-connector.directive';


@Component({
  selector: 'bdo-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR, useExisting: InputComponent, multi: true
  }]
})
export class InputComponent extends ControlValueAccessorConnectorDirective implements OnInit, OnChanges, OnDestroy {
  @Input() trimOnBlur = true;
  @Input() inputType: INPUT_TYPE = INPUT_TYPE.TEXT;
  @Input() id: string; // optional, if the id cannot be the name of the input due to duplicate id problems
  @Input() mask: 'iban' | 'date' | null = null; // optional prettifying the input
  @Input() help: string = '';
  @Input() enableAutocomplete: boolean = false;
  @Input() formTracking: FormFieldIdentifierTracking;

  // if placeholder or labelText are set (and not both), placeholder is set to labelText or the other way round
  @Input()
    set placeholder (placeholderText) {
      if (!this._labelText || this.labelText !== placeholderText) {
        this._labelText = placeholderText;
      }
      this._placeholder = placeholderText;
    }
    get placeholder () {
      return this._placeholder;
    }

  @Input()
    set labelText(labelText) {
      if (!this._placeholder || (labelText && labelText !== this._placeholder)) {
        this._placeholder = labelText;
      }
      this._labelText = labelText;
    }
    get labelText() {
      return this._labelText;
    }

  @Input() errors: Array<FormValidationError> = [];
  @Input() validationIconHidden: boolean = false;
  @Input() tooltipContent: string;
  @Input() isWarning: boolean = false;
  @Output() focus: EventEmitter<boolean> = new EventEmitter<boolean>(); // eslint-disable-line  @angular-eslint/no-output-native
  @Output() blur: EventEmitter<boolean> = new EventEmitter<boolean>(); // eslint-disable-line  @angular-eslint/no-output-native

  public INPUT_TYPE = INPUT_TYPE;
  public focused = false;
  public labelIsFloating: boolean;
  public isHidden: boolean;
  public showValidationIcon: boolean;
  public showValidationErrors: boolean;
  public Utilities = Utilities;
  public maskString = '';
  protected _labelText: string;
  protected _placeholder: string;
  protected timeout = null;

  constructor(
    injector: Injector,
    public eRef: ElementRef,
    private trackingService: TrackingService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.maskString = Utilities.getInputMask(this.mask);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Need to refresh showValidationIcon after error and hiding the icon and then only tabbing and changing other input
    if (changes.validationIconHidden?.currentValue === false) {
      this.onKeyDown();
    }
  }

  // nach 1 Sekunde keyup soll erst die Validierung gestarted werden
  // Also triggers on Tabbing
  onKeyDown() {
    if (!this.control.touched && !this.focused) {
      return;
    }
    if (this.timeout) { // if there is already a timeout in process cancel it
       window.clearTimeout(this.timeout);
    }

    this.timeout = null;
    this.showValidationIcon = false;
    this.showValidationErrors = false;
    this.timeout = window.setTimeout(() => {
      this.timeout = null;
      this.showValidationIcon = !this.validationIconHidden;
      this.showValidationErrors = !this.validationIconHidden;
      this.calcEverything();
    }, 1000);
  }

  calcEverything() {
    if (this.validationIconHidden) {
      this.labelIsFloating = true;
      return;
    }
    this.labelIsFloating = false;
    this.isHidden = false;

    if (this.control?.valid) {
      this.labelIsFloating = !this.inputIsEmpty();
      this.isHidden = this.inputIsEmpty();
    } else { // not valid
      if (!this.control.touched && !this.focused) {
        this.labelIsFloating = true;
        if (this.inputIsEmpty()) {
          this.isHidden = true;
        }
      }
      if (!(this.control.touched || this.focused) || this.showValidationErrors) {
        this.labelIsFloating = false;
        this.isHidden = true;
      }
    }

  }

  public inputIsEmpty() {
    return !(this.control.value && this.control.value.toString().length > 0);
  }

  getFloating(): boolean {
    return this.labelIsFloating;
  }

  getHidden(): boolean {
    return this.isHidden;
  }

  public onFocus() {
    this.focused = true;
    this.focus.emit(true);
  }

  public onSelect() {
    this.focused = true;
    this.focus.emit(true);
  }

  public onBlur() {
    this.focused = false;
    this.showValidationIcon = !this.validationIconHidden;
    this.showValidationErrors = !this.validationIconHidden;
    if (this.trimOnBlur && (this.control.value?.toString()?.startsWith(' ') || this.control.value?.toString()?.endsWith(' '))) {
      this.control.setValue(this.control.value.trim());
    }
    if (this.formTracking) {
      const formTracking: FormTracking = {
        ...this.formTracking,
        orca_field_type: this.inputType,
        orca_form_action: this.control.invalid ? TRACKING.FORM_ACTION.ERROR : TRACKING.FORM_ACTION.INTERACTION
      };
      formTracking.orca_field_name = formTracking.orca_field_name || this.id || this.inputName;
      this.trackingService.postFormInteraction(formTracking);
    }
    this.blur.emit(true);
  }

  public onChange(newTouchedState: boolean) {
    if (!this.focused && newTouchedState) {
      this.onTouched();
    }
  }

  public ngOnDestroy(): void {
    this.touchedChangedSubcription.unsubscribe();
  }

  private onTouched() {
    this.showValidationIcon = !this.validationIconHidden;
    this.showValidationErrors = !this.validationIconHidden;
    this.calcEverything();
  }

}
