import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { AccordionItemComponent } from './atoms/accordion-item/accordion-item.component';
import { AccordionComponent } from './atoms/accordion/accordion.component';
import { CheckboxComponent } from './atoms/checkbox/checkbox.component';
import { AccountidWithAddressComponent } from './atoms/accountid-with-address/accountid-with-address.component';
// Atoms
import { DashboardTileComponent } from './atoms/dashboard-tile/dashboard-tile.component';
import { DatePickerComponent } from './atoms/date-picker/date-picker.component';
import { DropdownComponent } from './atoms/dropdown/dropdown.component';
import { HomeLinkComponent } from './atoms/home-link/home-link.component';
import { InfoBoxComponent } from './atoms/info-box/info-box.component';
import { InputComponent } from './atoms/input/input.component';
import { MediatextComponent } from './atoms/mediatext/mediatext.component';
import { MoreButtonComponent } from './atoms/more-button/more-button.component';
import { MultiLineInputComponent } from './atoms/multi-line-input/multi-line-input.component';
import { PasswordStrengthComponent } from './atoms/password-strength/password-strength.component';
import { PopupContentComponent } from './atoms/popup-content/popup-content.component';
import { SectionHeadlineComponent } from './atoms/section-headline/section-headline.component';
import { SingleAccordionComponent } from './atoms/single-accordion/single-accordion.component';
import { SingleLineInputComponent } from './atoms/single-line-input/single-line-input.component';
import { SvgDefinitionsComponent } from './atoms/svg-definitions/svg-definitions.component';
import { SvgComponent } from './atoms/svg/svg.component';
import { TextIconComponent } from './atoms/text-icon/text-icon.component';
import { TextLinkIconComponent } from './atoms/text-link-icon/text-link-icon.component';
import { ValidateSubmitComponent } from './atoms/validate-submit/validate-submit.component';
import { LargePopupComponent } from './molecules/large-popup/large-popup.component';
import { ProgressBarComponent } from './atoms/progress-bar/progress-bar.component';
import { CardComponent } from './atoms/card/card.component';
import { LoadingComponent } from './atoms/loading/loading.component';
import { SelectionTileComponent } from './atoms/selection-tile/selection-tile.component';
// Molecules
import { SimpleTextPopupComponent } from './molecules/simple-text-popup/simple-text-popup.component';
import { VideoPopupComponent } from './molecules/video-popup/video-popup.component';
import { AddressPipe } from './pipes/address.pipe';
import { DefaultValidatorDirective } from './validators/default-validator.directive';
import { ZaehlerstandValidatorDirective } from './validators/zaehlerstand-validator.directive';
import { AutosuggestComponent } from './atoms/autosuggest/autosuggest.component';
import { InlineTooltipComponent } from './atoms/inline-tooltip/inline-tooltip.component';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { EditCardComponent } from './molecules/edit-card/edit-card.component';
import { MaskAllPipe } from './pipes/mask.pipe';
import { CustomerSatisfactionPollComponent } from './molecules/customer-satisfaction-poll/customer-satisfaction-poll.component';
import { PollQuestionComponent } from './molecules/customer-satisfaction-poll/poll-question/poll-question.component';
import { BooleanQuestionComponent } from './molecules/customer-satisfaction-poll/boolean-question/boolean-question.component';
import { AgreeQuestionComponent } from './molecules/customer-satisfaction-poll/agree-question/agree-question.component';
import { FreetextQuestionComponent } from './molecules/customer-satisfaction-poll/freetext-question/freetext-question.component';
import { PollDoneComponent } from './molecules/customer-satisfaction-poll/poll-done/poll-done.component';
import { ScaleQuestionComponent } from './molecules/customer-satisfaction-poll/scale-question/scale-question.component';
import { DotToCommaPipe } from './pipes/dot-to-comma.pipe';
import { ParenthesisOnlyPipe } from './pipes/parenthesis-only.pipe';
import { SelectButtonComponent } from './atoms/select-button/select-button.component';
import { SelectButtonGroupComponent } from './atoms/select-button-group/select-button-group.component';
import { SepaFormComponent } from './molecules/sepa-form/sepa-form.component';
import { BankAccountFormComponent } from './molecules/bank-account-form/bank-account-form.component';
import { PromoLinkComponent } from './atoms/promo-link/promo-link.component';
import { ScrollToInvalidControlDirective } from '../bdo/directives/scroll-to-invalid-control.directive';
import { ScrollOnInitDirective } from '../bdo/directives/scroll-on-init.directive';
import { SwitchCardComponent } from './molecules/switch-card/switch-card.component';
import { SlideOverComponent } from './molecules/slide-over/slide-over.component';
import { ToggleButtonComponent } from './atoms/toggle-button/toggle-button.component';
import { CustomHeaderComponent } from './atoms/date-picker/custom-header/custom-header.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { LinkTileComponent } from './molecules/link-tile/link-tile.component';
import { TrustpilotComponent } from './molecules/trustpilot/trustpilot.component';
import { MatSliderModule } from '@angular/material/slider';
import { SvgCmsComponent } from './atoms/svg-cms/svg-cms.component';
import { CoopTeaserComponent } from './molecules/coop-teaser/coop-teaser.component';
import { InfoTeaserComponent } from './molecules/info-teaser/info-teaser.component';
import { TextBoxPageComponent } from './molecules/text-box-page/text-box-page.component';
import { HintTileComponent } from './atoms/hint-tile/hint-tile.component';
import { TranslateModule } from '@ngx-translate/core';
import { FaqsComponent } from './molecules/faqs/faqs.component';
import { SquareIconTextComponent } from './atoms/square-icon-text/square-icon-text.component';
import { NoDataComponent } from './molecules/no-data/no-data.component';
import { PromoLinkWrapperComponent } from './molecules/promo-link-wrapper/promo-link-wrapper.component';
import { RouterLinkDirective } from './directives/router-link-directive.directive';
import { FilteredContentComponent } from './atoms/filtered-content/filtered-content.component';
import { CircleIconTextSmallComponent } from './atoms/circle-icon-text-small/circle-icon-text-small.component';
import { ToastNotificationComponent } from './atoms/toast-notification/toast-notification.component';
import { TariffPricesWorkingpriceComponent } from '../bdo/components/tariff-prices-workingprice/tariff-prices-workingprice.component';

@NgModule({
  imports: [
    AngularSvgIconModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatSliderModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxTippyModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule
  ],
  declarations: [
    CustomHeaderComponent,
    ToggleButtonComponent,
    DashboardTileComponent,
    InputComponent,
    VideoPopupComponent,
    DatePickerComponent,
    ZaehlerstandValidatorDirective,
    DefaultValidatorDirective,
    TextLinkIconComponent,
    InfoBoxComponent,
    TextIconComponent,
    SvgComponent,
    SvgDefinitionsComponent,
    DropdownComponent,
    CheckboxComponent,
    HomeLinkComponent,
    SectionHeadlineComponent,
    MediatextComponent,
    SimpleTextPopupComponent,
    SingleLineInputComponent,
    PasswordStrengthComponent,
    PopupContentComponent,
    SingleAccordionComponent,
    MoreButtonComponent,
    AccordionComponent,
    AccordionItemComponent,
    LargePopupComponent,
    MultiLineInputComponent,
    AccountidWithAddressComponent,
    AddressPipe,
    ProgressBarComponent,
    AutosuggestComponent,
    CardComponent,
    ValidateSubmitComponent,
    InlineTooltipComponent,
    EditCardComponent,
    MaskAllPipe,
    CustomerSatisfactionPollComponent,
    PollQuestionComponent,
    BooleanQuestionComponent,
    AgreeQuestionComponent,
    FreetextQuestionComponent,
    PollDoneComponent,
    ScaleQuestionComponent,
    DotToCommaPipe,
    ParenthesisOnlyPipe,
    LoadingComponent,
    SelectButtonComponent,
    SelectButtonGroupComponent,
    SelectionTileComponent,
    SepaFormComponent,
    BankAccountFormComponent,
    PromoLinkComponent,
    ScrollToInvalidControlDirective,
    ScrollOnInitDirective,
    SwitchCardComponent,
    SlideOverComponent,
    LinkTileComponent,
    TrustpilotComponent,
    SvgCmsComponent,
    CoopTeaserComponent,
    InfoTeaserComponent,
    TextBoxPageComponent,
    HintTileComponent,
    FaqsComponent,
    SquareIconTextComponent,
    NoDataComponent,
    PromoLinkWrapperComponent,
    RouterLinkDirective,
    FilteredContentComponent,
    CircleIconTextSmallComponent,
    ToastNotificationComponent,
    TariffPricesWorkingpriceComponent
  ],
  exports: [
    CustomHeaderComponent,
    DashboardTileComponent,
    InputComponent,
    VideoPopupComponent,
    SimpleTextPopupComponent,
    DatePickerComponent,
    ZaehlerstandValidatorDirective,
    DefaultValidatorDirective,
    TextLinkIconComponent,
    TextIconComponent,
    InfoBoxComponent,
    ToastNotificationComponent,
    SvgComponent,
    SvgDefinitionsComponent,
    DropdownComponent,
    CheckboxComponent,
    HomeLinkComponent,
    SectionHeadlineComponent,
    MediatextComponent,
    SingleLineInputComponent,
    PasswordStrengthComponent,
    PopupContentComponent,
    SingleAccordionComponent,
    MoreButtonComponent,
    AccordionComponent,
    AccordionItemComponent,
    LargePopupComponent,
    MultiLineInputComponent,
    AccountidWithAddressComponent,
    AddressPipe,
    ProgressBarComponent,
    AutosuggestComponent,
    CardComponent,
    ValidateSubmitComponent,
    InlineTooltipComponent,
    EditCardComponent,
    MaskAllPipe,
    CustomerSatisfactionPollComponent,
    DotToCommaPipe,
    ParenthesisOnlyPipe,
    LoadingComponent,
    SelectButtonComponent,
    SelectButtonGroupComponent,
    SelectionTileComponent,
    BankAccountFormComponent,
    SepaFormComponent,
    PromoLinkComponent,
    ScrollToInvalidControlDirective,
    ScrollOnInitDirective,
    SwitchCardComponent,
    SlideOverComponent,
    ToggleButtonComponent,
    LinkTileComponent,
    TrustpilotComponent,
    SvgCmsComponent,
    CoopTeaserComponent,
    InfoTeaserComponent,
    TextBoxPageComponent,
    HintTileComponent,
    TranslateModule,
    FaqsComponent,
    SquareIconTextComponent,
    NoDataComponent,
    PromoLinkWrapperComponent,
    RouterLinkDirective,
    FilteredContentComponent,
    CircleIconTextSmallComponent,
    TariffPricesWorkingpriceComponent
  ],
  providers: [
    provideNgxMask()
  ]
})
export class SharedModule {
}
