/**
 * NLB-GW
 * Diese fantastische API stellt verschiedene Services fuer den neuen RheinEnergie Online Service zur Verfuegung.  Insbesondere sind hier Services zum Zugriff auf das SAP iCos implementiert. 
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * periodic price for given year
 */
export interface TariffSummaryPeriodicPrices { 
    /**
     * number of year after start of contract, starting with 1
     */
    year?: number;
    /**
     * Calculated annual price
     */
    annualPriceGross?: number;
    /**
     * Calculated annual price
     */
    annualPriceNet?: number;
    /**
     * Calculated monthly price
     */
    monthlyPriceGross?: number;
    /**
     * Calculated monthly price
     */
    monthlyPriceNet?: number;
}

