/**
 * NLB-GW
 * Diese fantastische API stellt verschiedene Services fuer den neuen RheinEnergie Online Service zur Verfuegung.  Insbesondere sind hier Services zum Zugriff auf das SAP iCos implementiert. 
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * data object for billing plan item
 */
export interface BillingPlanItem { 
    billingPlanID?: string;
    /**
     * contractId of existing contract
     */
    contractId?: string;
    /**
     * division of product e.g. S4 for power, G6 for gas or T4 for water
     */
    division?: string;
    amount?: number;
}

