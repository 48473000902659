/**
 * NLB-GW
 * Diese fantastische API stellt verschiedene Services fuer den neuen RheinEnergie Online Service zur Verfuegung.  Insbesondere sind hier Services zum Zugriff auf das SAP iCos implementiert. 
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ContractOptionConditions { 
    /**
     * id of frame contract for a tariff
     */
    frameContractID?: string;
    /**
     * id of option; might be resolved to an productId in combination with a for a given FrameContractId
     */
    conditionalOptionId?: string;
    /**
     * E=excludes; I=includes; indicates if framecontractId and optionId can go together
     */
    includeExclude?: string;
}

