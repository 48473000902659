/**
 * NLB-GW
 * Diese fantastische API stellt verschiedene Services fuer den neuen RheinEnergie Online Service zur Verfuegung.  Insbesondere sind hier Services zum Zugriff auf das SAP iCos implementiert. 
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Prices for each element of the tariff (e.g. Basic price, options, meterprice)
 */
export interface ContractLevel { 
    contractLevelNumber?: string;
    description?: string;
    baseGross?: number;
    baseNet?: number;
    workGross?: number;
    workNet?: number;
    /**
     * if true, work prices should be treated as euro values instead of cent values
     */
    isEuro?: boolean;
    infoText?: string;
    priceDate?: Date;
}

