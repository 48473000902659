<form #loginForm="ngForm" class="re-form-container" (ngSubmit)="login(loginForm)" autocomplete="new-password" bdoScrollToInvalidControl [bdoScrollToInvalidControlForm]="loginForm">
  <bdo-loading *ngIf="isFetching"></bdo-loading>

  <fieldset>
    <div class="bdo-form-item-container">
      <ng-container [ngSwitch]="error">
        <div class="re-mb-10">
          <bdo-info-box *ngSwitchCase="'notfound'">
            {{ 'login.error.userNotFound' | translate }}
          </bdo-info-box>
          <bdo-info-box *ngSwitchCase="'general'">
            {{ 'general.error.error' | translate }}
          </bdo-info-box>
        </div>
      </ng-container>
      <div>
        <bdo-single-line-input *ngIf="!userExistsInCognito"
        #nameInput
        [inputName]="'name'"
        [placeholder]="'register.username.title' | translate"
        [labelText]="'register.username.title' | translate"
        [inputValue]="name"
        (inputValueChanged)="name=$event; onFocus();"
        (inputFocused)="onFocus()"
        [required]="true"
        [forceValidate]="validateAll"
        [validationIconHidden]="validationIconHidden"
        [errors]="[{text: ('register.username.required' | translate), name:'required'}]"
      ></bdo-single-line-input>
      <div *ngIf="userExistsInCognito" class="re-form-item-container-row">
        <div>{{'register.username.title' | translate}}</div>
        <div>{{name}}</div>
      </div>
      </div>
    </div>


    <div class="bdo-form-item-container" *ngIf="userExistsInCognito">
      <div>
        <bdo-single-line-input
          #passwordInput
          [inputName]="'password'"
          [inputValue]="password"
          [inputType]="'password'"
          [placeholder]="'register.password.title' | translate"
          [labelText]="'register.password.title' | translate"
          (inputValueChanged)="password=$event; onFocus();"
          (inputFocused)="onFocus()"
          [required]="true"
          [forceValidate]="validateAll"
          [validationIconHidden]="validationIconHidden"
          [errors]="[{text: ('register.password.required' | translate), name: 'required'},
                    {condition: password?.includes('<') || password?.includes('>'), text: ('register.password.htmlTags' | translate), name: 'htmlTagValidator'}]"
        ></bdo-single-line-input>

      </div>
    </div>

    <div *ngIf="!userExistsInCognito">
      <button class="re-button re-primary bdo-button--text bdo-space--bottom re-block" type="submit" [disabled]="isFetching">
        {{ 'general.action.continue' | translate }}
      </button>
      <a class="bdo-link--inline"
        [routerLink]="'/benutzername-vergessen'"
        (click)="trackingService.postTracking(TRACKING.LOCATION.LOGIN, TRACKING.ACTION.GOTO, TRACKING.LOCATION.PASSWORD_FORGOT)">
          {{ 'login.linkToForgot' | translate }}
      </a>
    </div>
    <div *ngIf="userExistsInCognito">
      <button class="re-button re-primary bdo-button--text bdo-space--bottom re-block" type="submit" [disabled]="isFetching">
       {{ 'login.title' | translate }}
      </button>

    <a class="bdo-link--inline"
      [routerLink]="'/passwort-vergessen'"
      (click)="trackingService.postTracking(TRACKING.LOCATION.LOGIN, TRACKING.ACTION.GOTO, TRACKING.LOCATION.PASSWORD_FORGOT)">
        {{ 'password.forgot.title' | translate }}
    </a>
    </div>

  </fieldset>

</form>
