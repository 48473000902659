import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { LoginFormComponent } from './login-form/login-form.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { AuthenticationFormComponent } from './authentication-form/authentication-form.component';
import { PasswordForgotComponent } from './password-forgot/password-forgot.component';
import { PasswordRenewComponent } from './password-renew/password-renew.component';
import { RouterModule } from '@angular/router';
import { RegisterFormComponent } from './register-form/register-form.component';
import { RegisterComponent } from './register/register.component';
import { RegisterSuccessComponent } from './register-success/register-success.component';
import { RegisterActivateComponent } from './register-activate/register-activate.component';
import { UsernameForgotComponent } from './username-forgot/username-forgot.component';
import { UsernameSuccessComponent } from './username-success/username-success.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AnonymousOrLoginComponent } from './anonymous-or-login/anonymous-or-login.component';
import { TermsOfServiceConfirmationComponent } from './terms-of-service-confirmation/terms-of-service-confirmation.component';
import { LoginpageComponent } from './loginpage/loginpage.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { EmailVerifyComponent } from './email-verify/email-verify.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    HttpClientModule,
    AngularSvgIconModule,
    RouterModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    provideNgxMask()
  ],
  declarations: [
    AuthenticationFormComponent,
    LoginComponent,
    LoginFormComponent,
    PasswordForgotComponent,
    PasswordRenewComponent,
    RegisterFormComponent,
    RegisterComponent,
    RegisterSuccessComponent,
    RegisterActivateComponent,
    UsernameForgotComponent,
    UsernameSuccessComponent,
    AnonymousOrLoginComponent,
    TermsOfServiceConfirmationComponent,
    LoginpageComponent,
    PasswordChangeComponent,
    EmailVerifyComponent
  ],
  exports: [
    AuthenticationFormComponent,
    AnonymousOrLoginComponent,
    PasswordChangeComponent
  ]
})
export class LoginModule { }
