import { Injectable } from '@angular/core';
import { Environment } from '../../../environments/environment';
import { filter, first, ReplaySubject } from 'rxjs';
import { STAGE } from '../enums/stage.enum';
import { KameleoonClient, KameleoonException } from 'kameleoon-client-javascript';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class KameleoonService {

  public static Goals = {
    DELIVERY_CONFIRMATION: 286607,
    PASSED_PERSONAL_DATA_PAGE: 286606,
    DELIVERY_NEW_HERE: 292967,
    DELIVERY_SITUATION_TO_BANKDATA: 292974,
    DELIVERY_BANKDATA_DONE: 292975
  };
  public kameleoonLoaded$ = new ReplaySubject<KameleoonClient>(1);
  private siteCode: string = Environment.kameleoon?.siteId;
  constructor() {
    this.kameleoonLoaded$.next(null);
  }

  public static getExperimentIdsOfRoute(activatedRoute: ActivatedRoute) {
    return activatedRoute?.snapshot?.data[`experimentIds`];
  }

  /**
   * get existing visitor code from cookie or create a new one, if no visitorcode exists
   *
   * we need to slice(4) the visitorcode, because the website creates visitor codes like aoxqgcfu6kxvuehv and the Kameleoon SDK
   * that is used in this angular application uses _js_aoxqgcfu6kxvuehv as visitor code. That results in getting a different variant than the website gets.
   * @param kameleoonClient KameleoonClient
   */
  obtainVisitorCode(kameleoonClient: KameleoonClient) {
    return kameleoonClient.getVisitorCode(Environment.kameleoon?.domain).slice(4);
  }

  processConversion(goalId: number, revenue?: number) {
    this.kameleoonLoaded$.pipe(
      filter((loaded) => !!loaded),
      first()
    ).subscribe({ next: (kameleoonClient) => {
      const visitorCode = this.obtainVisitorCode(kameleoonClient);
      kameleoonClient.trackConversion(visitorCode, goalId, revenue);
    } });
  }

  /**
   * returns the associatedVariationId and additionalParameters
   * @param experimentId
   * @param callback
   */
  loadExperiment<T>(experimentId: number, callback: (variationId: number, additionalParameters: T) => any) {

    this.kameleoonLoaded$.pipe(
      filter((loaded) => !!loaded),
      first()
    ).subscribe({ next: (kameleoonClient) => {
      try {
        const visitorCode = this.obtainVisitorCode(kameleoonClient);


        const variationId = kameleoonClient.triggerExperiment(visitorCode, experimentId);
        const additionalParameters = kameleoonClient.getVariationAssociatedData(variationId) as any;
        // only for debugging purposes, shows the current used variant
        // if (additionalParameters.variantName) {
        //   const variantContainer = document.createElement('div');
        //   variantContainer.innerHTML = additionalParameters.variantName;
        //   variantContainer.setAttribute('style', 'position: fixed; top:5px; left:5px; font-weight: bold;z-index:10000;
        //   background-color: rgba(255,255,255,0.6); border: 1px dashed black; padding: 10px 20px; border-radius: 20px');
        //   variantContainer.setAttribute('class', 're-top-1 re-absolute re-left-1');
        //   document.getElementsByTagName('body')[0].appendChild(variantContainer);
        // }

        // console.log(experiment.associatedVariationna);
        callback(variationId, additionalParameters);
      } catch (error) {
        this.logError(error);
        if (error.type === KameleoonException.NotTargeted) {
          // The user did not trigger the experiment, as the associated targeting segment conditions were not fulfilled. He should see the reference variation
          return null;
        }
        if (error.type === KameleoonException.NotActivated) {
          // The user triggered the experiment, but did not activate it. Usually, this happens because the user has been associated with excluded traffic
          return null;
        }
        if (error.type === KameleoonException.ExperimentConfigurationNotFound) {
          // The user will not be counted into the experiment, but should see the reference variation
          return null;
        }
      }
    } });
  }

  load() {
    const kameleoonClient = new KameleoonClient(this.siteCode, { 'actions_configuration_refresh_interval': 5 });
    kameleoonClient.runWhenReady(() => {
      this.kameleoonLoaded$.next(kameleoonClient);
    }, this.logError, 2000);
  }

  unload() {
    this.kameleoonLoaded$.next(null);
  }

  // todo use DebugLogger from authentication/migration branch
  private logError(...params) {
    if (Environment.stage !== STAGE.Production) {
      console.error('Kameleoon', ...params);
    }
  }

}
