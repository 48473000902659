import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { CampaignInfo } from '../../../assets/js/com/ts_api_client';
import { BdoApiService } from './bdo-api.service';
import { CustomerStoreService } from './customer-store.service';
import { TRACKING } from '../enums/trackingParts.enum';
import { TrackingService } from './tracking.service';
import { StorageService } from './storage.service';
import { TranslateService } from '@ngx-translate/core';
import { DatadogService } from './datadog.service';
import { Environment } from '../../../environments/environment';

export class CurrentCampaign {
  info: CampaignInfo;
  dismissed: boolean;
  linkToTariff: string;
  config: CampaignConfig;

  constructor(
    info: CampaignInfo,
    dismissed: boolean,
    linkToTariff: string,
    config: CampaignConfig
  ) {
    this.info = info;
    this.dismissed = dismissed;
    this.linkToTariff = linkToTariff;
    this.config = config;
  }

  toString() {
    try {
      return `BannerId: ${this.info.bannerId}, CampaignId: ${this.info.campaignId}, AccountId: ${this.info.accountId}, ProductId: ${this.info.productId}, DivisionId: ${this.info.divisionId}`;
    } catch (error) {
      return "";
    }
  }
}

export interface CampaignConfig {
  textHeadline: string;
  textDescription: string;
  mainLogoSrc: string;
  mainLogoAlt: string;
}

@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  public currentCampaign$: Observable<CurrentCampaign>;
  public currentCampaigns$: Observable<Array<CurrentCampaign>>;
  public hasNoCampaigns$: Observable<boolean>;

  constructor(private apiService: BdoApiService,
              private trackingService: TrackingService,
              private translateService: TranslateService,
              private customerStoreService: CustomerStoreService,
              private datadogService: DatadogService,
              private router: Router
  ) {


    this.currentCampaigns$ = this.customerStoreService?.currentAccountId$?.pipe(
      distinctUntilChanged(),
      switchMap((accountId) => {
        if (!accountId) { // Reset campaigns if logging out
          return of(undefined);
        } else {
          return this.apiService.getCampaignInfos(accountId).pipe(
            map((campaignInfos) => {
              return campaignInfos.map((campaignInfo) => ({
                info: campaignInfo,
                dismissed: this.isCampaignDismissed(campaignInfo),
                linkToTariff: this.buildLinkToTariffAgreement(campaignInfo),
                config: this.getCampaignConfig(campaignInfo)
              }));
            }),
            catchError((error: unknown) => {
              return of(null);
            })
          );

        }
      }),
      filter((campaigns) => !!campaigns),
      shareReplay(1)
    );

    this.hasNoCampaigns$ = this.currentCampaigns$?.pipe(
      map((campaigns) => campaigns.length === 0)
    );


      /**
     * return first non dismissed, if all are dismissed, return first campaign in array,
     * because the dashboard and the campaign popup both show one campaign, but the popup is dismissable
     * while the dashboard tile is not. The dashboard shows the same first non-dismissed campaign as the popup
     */
    this.currentCampaign$ = this.currentCampaigns$?.pipe(
      map((campaigns) => {
      const firstNonDismissedCampaign = campaigns.find((campaign) => campaign.dismissed !== true);
      this.datadogService.trackCustomEvent('CurrentCampaign$: ' + campaigns.map((campaign) => campaign?.toString()).join(", ") + ' # FirstNonDismissed: ' + firstNonDismissedCampaign?.toString());
      return firstNonDismissedCampaign ? firstNonDismissedCampaign : campaigns[0];
    }));
  }

  public isCampaignDismissed(campaign: CampaignInfo) {
    const storedCampaigns = StorageService.getCampaignsData();
    if (!storedCampaigns) {
      return false;
    }
    return storedCampaigns?.some((item) =>
      item.accountId === campaign.accountId &&
      item.bannerId === campaign.bannerId);
  }

  dismissCampaign(campaign: CurrentCampaign) {
    StorageService.addCampaign(campaign.info);
  }

  goToCampaign(customCampaign?: CurrentCampaign): Observable<boolean> {
    StorageService.clearOldContract();
    StorageService.clearTariffData();
    if (!Environment.tariffChangeIsEnabled()){
      return from(this.router.navigate(["/hinweis"]).then(() => true));
    }
    if (customCampaign){
      return from(this.router.navigate([customCampaign.linkToTariff]).then(() => true));
    }
    return this.currentCampaign$.pipe(
      tap((campaign) => {
        if (campaign) {
          this.trackingService.postSimpleTracking(TRACKING.LOCATION.CAMPAIGN, TRACKING.ACTION.GOTO + ' Offer ' + campaign.info.bannerId);
          StorageService.clearOldContract();
          StorageService.clearTariffData();
        }
      }),
      switchMap((campaign) => {
        if (campaign) {
          const navigationExtras: NavigationExtras = {};
          const url = new URL(campaign.linkToTariff, window.location.origin);
          if (url.searchParams.has('bannerId')) {
            navigationExtras.queryParams = { bannerId: campaign.info.bannerId };
          }
          return this.router.navigate([url.pathname], navigationExtras).then(() => true);
        } else {
          return of(false);
        }
      })
    );
  }

  buildLinkToTariffAgreement(campaign: CampaignInfo) {
    return  'vertrag/' + campaign.accountId + '/anpassen/tarifauswahl?bannerId=' + campaign.bannerId;
  }


  getCampaignConfig(campaign: CampaignInfo): CampaignConfig {
    const defaultConfig: CampaignConfig = {
      textHeadline: this.translateService.instant('campaign.default.title'),
      textDescription: this.translateService.instant('campaign.default.description'),
      mainLogoSrc: this.translateService.instant('campaign.default.logo'),
      mainLogoAlt: this.translateService.instant('campaign.default.logoAlt')
    };

    const configs: Array<{ bannerIds: Array<string>, config: CampaignConfig }> = [

      {
        bannerIds: [
          'BK_GAS_MVP_2',
          'BK_GAS_TMVP_2',
          'SWA_GAS_MVP_2',
          'SWA_GAS_TMVP_2',
          'RE_GAS_MVP_2',
          'RE_GAS_TMVP_2',
          'BK_GAS_MVP_2'
        ],
        config: {
          textHeadline: this.translateService.instant('campaign.newPriceGuaranteeG6.title'),
          textDescription: this.translateService.instant('campaign.newPriceGuaranteeG6.description'),
          mainLogoSrc: this.translateService.instant('campaign.newPriceGuaranteeG6.logo'),
          mainLogoAlt: this.translateService.instant('campaign.newPriceGuaranteeG6.logoAlt')
        }
      },
      {
        bannerIds: [
          'BK_STROM_MVP_2',
          'BK_STROM_TMVP_2',
          'SWA_STROM_MVP_2',
          'SWA_STROM_TMVP_2',
          'RE_STROM_MVP_2',
          'RE_STROM_TMVP_2',
          'SA_STROM_MVP_2',
          'SA_TRK22_MVP_1',
          'SA_FRK22_MVP_1'
        ],
        config: {
          textHeadline: this.translateService.instant('campaign.newPriceGuaranteeS4.title'),
          textDescription: this.translateService.instant('campaign.newPriceGuaranteeS4.description'),
          mainLogoSrc: this.translateService.instant('campaign.newPriceGuaranteeS4.logo'),
          mainLogoAlt: this.translateService.instant('campaign.newPriceGuaranteeS4.logoAlt')
        }
      },
      {
        bannerIds: [
          'BK_WP_WPE',
          'SA_WP_WPE',
          'RE_WP_WPE'
        ],
        config: {
          textHeadline: this.translateService.instant('campaign.newHeatpump.title'),
          textDescription: this.translateService.instant('campaign.newHeatpump.description'),
          mainLogoSrc: this.translateService.instant('campaign.newHeatpump.logo'),
          mainLogoAlt: this.translateService.instant('campaign.newHeatpump.logoAlt')
        }
      },
      {
        bannerIds: ['CANCEL'],
        config: {
          textHeadline: this.translateService.instant('campaign.cancel.title'),
          textDescription: this.translateService.instant('campaign.cancel.description'),
          mainLogoSrc: this.translateService.instant('campaign.cancel.logo'),
          mainLogoAlt: this.translateService.instant('campaign.cancel.logoAlt')
        }
      }
    ];
    const configForBannerId = configs.find((config) => config.bannerIds.includes(campaign.bannerId));
    if (!configForBannerId) {
      return defaultConfig;
    } else {
      return configForBannerId.config;
    }
  }

}
