<ng-container>
    <label [for]="inputName" class="animated fadeInUp"
      [class.floating]="getFloating(input)"
      [class.hidden]="getHidden(input)">
      {{labelText}}
    </label>
    <ng-container *ngFor="let error of errors">
      <div class="error" *ngIf="(((input.touched || focused) && showDelayed && !validationIconHidden) || (forceValidate && !(input.touched || focused))) &&
      (input.invalid && input.errors[error.name]) ">
        {{error.text}}
      </div>
    </ng-container>
    <!-- <ng-container *ngIf="input.touched && !focused && input.invalid && showDelayed">
      <div *ngIf="input.errors[required]">
        Bitte geben Sie einen Wert ein.
      </div>
    </ng-container> -->

    <div class="re-relative">
      <input #input="ngModel"
        [(ngModel)]="inputValue"
        [name]="inputName"
        [id]="inputName"
        (focus)="onFocus()"
        [required]="required"
        [bdoDefaultValidator]="errors"
        [type]="inputType? inputType : 'text'"
        [minLength]="minLength? minLength : null"
        [maxlength]="maxLength? maxLength : null"
        [email]="inputType==='email'"
        [placeholder]="placeholder"
        (focus)="onFocus()"
        (blur)="onBlur()"
        (ngModelChange)="onChange($event)"
        [autocomplete]="inputType === 'password' ? 'new-password' : 'off'"> <!-- Prevent Chrome Autofill-->
      <div *ngIf="((forceValidate && !(input.touched || focused)) || showDelayed) && !validationIconHidden" class="validation-icon">
        <bdo-svg [name]="input.valid? 'icon-success' : 'icon-alert'" [styling]="input.valid? 'bdo-svg--success' : 'bdo-svg--error'"></bdo-svg>
      </div>
    </div>
  </ng-container>


<!--   Valid: {{input.valid}} # Touched: {{input.touched}} # Focus: {{focused}} # Delayed: {{showDelayed}} #forceVal: {{forceValidate}}
  <br>
  Errors: {{input.errors | json}}
  <br><br>
  Floating: {{isFloating}} # Hidden: {{isHidden}} # Error: {{isError}} -->

<!--   <pre>
    valid: {{ input.valid }}
    pristine: {{ input.pristine }}
    touched: {{ input.touched }}
    {{ input.value }}
    {{ input.errors | json }}
  </pre> -->
