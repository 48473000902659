function debug(logLevel, ...messages) {
  if (logLevel === 'debug') console.log(...messages);
}
function warn(logLevel, warning) {
  if (logLevel === 'debug' || logLevel === 'warn') {
    // https://github.com/typescript-eslint/typescript-eslint/issues/7478
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (typeof process !== 'undefined' && process.emitWarning) process.emitWarning(warning);else console.warn(warning);
  }
}
export { debug, warn };