import { Injectable } from '@angular/core';
import { Environment } from '../../../environments/environment';
import { TENANT_ACRONYM } from '../enums/tenant.enum';
import { VERBRAUCHSTYP } from "../enums/verbrauchstyp.enum";

export interface TenantData {
  companyName: string,
  bannerLogo: string,
  tenantName: string,
  tenantForTexts: string,
  supply: string,
  hrefHomepage: string,
  hrefDataPrivacy: string,
  hrefDataPrivacyDisplayName: string,
  hrefFrequentQuestions: string,
  hrefPhoneContact: string,
  hrefContact: string,
  hrefContactForm: string,
  hrefHelpCenterCancel: string,
  hrefImpressum: string,
  hrefPriceCap: string,
  hrefPriceCapElectricity: string,
  hrefPriceCapGas: string,
  hrefBillExplanation: string,
  mailto: string,
  mailtoDataPrivacy: string,
  addressDataPrivacy: string,
  address: string,
  phonenumber: string,
  phonenumberGewe?: string,
  tariffAdvisorLinks: {
    [VERBRAUCHSTYP.Gas]: string,
    [VERBRAUCHSTYP.Strom]: string
  },
  city: string,
  short: string
}

export const tenantData: { [key: string]: TenantData } = {
  re: {
    short: 'RE',
    bannerLogo: 'assets/img/rheinenergie/banner_logo.svg',
    companyName: 'RheinEnergie',
    tenantForTexts: 'bei der RheinEnergie',
    tenantName: 'RheinEnergie AG',
    supply: 'Energie- oder Wasser',
    hrefHomepage: 'https://www.rheinenergie.com/de/',
    hrefDataPrivacy: 'https://www.rheinenergie.com/de/datenschutz.html',
    hrefDataPrivacyDisplayName: 'www.rheinenergie.com/datenschutz',
    hrefFrequentQuestions: 'https://www.rheinenergie.com/de/privatkunden/kundenservice/kontakt_und_haeufige_fragen/haeufige_fragen/haeufige_fragen.html',
    hrefPhoneContact: 'https://www.rheinenergie.com/kontakt ',
    hrefContact: 'https://www.rheinenergie.com/kontakt',
    hrefContactForm: 'https://www.rheinenergie.com/kontaktformular',
    hrefHelpCenterCancel: 'https://www.rheinenergie.com/de/hilfecenter/wechsel_und_kuendigung/kuendigung_abmeldung/kuendigung_abmeldung.html?tablink=1',
    hrefImpressum: 'https://www.rheinenergie.com/de/impressum.html',
    hrefPriceCap: 'https://www.rheinenergie.com/energiepreisbremsen',
    hrefPriceCapElectricity: 'https://www.rheinenergie.com/de/hilfecenter/preisbremsen___soforthilfe/strom_2/strom.html',
    hrefPriceCapGas: 'https://www.rheinenergie.com/de/hilfecenter/preisbremsen___soforthilfe/gas/gas.html',
    hrefBillExplanation: 'https://www.rheinenergie.com/rechnung',
    mailtoDataPrivacy: 'datenschutz@rheinenergie.com',
    addressDataPrivacy: 'RheinEnergie AG, Datenschutzbeauftragter, Parkgürtel 24, 50823 Köln',
    address: 'RheinEnergie AG, Parkgürtel 24, 50823 Köln',
    mailto: 'service@rheinenergie.com',
    phonenumber: '0221 34645-300',
    phonenumberGewe: '0221 34645-300',
    city: 'Köln',
    tariffAdvisorLinks: {
      [VERBRAUCHSTYP.Gas]: 'https://www.rheinenergie.com/de/privatkunden/strom___erdgas/erdgas/erdgas_fuer_zu_hause/erdgas_fuer_zu_hause.html',
      [VERBRAUCHSTYP.Strom]: 'https://www.rheinenergie.com/de/privatkunden/strom___erdgas/strom/strom_fuer_zu_hause/strom_fuer_zu_hause.html'
    }
  },
  belkaw: {
    short: 'BELKAW',
    bannerLogo: 'assets/img/belkaw/banner_logo.svg',
    companyName: 'BELKAW',
    tenantForTexts: 'bei der BELKAW',
    tenantName: 'BELKAW GmbH',
    supply: 'Energie- oder Wasser',
    hrefHomepage: 'https://www.belkaw.de/',
    hrefDataPrivacy: 'https://www.belkaw.de/datenschutz',
    hrefDataPrivacyDisplayName: 'www.belkaw.de/datenschutz',
    hrefFrequentQuestions: 'https://www.belkaw.de/kundenservice',
    hrefPhoneContact: 'https://www.belkaw.de/wichtige-telefonnummern',
    hrefContact: 'https://www.belkaw.de/kontaktformular',
    hrefContactForm: 'https://www.belkaw.de/kontaktformular',
    hrefHelpCenterCancel: 'https://www.belkaw.de/hilfecenter',
    hrefImpressum: 'https://www.belkaw.de/impressum',
    hrefPriceCap: 'https://www.belkaw.de/preisbremse',
    hrefPriceCapElectricity: 'https://www.belkaw.de/strompreisbremse',
    hrefPriceCapGas: 'https://www.belkaw.de/gaspreisbremse',
    hrefBillExplanation: 'https://www.belkaw.de/html/rechnungserklaerung/index.html',
    mailtoDataPrivacy: 'datenschutz@belkaw.de',
    addressDataPrivacy: 'BELKAW GmbH, Datenschutzbeauftragter, Hermann-Löns-Straße 131, 51469 Bergisch Gladbach',
    address: 'Hermann-Löns-Straße 131, 51469 Bergisch Gladbach',
    mailto: 'kundenservice@BELKAW.de',
    phonenumber: '02202 2855-800',
    phonenumberGewe: undefined,
    city: 'Bergisch Gladbach',
    tariffAdvisorLinks: {
      [VERBRAUCHSTYP.Gas]: 'https://www.belkaw.de/erdgas-fuer-zuhause',
      [VERBRAUCHSTYP.Strom]: 'https://www.belkaw.de/strom-fuer-zuhause'
    }
  },
  swl: {
    short: 'SWL',
    bannerLogo: 'assets/img/leichlingen/banner_logo.svg',
    companyName: 'Stadtwerke Leichlingen',
    tenantName: 'Stadtwerke Leichlingen GmbH',
    tenantForTexts: 'bei den Stadtwerken Leichlingen',
    supply: 'Energie- oder Wasser',
    hrefHomepage: 'http://www.stadtwerke-leichlingen.de/',
    hrefDataPrivacy: 'https://www.stadtwerke-leichlingen.de/Datenschutz.html',
    hrefDataPrivacyDisplayName: 'www.stadtwerke-leichlingen.de/Datenschutz.html',
    hrefFrequentQuestions: 'https://www.stadtwerke-leichlingen.de/',
    hrefPhoneContact: 'https://www.stadtwerke-leichlingen.de/kontakt.html?secure',
    hrefContact: 'https://www.stadtwerke-leichlingen.de/kontakt.html',
    hrefContactForm: 'https://www.stadtwerke-leichlingen.de/kontakt.html',
    hrefHelpCenterCancel: '',
    hrefImpressum: 'https://www.stadtwerke-leichlingen.de/impressum.html',
    hrefPriceCap: 'https://www.stadtwerke-leichlingen.de/Preisbremse.html',
    hrefPriceCapElectricity: 'https://www.stadtwerke-leichlingen.de/Preisbremse.html',
    hrefPriceCapGas: 'https://www.stadtwerke-leichlingen.de/Preisbremse.html',
    hrefBillExplanation: '',
    mailtoDataPrivacy: 'info@stadtwerke-leichlingen.de',
    addressDataPrivacy: 'Stadtwerke Leichlingen GmbH, Datenschutzbeauftragter, Im Brückerfeld 15, 42799 Leichlingen',
    address: 'Stadtwerke Leichlingen GmbH, Im Brückerfeld 15, 42799 Leichlingen',
    mailto: 'info@stadtwerke-leichlingen.de',
    phonenumber: '02175 977-0',
    phonenumberGewe: '02175 977-0',
    city: '',
    tariffAdvisorLinks: {
      [VERBRAUCHSTYP.Gas]: 'https://www.stadtwerke-leichlingen.de/cms/tarife.html',
      [VERBRAUCHSTYP.Strom]: 'https://www.stadtwerke-leichlingen.de/cms/tarife.html'
    }
  },
  swa: {
    short: 'SWA',
    bannerLogo: 'assets/img/sanktaugustin/banner_logo.svg',
    companyName: 'Stadtwerke Sankt Augustin',
    tenantName: 'Stadtwerke Sankt Augustin GmbH',
    tenantForTexts: 'bei den Stadtwerken Sankt Augustin',
    supply: 'Energie',
    hrefHomepage: 'http://www.stadtwerke-sankt-augustin.de/',
    hrefDataPrivacy: 'https://www.stadtwerke-sankt-augustin.de/datenschutz',
    hrefDataPrivacyDisplayName: 'www.stadtwerke-sankt-augustin.de/datenschutz',
    hrefFrequentQuestions: 'https://www.stadtwerke-sankt-augustin.de/kundenservice',
    hrefPhoneContact: 'https://www.stadtwerke-sankt-augustin.de/so-erreichen-sie-uns',
    hrefContact: 'https://www.stadtwerke-sankt-augustin.de/so-erreichen-sie-uns',
    hrefContactForm: 'http://www.stadtwerke-sankt-augustin.de/kontaktformular',
    hrefHelpCenterCancel: 'https://www.stadtwerke-sankt-augustin.de/hilfecenter',
    hrefImpressum: 'http://www.stadtwerke-sankt-augustin.de/impressum',
    hrefPriceCap: 'https://www.stadtwerke-sankt-augustin.de/preisbremse',
    hrefPriceCapElectricity: 'https://www.stadtwerke-sankt-augustin.de/strompreisbremse',
    hrefPriceCapGas: 'https://www.stadtwerke-sankt-augustin.de/gaspreisbremse',
    hrefBillExplanation: 'https://www.stadtwerke-sankt-augustin.de/rechnungserklaerung',
    mailtoDataPrivacy: 'datenschutz@stadtwerke-sankt-augustin.de',
    addressDataPrivacy: 'Stadtwerke Sankt Augustin GmbH, Datenschutzbeauftragter, Mendener Straße 23, 53757 Sankt Augustin',
    address: 'Stadtwerke Sankt Augustin GmbH, Mendener Straße 23, 53757 Sankt Augustin',
    mailto: 'service@stadtwerke-sankt-augustin.de',
    phonenumber: '02241 25285-25300',
    phonenumberGewe: undefined,
    city: 'Siegburg',
    tariffAdvisorLinks: {
      [VERBRAUCHSTYP.Gas]: 'https://www.stadtwerke-sankt-augustin.de/erdgas',
      [VERBRAUCHSTYP.Strom]: 'https://www.stadtwerke-sankt-augustin.de/strom-fuer-zuhause'
    }
  },
  slo: {
    short: 'SLO',
    bannerLogo: 'assets/img/lohmar/banner_logo.svg',
    companyName: 'Stadtwerke Lohmar',
    tenantName: '',
    tenantForTexts: 'bei den Stadtwerken Lohmar',
    supply: '',
    hrefHomepage: '',
    hrefDataPrivacy: '',
    hrefDataPrivacyDisplayName: '',
    hrefFrequentQuestions: '',
    hrefPhoneContact: '',
    hrefContact: '',
    hrefContactForm: '',
    hrefHelpCenterCancel: '',
    hrefImpressum: '',
    hrefPriceCap: '',
    hrefPriceCapElectricity: '',
    hrefPriceCapGas: '',
    hrefBillExplanation: '',
    mailtoDataPrivacy: '',
    addressDataPrivacy: '',
    address: '',
    mailto: '',
    phonenumber: '',
    phonenumberGewe: '',
    city: '',
    tariffAdvisorLinks: {
      [VERBRAUCHSTYP.Gas]: 'https://www.stadtwerke-lohmar.de/service/onlineservice/anmeldung/',
      [VERBRAUCHSTYP.Strom]: 'https://www.stadtwerke-lohmar.de/service/onlineservice/anmeldung/'
    }
  },
  agger: {
    companyName: 'AggerEnergie',
    short: 'AGGER',
    tariffAdvisorLinks: {
      [VERBRAUCHSTYP.Gas]: 'https://www.aggerenergie.de/',
      [VERBRAUCHSTYP.Strom]: 'https://www.aggerenergie.de/'
    },
    bannerLogo: 'assets/img/agger_banner_logo.svg',
    tenantForTexts: 'bei der AggerEnergie',
    tenantName: '',
    supply: '',
    hrefHomepage: '',
    hrefDataPrivacy: '',
    hrefDataPrivacyDisplayName: '',
    hrefFrequentQuestions: '',
    hrefPhoneContact: '',
    hrefContact: '',
    hrefContactForm: '',
    hrefHelpCenterCancel: '',
    hrefImpressum: '',
    hrefPriceCap: '',
    hrefPriceCapElectricity: '',
    hrefPriceCapGas: '',
    hrefBillExplanation: '',
    mailtoDataPrivacy: '',
    addressDataPrivacy: '',
    address: '',
    mailto: '',
    phonenumber: '',
    city: '',
  },
  evd: {
    companyName: 'EVD',
    short: 'EVD',
    tariffAdvisorLinks: {
      [VERBRAUCHSTYP.Gas]: 'https://www.evd-dormagen.de/',
      [VERBRAUCHSTYP.Strom]: 'https://www.evd-dormagen.de/'
    },
    bannerLogo: 'assets/img/evd_banner_logo.svg',
    tenantForTexts: 'bei der EVD',
    tenantName: '',
    supply: '',
    hrefHomepage: '',
    hrefDataPrivacy: '',
    hrefDataPrivacyDisplayName: '',
    hrefFrequentQuestions: '',
    hrefPhoneContact: '',
    hrefContact: '',
    hrefContactForm: '',
    hrefHelpCenterCancel: '',
    hrefImpressum: '',
    hrefPriceCap: '',
    hrefPriceCapElectricity: '',
    hrefPriceCapGas: '',
    hrefBillExplanation: '',
    mailtoDataPrivacy: '',
    addressDataPrivacy: '',
    address: '',
    mailto: '',
    phonenumber: '',
    city: '',
  },
  evl: {
    companyName: 'EVL',
    short: 'EVL',
    tariffAdvisorLinks: {
      [VERBRAUCHSTYP.Gas]: 'https://www.evl-gmbh.de/',
      [VERBRAUCHSTYP.Strom]: 'https://www.evl-gmbh.de/'
    },
    bannerLogo: 'assets/img/evl_banner_logo.svg',
    tenantForTexts: 'bei der EVL',
    tenantName: '',
    supply: '',
    hrefHomepage: '',
    hrefDataPrivacy: '',
    hrefDataPrivacyDisplayName: '',
    hrefFrequentQuestions: '',
    hrefPhoneContact: '',
    hrefContact: '',
    hrefContactForm: '',
    hrefHelpCenterCancel: '',
    hrefImpressum: '',
    hrefPriceCap: '',
    hrefPriceCapElectricity: '',
    hrefPriceCapGas: '',
    hrefBillExplanation: '',
    mailtoDataPrivacy: '',
    addressDataPrivacy: '',
    address: '',
    mailto: '',
    phonenumber: '',
    city: '',
  },

  swb: {
    companyName: 'Stadtwerke Bonn',
    short: 'SWB',
    tariffAdvisorLinks: {
      [VERBRAUCHSTYP.Gas]: 'https://www.stadtwerke-bonn.de/fuer-zuhause/',
      [VERBRAUCHSTYP.Strom]: 'https://www.stadtwerke-bonn.de/fuer-zuhause/'
    },
    bannerLogo: 'assets/img/swb_banner_logo.svg',
    tenantForTexts: 'bei den Stadtwerken Bonn',
    tenantName: '',
    supply: '',
    hrefHomepage: '',
    hrefDataPrivacy: '',
    hrefDataPrivacyDisplayName: '',
    hrefFrequentQuestions: '',
    hrefPhoneContact: '',
    hrefContact: '',
    hrefContactForm: '',
    hrefHelpCenterCancel: '',
    hrefImpressum: '',
    hrefPriceCap: '',
    hrefPriceCapElectricity: '',
    hrefPriceCapGas: '',
    hrefBillExplanation: '',
    mailtoDataPrivacy: '',
    addressDataPrivacy: '',
    address: '',
    mailto: '',
    phonenumber: '',
    city: '',
  },

  gvg: {
    companyName: 'GVG',
    short: 'GVG',
    tariffAdvisorLinks: {
      [VERBRAUCHSTYP.Gas]: 'https://www.gvg.de/',
      [VERBRAUCHSTYP.Strom]: 'https://www.gvg.de/'
    },
    bannerLogo: 'assets/img/gvg_banner_logo.svg',
    tenantForTexts: 'bei der GVG',
    tenantName: '',
    supply: '',
    hrefHomepage: '',
    hrefDataPrivacy: '',
    hrefDataPrivacyDisplayName: '',
    hrefFrequentQuestions: '',
    hrefPhoneContact: '',
    hrefContact: '',
    hrefContactForm: '',
    hrefHelpCenterCancel: '',
    hrefImpressum: '',
    hrefPriceCap: '',
    hrefPriceCapElectricity: '',
    hrefPriceCapGas: '',
    hrefBillExplanation: '',
    mailtoDataPrivacy: '',
    addressDataPrivacy: '',
    address: '',
    mailto: '',
    phonenumber: '',
    city: '',
  },
};

const templateTos = (
  values: TenantData
) => `V02/10.2020
<ol>
  <li><span class="bdo-list__head">Vertragsgegenstand</span>
    <ol>
      <li>Der kostenlose OnlineService ist ein Internetangebot der ${values.tenantName} (im Folgenden „Lieferant“ genannt) für registrierte, d.h. mit einer persönlichen Zugriffsberechtigung ausgestattete Personen (im Folgenden „Nutzer“ genannt). Der OnlineService stellt eine Möglichkeit zum digitalen Abschluss und der digitalen Verwaltung von Verträgen und einen zusätzlichen Kommunikationsweg zwischen dem Nutzer und dem Lieferanten dar. Hierunter fallen z.B. der Abschluss von Verträgen, Nutzerdatenänderungen, Zählerstandsmitteilungen, Einzugs- oder Auszugsmeldungen und ein geschützter elektronischer Postfachbereich. Die Kommunikation erfolgt elektronisch. Einige wichtige Mitteilungen (z.B. Mahnungen) erfolgen weiterhin nur auf dem Postweg.</li>
      <li>Einige Dienste z.B. Zählerstandsmitteilungen sowie An-, Ab- und Ummeldungen können vom Nutzer im OnlineService auch ohne Registrierung vorgenommen werden.</li>
      <li>Die einem ${values.supply}lieferungsvertrag oder einem anderen Vertrag zwischen dem Nutzer und dem Lieferanten zugrundeliegenden Vertragsbedingungen werden durch diese Nutzungsbedingungen nicht berührt.</li>
    </ol>
  </li>
  <li><span class="bdo-list__head">Voraussetzungen für Registrierung und Nutzung des OnlineService</span>
    <ol>
      <li>Um sich für den OnlineService registrieren zu können, muss der Nutzer Kunde des Lieferanten sein und eine gültige Kundennummer sowie eine Zählernummer bei dem Lieferanten haben. Die Kundennummer erhält der Kunde von dem Lieferanten bei Abschluss eines ${values.supply}liefervertrages. </li>
      <li>Der Nutzer verfügt zu jeder Zeit über eine gültige E-Mail-Adresse, an die für ihn bestimmte Informationen und rechtsverbindliche Benachrichtigungen aus dem OnlineService versendet werden können. </li>
      <li>Für das Öffnen von durch den Lieferanten zur Verfügung gestellten PDF-Dateien benötigt der Nutzer
        entsprechende kostenlose Programme, wie z.B. Adobe Acrobat Reader (Adobe Acrobat ist eine eingetragene Marke der Adobe Systems Inc.), auf dem von ihm verwendeten Gerät.</li>
      <li>Der Nutzer muss sich mit den jeweils gültigen Nutzungsbedingungen einverstanden erklären.</li>
    </ol>
  </li>
  <li><span class="bdo-list__head">Registrierung</span>
    <ol>
      <li>Die Registrierung ist für den Nutzer freiwillig und kostenlos.</li>
      <li>Für die Registrierung muss der Nutzer seine Kunden- und Zählernummer in der Eingabemaske im Registrierungsprozess eingeben sowie einen Benutzernamen und ein Passwort vergeben. Im weiteren Registrierungsprozess hat der Nutzer die noch fehlenden Angaben zu machen, die für den Registrierungsprozess und die zukünftige Nutzung des OnlineService notwendig sind (z.B. seine Email-Adresse) sowie die Nutzungsbedingungen zu akzeptieren. Der Lieferant behält sich vor, seinen Kunden zukünftig auch andere Registrierungsmöglichkeiten zur Verfügung stellen. </li>
      <li>Die Registrierung ist erst abgeschlossen, wenn der Nutzer nach der Eingabe der Registrierungsdaten den Aktivierungslink in der an ihn versandten E-Mail anklickt und die Registrierung bestätigt (sog. Double-Opt-In). Dem Nutzer wird die Registrierung bzw. Freischaltung des Kontos bestätigt. Die ggf. bestehenden Verträge mit dem Lieferanten werden, wenn technisch möglich, dem neu angelegten Benutzerkonto im OnlineService zugeordnet. </li>
      <li>Für die Richtigkeit, Erreichbarkeit und ggf. erforderliche Änderung der hinterlegten E-Mail-Adresse ist der Nutzer selbst verantwortlich. Zur Sicherstellung der Erreichbarkeit gehört beispielsweise auch die regelmäßige Überprüfung des SPAM-Ordners.</li>
      <li>Der Lieferant behält sich vor, in begründeten Fällen die Registrierung einzelner Nutzer abzulehnen.</li>
    </ol>
  </li>
  <li><span class="bdo-list__head">Nutzung des OnlineService</span>
    <ol>
      <li>Der Nutzer ist für die Daten und Inhalte, die er in seinem Benutzerkonto einstellt, selbst verantwortlich. Insbesondere hat er seine eingegebenen Daten und Inhalte auf Vollständigkeit, Richtigkeit und Fristeinhaltung zu überprüfen und muss sie stets aktuell halten. Dazu gehört auch jede Änderung der vom Nutzer im OnlineService verwendeten E-Mail-Adresse. </li>
      <li>Der Nutzer darf den OnlineService nur im Rahmen der von dem Lieferanten vorgesehenen Funktionen nutzen.</li>
      <li>Ist eine Übermittlung von Daten über den OnlineService nicht möglich, entbindet dies den Kunden nicht von seinen aus dem Liefervertrag bestehenden Pflichten. In diesem Fall sind andere Kommunikationswege zu nutzen.</li>
      <li>Willenserklärungen jeder Art gelten als rechtsverbindlich abgegeben, sobald der Nutzer diese über die jeweilige Funktion des OnlineService an den Lieferanten abgesendet hat.</li>
    </ol>
  </li>
  <li><span class="bdo-list__head">Versandart und elektronisches Dokumentenarchiv</span>
    <ol>
      <li>Der Nutzer kann nach der Anmeldung im OnlineService mit seinem Benutzernamen und Passwort auf sein elektronisches Dokumentenarchiv zugreifen. </li>
      <li>Im persönlichen elektronischen Dokumentenarchiv des Nutzers legt der Lieferant die Dokumente für den Nutzer zugangsgeschützt vor Dritten ab. Befinden sich neue Dokumente im elektronischen Dokumentenarchiv, bekommt der Nutzer hierüber eine E-Mail an seine angegebene E-Mail-Adresse. Wird die E-Mail vom Nutzer nicht gelesen (z.B. weil sie in den Spam-Ordner des Nutzers geleitet wurde), hat das keine Auswirkung auf die rechtliche Wirksamkeit der Nachricht. </li>
      <li>Der Nutzer ist verpflichtet, regelmäßig zu prüfen, ob der Lieferant neue Inhalte in sein elektronisches Dokumentenarchiv eingestellt hat. Die von dem Lieferanten erstellten Dokumente und Mitteilungen sind vom Nutzer unverzüglich zu prüfen und ggf. zu beanstanden.</li>
    </ol>
  </li>
  <li><span class="bdo-list__head">Pflichten des Nutzers / Schutz vor Zugriff Dritter</span>
    <ol>
      <li>Der Nutzer hat dafür Sorge zu tragen, dass die im Rahmen der Registrierung abgefragten Daten vollständig und wahrheitsgemäß erfasst werden.</li>
      <li>Der Nutzer ist verpflichtet, die für die Registrierung und Anmeldung zum OnlineService erforderlichen Daten vor dem Zugriff Dritter zu schützen. Verstößt der Nutzer gegen diese Verpflichtung, geht eine missbräuchliche Nutzung dieser Daten zu seinen Lasten. Hat der Nutzer Grund zur Annahme, dass nicht-autorisierte Dritte Kenntnis von den Zugangsdaten bekommen, so hat er sein Passwort für den Zugang zum OnlineService unverzüglich zu ändern. Der Lieferant ist über die unautorisierte Nutzung des OnlineService unverzüglich in Kenntnis zu setzen.</li>
      <li>Bei Nutzung des OnlineService durch einen vom Nutzer bestimmten Dritten, hat der Nutzer diesen Dritten durch die Weitergabe der hierfür benötigten Zugangsdaten zur Nutzung autorisiert. Der Lieferant kann grundsätzlich nicht feststellen, ob der Nutzer selbst oder ein Dritter das Benutzerkonto verwendet und sind nicht für den Zugriff eines Dritten mit den Zugangsdaten des Nutzers haftbar. </li>
      <li>Der Nutzer kann sein Passwort jederzeit ändern. Um Missbrauch zu vermeiden, sollte er von dieser Möglichkeit in regelmäßigen Abständen Gebrauch machen.</li>
      <li>Dem Nutzer sind die Risiken der Internetnutzung ebenso wie die Möglichkeit von bestehenden Sicherheitslücken bei der Datenübertragung im Internet bekannt. </li>
      <li>Der Nutzer hat insbesondere die Pflicht, dafür Sorge zu tragen, dass keine Informationen mit rechts- oder sittenwidrigen Inhalten unter seinen Zugangsdaten übermittelt oder eingestellt werden. </li>
    </ol>
  </li>
  <li><span class="bdo-list__head">Beschränkungen und Sperrung des OnlineService</span>
    <ol>
      <li>Aus technischen und betrieblichen Gründen sind zeitweilige Einschränkungen des Zugangs zum OnlineService möglich. Während dieser zeitweisen Einschränkung des Zugangs ist die Nutzung des OnlineService nicht oder nur in Teilen möglich. Zeitweilige Einschränkungen können z.B. auf Grund von Wartungs- und Instandsetzungsarbeiten entstehen, die für einen einwandfreien Betrieb oder zur Optimierung des OnlineService erforderlich oder wünschenswert sind.</li>
      <li>Der Lieferant behält sich vor, einzelnen Nutzern die Verwendung spezifischer Funktionen oder die Verwendung des OnlineService in begründeten Fällen unbefristet zu verwehren oder ganz einzuschränken. </li>
      <li>Bei Verdacht auf Missbrauch wird der Lieferant den Zugang zum OnlineService sperren und den Nutzer darüber entsprechend informieren. Gleiches gilt, wenn der Nutzer bei Verdacht auf Missbrauch durch Dritte die Sperrung seines Zugangs verlangt. In diesem Fall ist eine Meldung an den Lieferanten erforderlich, wenn der Nutzer den OnlineService wieder nutzen möchte.</li>
    </ol>
  </li>
  <li><span class="bdo-list__head">Änderungen der Nutzungsbedingungen und des OnlineService</span>
    <ol>
      <li>Der Lieferant kann den Leistungsumfang des OnlineService sowie die Nutzungsbedingungen jederzeit verändern. Der Nutzer wird dann bei seiner nächsten Anmeldung zum OnlineService über die Änderungen informiert.</li>
      <li>Die Änderungen gelten als vom Nutzer genehmigt, wenn er die Nutzung des OnlineService nach Wirksamwerden der Änderungen fortsetzt. Ist der Nutzer mit den Änderungen nicht einverstanden, gilt Ziffer 9.1 entsprechend. </li>
      <li>Der Nutzer findet die jeweils gültigen Nutzungsbedingungen jederzeit im OnlineService und kann diese dort herunterladen.</li>
    </ol>
  </li>
  <li><span class="bdo-list__head">Löschung des Benutzerkontos</span>
    <ol>
      <li>Sofern die Nutzung des OnlineService nicht Voraussetzung und Bestandteil eines Liefervertrages ist, kann das Nutzungsverhältnis durch jede Seite jederzeit und mit sofortiger Wirkung ohne Angabe von Gründen durch Löschen des Benutzerkontos beendet werden. </li>
      <li>Nach Löschung des Benutzerkontos wird der Zugang zum OnlineService gesperrt. Die weitere Kommunikation inklusive des Versands der Dokumente erfolgt dann wieder in Papierform bzw. über einen anderen zwischen dem Kunden und dem Lieferanten vereinbarten Weg. Ein kostenfreier Zugriff auf alte Dokumente ist nicht mehr möglich.</li>
      <li>Die Löschung des Benutzerkontos oder die vorübergehende Sperrung des Zugangs zum OnlineService, sei es auf Antrag des Nutzers oder durch Entscheidung des Lieferanten, hat keinerlei Auswirkungen auf sonstige vertragliche Vereinbarungen und Verpflichtungen zwischen dem Nutzer und dem Lieferanten.</li>
    </ol>
  </li>
  <li><span class="bdo-list__head">Datenschutz</span>
    <ol>
      <li>Die im Rahmen der Registrierung eingegebenen Daten werden nur für die Zwecke der Nutzung des OnlineService verarbeitet. Die vom Nutzer über den OnlineService übermittelten Daten (wie bspw. Zählerstände) werden von dem Lieferanten für den Vertragsabschluss oder die Verwaltung der bestehenden Verträge verarbeitet. Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 lit. b Datenschutzgrundverordnung (DS-GVO). Personenbezogene Daten, die der Nutzer dem Lieferanten übermittelt und die sich nicht auf das Vertragsverhältnis beziehen, verarbeitet der Lieferant aus berechtigtem Interesse gem. Art. 6 Abs. 1 lit. f DS-GVO.</li>
      <li>Die Daten werden von dem Lieferanten stets vertraulich behandelt und nicht an Dritte weitergegeben, es sei denn der Nutzer hat dem zugestimmt, der Lieferant ist hierzu gesetzlich verpflichtet oder bedient sich zur Durchführung des OnlineService Dritter. </li>
      <li>Weitere Informationen zum Datenschutz und den Betroffenenrechten sind unter <a class="bdo-link--inline" href="${values.hrefDataPrivacy}">${values.hrefDataPrivacyDisplayName}</a> zu finden. Unseren Datenschutzbeauftragten erreichen Sie unter <a class="bdo-link--inline" href="mailto:${values.mailtoDataPrivacy}">${values.mailtoDataPrivacy}</a> oder postalisch unter ${values.addressDataPrivacy}.</li>
    </ol>
  </li>
  <li><span class="bdo-list__head">Haftung</span>
    <ol>
      <li>Der Lieferant haftet in Fällen der verschuldensabhängigen Haftung ausschließlich für Vorsatz und grobe Fahrlässigkeit. Die Haftungsbeschränkung gilt nicht bei der Verletzung vertragswesentlicher Pflichten (sog. Kardinalpflichten). Kardinalpflichten sind solche grundlegenden, vertragswesentlichen Pflichten, die maßgeblich für den Vertragsabschluss des Kunden waren und auf deren Einhaltung dieser vertrauen durfte. Im Falle der fahrlässigen, nicht aber grob fahrlässigen Verletzung von Kardinalpflichten ist die Haftung auf den Ersatz des vorhersehbaren, vertragstypischen Schadens beschränkt.</li>
      <li>Für Schäden aufgrund der Verletzung des Lebens, des Körpers oder der Gesundheit ist die Haftung unbeschränkt.</li>
      <li>Die vorstehenden Beschränkungen gelten auch für gesetzliche Vertreter, leitende Angestellte und Mitarbeiter des Lieferanten sowie deren Erfüllungsgehilfen. </li>
    </ol>
  </li>
  <li><span class="bdo-list__head">Gesetzliche Informationspflichten/Streitbeilegung</span>
    <p>Bei Fragen können Sie sich an unseren Kundenservice wenden: ${values.address}, Telefon: ${values.phonenumber}, E-Mail: <a class="bdo-link--inline" href="mailto:${values.mailto}">${values.mailto}</a> Verbraucher haben die Möglichkeit, über die Online-Streitbeilegungs-Plattform der Europäischen Kommission kostenlose Hilfestellung für die Einreichung einer Verbraucherbeschwerde zu einem Online-Kaufvertrag oder Online-Dienstleistungsvertrag sowie Informationen über die Verfahren an den Verbraucherschlichtungsstellen in der Europäischen Union zu erhalten. Diese Plattform finden Sie unter: <a class="bdo-link--inline" href="http://www.ec.europa.eu/consumers/odr/">www.ec.europa.eu/consumers/odr/</a></p>
  </li>
  <li><span class="bdo-list__head">Schlussbestimmungen</span>
    <ol>
      <li>Für diese Nutzungsbedingungen, deren Durchführung und die sich hieraus ergebenden Ansprüche gilt deutsches Recht. ${values.city ? `Gerichtsstand und Erfüllungsort ist ${values.city}, soweit der Kunde nicht Verbraucher gemäß § 13 BGB ist.` : ''}</li>
      <li>Sollte in diesen Nutzungsbedingungen eine Bestimmung aus tatsächlichen oder rechtlichen Gründen unwirksam oder undurchführbar sein oder werden, so wird die Wirksamkeit der übrigen Vertragsbestimmungen hierdurch nicht berührt. </li>
    </ol>
  </li>
</ol>`;


@Injectable({
  providedIn: 'root'
})
export class TenantService {

  getTenantDataByShort(short: string): TenantData {
    const data: [string, TenantData] = Object.entries(tenantData).find(([key, tenantDataEntry]) => tenantDataEntry.short === short);
    if (data?.length && data.length > 1) {
      return data[1];
    }
  }

  getCurrentTenantData(): TenantData {
    const short = TENANT_ACRONYM[Environment.tenant].toUpperCase();
    return this.getTenantDataByShort(short);
  }

  getTosTemplate(): string {
    const currentTenantData = this.getCurrentTenantData();
    return templateTos(currentTenantData);
  }
}
