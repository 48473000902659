/**
 * NLB-GW
 * Diese fantastische API stellt verschiedene Services fuer den neuen RheinEnergie Online Service zur Verfuegung.  Insbesondere sind hier Services zum Zugriff auf das SAP iCos implementiert. 
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Kurzuebersicht ueber aktuelle und naechste Forderungen
 */
export interface Forderung { 
    /**
     * Summe aller aktuell offenen Forderungen/Guthaben (Nicht bezahlte Abschlaege, Mahnkosten, Auszahlungen am Ende der Abrechnungsperiode). Positiver Betrag: Offene Forderung. Negativer Betrag: Guthaben
     */
    offeneSumme?: number;
    /**
     * Betrag der naechsten offenen Forderung
     */
    next?: number;
    /**
     * Datum, wann die naechste Forderung faellig ist
     */
    nextDatum?: Date;
    /**
     * Gibt an, ob zu der Kundennummer ein SEPA-Mandat vorliegt
     */
    sepa?: boolean;
}

