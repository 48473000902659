import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Poll, PollAnswer } from '../../../../assets/js/com/ts_api_client';
import { BdoApiService } from '../../../bdo/services/bdo-api.service';
import { CustomerStoreService } from '../../../bdo/services/customer-store.service';
import { Utilities } from '../../utils/utilities';

@Component({
  selector: 'bdo-customer-satisfaction-poll',
  templateUrl: './customer-satisfaction-poll.component.html',
  styleUrls: ['./customer-satisfaction-poll.component.scss']
})
export class CustomerSatisfactionPollComponent implements OnInit {
  @Input() pollId: number;
  @Input() triggerPoint: string = '';
  poll: Poll;
  activeQuestion = 0;
  maxQuestions: number;
  showPoll: boolean = false;

  constructor(
    private apiService: BdoApiService,
    private router: Router,
    private customerStore: CustomerStoreService
  ) { }

  ngOnInit(): void {
    if (Utilities.getCookie('poll_shown') === '1') {
      this.showPoll = false;
    } else {
      this.apiService.getPoll(this.pollId)
      .subscribe( { next: res => {
        this.poll = res;
        this.maxQuestions = this.poll.questions?.length;
        this.showPoll = true;
        Utilities.createCookie('poll_shown', '1', 1.5 / 24 );
      } });
    }
  }

  onQuestionComplete(answer: string) {
    const answerObject: PollAnswer = {
      pollId: this.poll.pollId,
      accountId: this.customerStore.getAccountId() || 'empty',
      path: this.router.url,
      questionNo: this.poll?.questions[this.activeQuestion].questionNo,
      triggerPoint: this.triggerPoint,
      answer: answer
    };
    this.apiService.postPollAnswer(answerObject).subscribe();

    if (this.activeQuestion === this.maxQuestions) {
      this.activeQuestion = -1;
    } else {
      this.activeQuestion ++;
    }
  }

}
