/**
 * NLB-GW
 * Diese fantastische API stellt verschiedene Services fuer den neuen RheinEnergie Online Service zur Verfuegung.  Insbesondere sind hier Services zum Zugriff auf das SAP iCos implementiert. 
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * data object for defer payment call
 */
export interface DeferPaymentData { 
    /**
     * identifies a payment item
     */
    id?: string;
    /**
     * accountId of existing contract, also knows as VK
     */
    accountId?: string;
    deferredTo?: Date;
}

