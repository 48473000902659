import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  Input
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackingService } from '../../bdo/services/tracking.service';
import { LoginService } from '../login.service';
import { Subscription } from 'rxjs';
import { RegistrationData } from '../../../assets/js/com/ts_api_client';
import { RESPONSE_STATUS } from '../../bdo/enums/responseStatus.enum';
import { REGISTER_ERROR } from '../../bdo/enums/registerError.enum';
import { TenantService } from '../../bdo/services/tenant.service';
import { ACTIVATION_STATE } from '../../bdo/enums/activationState.enum';
import { TRACKING } from '../../bdo/enums/trackingParts.enum';
import punycode from 'punycode/';
import { passwordPattern } from '../../shared/validators/password-validator';
import * as AccountIdValidator from '../../shared/validators/accountId-validator';
import { Location } from '@angular/common';
import { NavigationState } from '../../bdo/models/navigationState';
import { Utilities } from '../../shared/utils/utilities';
import { CompetitionHelperService } from '../../bdo/services/competition-helper.service';

@Component({
  selector: 'bdo-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() activationState = ACTIVATION_STATE.NONE;
  @Output() submitRegisterCheckIdentification: EventEmitter<any> = new EventEmitter();
  @ViewChild('registerForm', { static: true }) registerForm: NgForm;
  ActivationStateEnum = ACTIVATION_STATE;
  sendingData: boolean = false;
  accountId: string = '';
  meterNumberOrRegisterCode: string = '';
  mail: string = '';
  mailRepeat: string = '';
  usernameNew: string = '';
  passwordNew: string = '';
  passwordRepeat: string = '';
  tosAccepted: boolean = false; // Nutzungsbedingungen - Terms of Service
  tosTouched: boolean = false;
  public competitionChecked: boolean = false;

  showRegistrationDataForm: boolean = false;
  RegisterErrorEnum = REGISTER_ERROR;
  registerError: string = REGISTER_ERROR.NONE;
  submitted: boolean = false;
  validateAll: boolean = false;
  validationIconHidden: boolean = false;
  registeringDone = false;
  usernameAvailable: boolean = true;
  checkingUsername: boolean = false;

  valueChangesSubscription: Subscription;
  public isValidPassword: boolean;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    private trackingService: TrackingService,
    private location: Location,
    public tenantService: TenantService,
    public competitionHelperService: CompetitionHelperService
  ) {
    // If already added identificationData in other route use them here and skip step 1
    // Angular9/Typescript Update will make this pretty!
    try {
      const currentState: NavigationState = Utilities.getStateOfCurrentRoute(this.location);
      if (currentState.identificationData) {
        const identificationData = currentState.identificationData;
        this.accountId = identificationData.accountId;
        this.meterNumberOrRegisterCode = identificationData.meterNumberOrRegisterCode;
        this.showRegistrationDataForm = true;
      }
    } catch (error) {
      // no identificationData, so start with step 1, the identification form
    }
  }

  ngOnInit() {
    this.meterNumberOrRegisterCode = this.route.snapshot.queryParamMap.get('registercode');
  }

  ngAfterViewInit() {
    this.valueChangesSubscription = this.registerForm.valueChanges.subscribe({ next: (data) => {
      this.isValidPassword = passwordPattern.test(data?.passwordNew);
      if (this.submitted) {
        this.submitted = false;
        this.registerError = REGISTER_ERROR.NONE;
        this.validationIconHidden = false;
      }
    } });
  }

  ngOnDestroy() {
    this.valueChangesSubscription.unsubscribe();
  }

  onTosClicked() {
    this.tosAccepted = !this.tosAccepted;
    this.tosTouched = true;
  }

  trackIt() {
    this.trackingService.postTracking(TRACKING.LOCATION.REGISTER, TRACKING.ACTION.OPEN, TRACKING.LOCATION.TERMSOFSERVICE);
  }

  register(form: NgForm) {
    this.submitted = true;
    this.sendingData = true;
    // Step 1 : Validate User Identification
    if (!this.showRegistrationDataForm) {
      this.trackingService.postSimpleTracking(TRACKING.LOCATION.REGISTER, 'check Identification');
      if (form.valid) {
        this.validateIdentification(form.value.accountId, form.value.meterNumberOrRegisterCode);
        this.validateAll = false;
      } else {
        this.validateAll = true;
        this.sendingData = false;
      }
    } else {
      // Step 2
      this.trackingService.postSimpleTracking(TRACKING.LOCATION.REGISTER, 'Send');
      this.sendRegistration(form);
    }
  }

  // Checks, if Combination from accountId and meterNumber is available
  validateIdentification(accountId: string, meterNumberOrRegisterCode: string) {
    this.loginService.checkIdentification(accountId, meterNumberOrRegisterCode)
      .subscribe(
        {
          next: (res) => {
            this.sendingData = false;
            if (res.status === RESPONSE_STATUS.Error) {
              this.showRegisterError(res.info as REGISTER_ERROR);
              if (res.info === REGISTER_ERROR.WRONG_COMBINATION) {
                this.trackingService.postSimpleTracking(TRACKING.LOCATION.REGISTER, TRACKING.ACTION.ERROR + '.' + REGISTER_ERROR.WRONG_COMBINATION +
                  '.with' + (meterNumberOrRegisterCode.startsWith('$') ? 'Registercode' : 'Meternumber'));
              } else {
                this.trackingService.postSimpleTracking(TRACKING.LOCATION.REGISTER, TRACKING.ACTION.ERROR + '.' + res.info);
              }
            } else if (res.status === RESPONSE_STATUS.Success) {
              if (res.info !== '') {
                this.showRegisterError(REGISTER_ERROR.ACCOUNT_EXISTS);
                this.trackingService.postSimpleTracking(TRACKING.LOCATION.REGISTER, TRACKING.ACTION.ERROR + '.' + REGISTER_ERROR.ACCOUNT_EXISTS);
              } else {
                // Identification fine: open Part 2
                this.trackingService.postSimpleTracking(TRACKING.LOCATION.REGISTER, TRACKING.ACTION.SUCCESS + '.with' +
                  (meterNumberOrRegisterCode.startsWith('$') ? 'Registercode' : 'Meternumber'));
                this.showRegistrationDataForm = true;
              }
            }
          },
          error: () => {
            this.sendingData = false;
          }
        });
  }

  sendRegistration(form: NgForm) {
    if (form.valid && this.tosAccepted) {
      const registrationData: RegistrationData = {};
      registrationData.accountId = form.value.accountId;
      registrationData.meterNumberOrRegisterCode = form.value.meterNumberOrRegisterCode;
      registrationData.mail = punycode.toUnicode(form.value.mail);
      registrationData.username = form.value.usernameNew;
      registrationData.password = form.value.passwordNew;
      if (this.competitionHelperService?.isActive()){
        registrationData.competitionId = this.competitionHelperService.getActiveCompetitionId();
      }
      this.loginService.register(registrationData)
        .subscribe(
          {
            next: (res) => {
              this.sendingData = false;
              if (res.status === RESPONSE_STATUS.Error) {
                this.showRegisterError(res.info as REGISTER_ERROR);
              } else {
                // Show Success Popup
                this.registeringDone = true;
              }
            },
            error: () => {
              this.sendingData = false;
              this.showRegisterError(REGISTER_ERROR.UNKNOWN_ERROR);
            }
          });
    } else {
      this.validateAll = true;
      this.sendingData = false;
      this.tosTouched = true;
    }
  }

  showRegisterError(registerError: REGISTER_ERROR) {
    this.registerError = registerError;
    this.validationIconHidden = true;
  }

  hideError() {
    // this.hideErrorEvent.emit();
  }

  onFocus() {
    this.registerError = REGISTER_ERROR.NONE;
    this.activationState = ACTIVATION_STATE.NONE;
    this.validationIconHidden = false;
    this.submitted = false;
  }

  closeMessage() {
    this.registeringDone = false;
    this.router.navigate(['/login']);
  }

  checkUsername(value: string): boolean {
    if (!value) { return false; }
    return value.match(/[^a-zA-Z0-9\._@-]/) !== null;
  }

  containsOnlyDigits(value: string): boolean {
    if (!value) { return true; }
    return /^\d+$/.test(value);
  }

  /** AccountIds may start with up to 3 optional Zeroes, a 2 and may not be longer than 12 digits */
  isValidAccountId(value: string): boolean {
    return AccountIdValidator.isValidAccountId(value);
  }

  checkUsernameAvailable(username: string) {
    this.checkingUsername = true;
    if (username.length > 0) {
      this.loginService.checkUsernameAvailable(username)
        .subscribe(
          {
            next: (res) => {
              this.usernameAvailable = res.available;
              this.checkingUsername = false;
            },
            error: () => {
              // If Call fails, we better set the name to unavailable to have the user try again and make another call
              this.usernameAvailable = false;
              this.checkingUsername = false;
            }
          });
    }
  }

  onCompetitionChanged() {
    this.competitionChecked = !this.competitionChecked;
  }

}
