/**
 * NLB-GW
 * Diese fantastische API stellt verschiedene Services fuer den neuen RheinEnergie Online Service zur Verfuegung.  Insbesondere sind hier Services zum Zugriff auf das SAP iCos implementiert. 
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { Contract } from '../model/contract';
// @ts-ignore
import { ContractAddResponse } from '../model/contractAddResponse';
// @ts-ignore
import { ContractCancelData } from '../model/contractCancelData';
// @ts-ignore
import { ContractChangeResponse } from '../model/contractChangeResponse';
// @ts-ignore
import { ContractChangeTariff } from '../model/contractChangeTariff';
// @ts-ignore
import { ContractConsumptionSummary } from '../model/contractConsumptionSummary';
// @ts-ignore
import { ContractInformationSummary } from '../model/contractInformationSummary';
// @ts-ignore
import { ContractMoveData } from '../model/contractMoveData';
// @ts-ignore
import { ContractStartResponse } from '../model/contractStartResponse';
// @ts-ignore
import { StatusResponse } from '../model/statusResponse';
// @ts-ignore
import { TariffChangeRequest } from '../model/tariffChangeRequest';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ContractService {

    protected basePath = 'http://localhost:8080/BdoSpiders/orca';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * returns a list of tariff infos the customer can choose as change target
     * @param xTenant 
     * @param accountId 
     * @param meterNumber Valid characters are: \&#39;A-Z\&#39;,\&#39;0-9\&#39; and \&#39;-./_&#x60;Ä\&#39;
     * @param contractId 
     * @param division 
     * @param consumption 
     * @param customerMode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAnonymousContractChangeTariffOptionsInformation(xTenant: string, accountId: string, meterNumber: string, contractId: string, division?: string, consumption?: number, customerMode?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<Array<ContractChangeTariff>>;
    public getAnonymousContractChangeTariffOptionsInformation(xTenant: string, accountId: string, meterNumber: string, contractId: string, division?: string, consumption?: number, customerMode?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<ContractChangeTariff>>>;
    public getAnonymousContractChangeTariffOptionsInformation(xTenant: string, accountId: string, meterNumber: string, contractId: string, division?: string, consumption?: number, customerMode?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<ContractChangeTariff>>>;
    public getAnonymousContractChangeTariffOptionsInformation(xTenant: string, accountId: string, meterNumber: string, contractId: string, division?: string, consumption?: number, customerMode?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getAnonymousContractChangeTariffOptionsInformation.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getAnonymousContractChangeTariffOptionsInformation.');
        }
        if (meterNumber === null || meterNumber === undefined) {
            throw new Error('Required parameter meterNumber was null or undefined when calling getAnonymousContractChangeTariffOptionsInformation.');
        }
        if (contractId === null || contractId === undefined) {
            throw new Error('Required parameter contractId was null or undefined when calling getAnonymousContractChangeTariffOptionsInformation.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (division !== undefined && division !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>division, 'division');
        }
        if (consumption !== undefined && consumption !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>consumption, 'consumption');
        }
        if (customerMode !== undefined && customerMode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerMode, 'customerMode');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/contract/change/tariffOptions/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "meterNumber", value: meterNumber, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "contractId", value: contractId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<Array<ContractChangeTariff>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns contract consumption information for accountId
     * returns contract consumption information for accountId
     * @param xTenant 
     * @param accountId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAnonymousContractConsumptionInfo(xTenant: string, accountId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<ContractConsumptionSummary>;
    public getAnonymousContractConsumptionInfo(xTenant: string, accountId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ContractConsumptionSummary>>;
    public getAnonymousContractConsumptionInfo(xTenant: string, accountId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ContractConsumptionSummary>>;
    public getAnonymousContractConsumptionInfo(xTenant: string, accountId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getAnonymousContractConsumptionInfo.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getAnonymousContractConsumptionInfo.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/contract/consumption/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<ContractConsumptionSummary>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns anonymous contract information for accountId
     * returns anonymous contract information for accountId
     * @param xTenant 
     * @param accountId 
     * @param meterNumber Valid characters are: \&#39;A-Z\&#39;,\&#39;0-9\&#39; and \&#39;-./_&#x60;Ä\&#39;
     * @param annualConsumption estimated annual consumption of units for customer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAnonymousContractInformation(xTenant: string, accountId: string, meterNumber: string, annualConsumption?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<ContractInformationSummary>;
    public getAnonymousContractInformation(xTenant: string, accountId: string, meterNumber: string, annualConsumption?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ContractInformationSummary>>;
    public getAnonymousContractInformation(xTenant: string, accountId: string, meterNumber: string, annualConsumption?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ContractInformationSummary>>;
    public getAnonymousContractInformation(xTenant: string, accountId: string, meterNumber: string, annualConsumption?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getAnonymousContractInformation.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getAnonymousContractInformation.');
        }
        if (meterNumber === null || meterNumber === undefined) {
            throw new Error('Required parameter meterNumber was null or undefined when calling getAnonymousContractInformation.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (annualConsumption !== undefined && annualConsumption !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>annualConsumption, 'annualConsumption');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/contract/info/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "meterNumber", value: meterNumber, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<ContractInformationSummary>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns a list of tariff offers
     * @param xTenant 
     * @param offerId 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAnonymousContractOffer(xTenant: string, offerId: string, token: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<Contract>;
    public getAnonymousContractOffer(xTenant: string, offerId: string, token: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Contract>>;
    public getAnonymousContractOffer(xTenant: string, offerId: string, token: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Contract>>;
    public getAnonymousContractOffer(xTenant: string, offerId: string, token: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getAnonymousContractOffer.');
        }
        if (offerId === null || offerId === undefined) {
            throw new Error('Required parameter offerId was null or undefined when calling getAnonymousContractOffer.');
        }
        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling getAnonymousContractOffer.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (offerId !== undefined && offerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offerId, 'offerId');
        }
        if (token !== undefined && token !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>token, 'token');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/offer`;
        return this.httpClient.request<Contract>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns a list of tariff infos the customer can choose as change target
     * @param xTenant 
     * @param authorization 
     * @param accountId 
     * @param contractId 
     * @param division 
     * @param consumption 
     * @param customerMode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContractChangeTariffOptionsInformation(xTenant: string, authorization: string, accountId: string, contractId: string, division?: string, consumption?: number, customerMode?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<Array<ContractChangeTariff>>;
    public getContractChangeTariffOptionsInformation(xTenant: string, authorization: string, accountId: string, contractId: string, division?: string, consumption?: number, customerMode?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<ContractChangeTariff>>>;
    public getContractChangeTariffOptionsInformation(xTenant: string, authorization: string, accountId: string, contractId: string, division?: string, consumption?: number, customerMode?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<ContractChangeTariff>>>;
    public getContractChangeTariffOptionsInformation(xTenant: string, authorization: string, accountId: string, contractId: string, division?: string, consumption?: number, customerMode?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getContractChangeTariffOptionsInformation.');
        }
        if (authorization === null || authorization === undefined) {
            throw new Error('Required parameter authorization was null or undefined when calling getContractChangeTariffOptionsInformation.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getContractChangeTariffOptionsInformation.');
        }
        if (contractId === null || contractId === undefined) {
            throw new Error('Required parameter contractId was null or undefined when calling getContractChangeTariffOptionsInformation.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (division !== undefined && division !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>division, 'division');
        }
        if (consumption !== undefined && consumption !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>consumption, 'consumption');
        }
        if (customerMode !== undefined && customerMode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerMode, 'customerMode');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }
        if (authorization !== undefined && authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/contract/change/tariffOptions/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "contractId", value: contractId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<Array<ContractChangeTariff>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns contract consumption information for accountId
     * returns contract consumption information for accountId
     * @param xTenant 
     * @param authorization 
     * @param accountId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContractConsumptionInformation(xTenant: string, authorization: string, accountId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<ContractConsumptionSummary>;
    public getContractConsumptionInformation(xTenant: string, authorization: string, accountId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ContractConsumptionSummary>>;
    public getContractConsumptionInformation(xTenant: string, authorization: string, accountId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ContractConsumptionSummary>>;
    public getContractConsumptionInformation(xTenant: string, authorization: string, accountId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getContractConsumptionInformation.');
        }
        if (authorization === null || authorization === undefined) {
            throw new Error('Required parameter authorization was null or undefined when calling getContractConsumptionInformation.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getContractConsumptionInformation.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }
        if (authorization !== undefined && authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/contract/consumption/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<ContractConsumptionSummary>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns contract information for accountId
     * returns contract information for accountId
     * @param xTenant 
     * @param authorization 
     * @param accountId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContractInformation(xTenant: string, authorization: string, accountId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<ContractInformationSummary>;
    public getContractInformation(xTenant: string, authorization: string, accountId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ContractInformationSummary>>;
    public getContractInformation(xTenant: string, authorization: string, accountId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ContractInformationSummary>>;
    public getContractInformation(xTenant: string, authorization: string, accountId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getContractInformation.');
        }
        if (authorization === null || authorization === undefined) {
            throw new Error('Required parameter authorization was null or undefined when calling getContractInformation.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getContractInformation.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }
        if (authorization !== undefined && authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/contract/info/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<ContractInformationSummary>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns a productId based on the given accountId, frameContractId and optionId
     * @param xTenant 
     * @param accountId 
     * @param frameContractId 
     * @param optionIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductIdForFrameContract(xTenant: string, accountId: string, frameContractId: string, optionIds?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<StatusResponse>;
    public getProductIdForFrameContract(xTenant: string, accountId: string, frameContractId: string, optionIds?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<StatusResponse>>;
    public getProductIdForFrameContract(xTenant: string, accountId: string, frameContractId: string, optionIds?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<StatusResponse>>;
    public getProductIdForFrameContract(xTenant: string, accountId: string, frameContractId: string, optionIds?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getProductIdForFrameContract.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getProductIdForFrameContract.');
        }
        if (frameContractId === null || frameContractId === undefined) {
            throw new Error('Required parameter frameContractId was null or undefined when calling getProductIdForFrameContract.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (frameContractId !== undefined && frameContractId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>frameContractId, 'frameContractId');
        }
        if (optionIds !== undefined && optionIds !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>optionIds, 'optionIds');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/contract/change/productId/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<StatusResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * writes cancel contract to SAP system
     * writes cancel contract to SAP system
     * @param xTenant 
     * @param contractCancelData CancelContractForm
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAnonymousContractCancel(xTenant: string, contractCancelData: ContractCancelData, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<StatusResponse>;
    public postAnonymousContractCancel(xTenant: string, contractCancelData: ContractCancelData, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<StatusResponse>>;
    public postAnonymousContractCancel(xTenant: string, contractCancelData: ContractCancelData, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<StatusResponse>>;
    public postAnonymousContractCancel(xTenant: string, contractCancelData: ContractCancelData, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling postAnonymousContractCancel.');
        }
        if (contractCancelData === null || contractCancelData === undefined) {
            throw new Error('Required parameter contractCancelData was null or undefined when calling postAnonymousContractCancel.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/contract/cancel`;
        return this.httpClient.request<StatusResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: contractCancelData,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * anonymous move contract within SAP system
     * anonymous move contract within SAP system
     * @param xTenant 
     * @param contractMoveData SendContractMoveForm
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAnonymousMoveContract(xTenant: string, contractMoveData: ContractMoveData, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<ContractMoveData>;
    public postAnonymousMoveContract(xTenant: string, contractMoveData: ContractMoveData, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ContractMoveData>>;
    public postAnonymousMoveContract(xTenant: string, contractMoveData: ContractMoveData, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ContractMoveData>>;
    public postAnonymousMoveContract(xTenant: string, contractMoveData: ContractMoveData, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling postAnonymousMoveContract.');
        }
        if (contractMoveData === null || contractMoveData === undefined) {
            throw new Error('Required parameter contractMoveData was null or undefined when calling postAnonymousMoveContract.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/contract/move`;
        return this.httpClient.request<ContractMoveData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: contractMoveData,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * changes contracts within sap system for customer
     * changes contracts within sap system for customer
     * @param xTenant 
     * @param authorization 
     * @param tariffChangeRequest changes contract for accountId and given productId(s) an 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postChangeContract(xTenant: string, authorization: string, tariffChangeRequest: TariffChangeRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<ContractChangeResponse>;
    public postChangeContract(xTenant: string, authorization: string, tariffChangeRequest: TariffChangeRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ContractChangeResponse>>;
    public postChangeContract(xTenant: string, authorization: string, tariffChangeRequest: TariffChangeRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ContractChangeResponse>>;
    public postChangeContract(xTenant: string, authorization: string, tariffChangeRequest: TariffChangeRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling postChangeContract.');
        }
        if (authorization === null || authorization === undefined) {
            throw new Error('Required parameter authorization was null or undefined when calling postChangeContract.');
        }
        if (tariffChangeRequest === null || tariffChangeRequest === undefined) {
            throw new Error('Required parameter tariffChangeRequest was null or undefined when calling postChangeContract.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }
        if (authorization !== undefined && authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/contract/change`;
        return this.httpClient.request<ContractChangeResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: tariffChangeRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * changes contracts within sap system for customer
     * changes contracts within sap system for customer
     * @param xTenant 
     * @param tariffChangeRequest changes contract for accountId and given productId(s) an 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postChangeContractAnonymous(xTenant: string, tariffChangeRequest: TariffChangeRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<ContractChangeResponse>;
    public postChangeContractAnonymous(xTenant: string, tariffChangeRequest: TariffChangeRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ContractChangeResponse>>;
    public postChangeContractAnonymous(xTenant: string, tariffChangeRequest: TariffChangeRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ContractChangeResponse>>;
    public postChangeContractAnonymous(xTenant: string, tariffChangeRequest: TariffChangeRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling postChangeContractAnonymous.');
        }
        if (tariffChangeRequest === null || tariffChangeRequest === undefined) {
            throw new Error('Required parameter tariffChangeRequest was null or undefined when calling postChangeContractAnonymous.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/contract/change`;
        return this.httpClient.request<ContractChangeResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: tariffChangeRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * writes additional contract for existing customer to SAP system
     * writes additional contract for existing customer to SAP system
     * @param xTenant 
     * @param authorization 
     * @param contract SendContractForm
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postContractAdd(xTenant: string, authorization: string, contract: Contract, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<ContractAddResponse>;
    public postContractAdd(xTenant: string, authorization: string, contract: Contract, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ContractAddResponse>>;
    public postContractAdd(xTenant: string, authorization: string, contract: Contract, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ContractAddResponse>>;
    public postContractAdd(xTenant: string, authorization: string, contract: Contract, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling postContractAdd.');
        }
        if (authorization === null || authorization === undefined) {
            throw new Error('Required parameter authorization was null or undefined when calling postContractAdd.');
        }
        if (contract === null || contract === undefined) {
            throw new Error('Required parameter contract was null or undefined when calling postContractAdd.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }
        if (authorization !== undefined && authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/contract/add`;
        return this.httpClient.request<ContractAddResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: contract,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ends existing contract within SAP system
     * ends existing contract within SAP system
     * @param xTenant 
     * @param authorization 
     * @param contract SendContractForm
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postContractEnd(xTenant: string, authorization: string, contract: Contract, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<Contract>;
    public postContractEnd(xTenant: string, authorization: string, contract: Contract, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Contract>>;
    public postContractEnd(xTenant: string, authorization: string, contract: Contract, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Contract>>;
    public postContractEnd(xTenant: string, authorization: string, contract: Contract, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling postContractEnd.');
        }
        if (authorization === null || authorization === undefined) {
            throw new Error('Required parameter authorization was null or undefined when calling postContractEnd.');
        }
        if (contract === null || contract === undefined) {
            throw new Error('Required parameter contract was null or undefined when calling postContractEnd.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }
        if (authorization !== undefined && authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/contract/end`;
        return this.httpClient.request<Contract>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: contract,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * writes new contract to SAP system
     * writes new contract to SAP system
     * @param xTenant 
     * @param contract SendContractForm
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postContractStart(xTenant: string, contract: Contract, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<ContractStartResponse>;
    public postContractStart(xTenant: string, contract: Contract, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ContractStartResponse>>;
    public postContractStart(xTenant: string, contract: Contract, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ContractStartResponse>>;
    public postContractStart(xTenant: string, contract: Contract, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling postContractStart.');
        }
        if (contract === null || contract === undefined) {
            throw new Error('Required parameter contract was null or undefined when calling postContractStart.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/contract/start`;
        return this.httpClient.request<ContractStartResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: contract,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * move contract within SAP system
     * move contract within SAP system
     * @param xTenant 
     * @param authorization 
     * @param contractMoveData SendContractMoveForm
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postMoveContract(xTenant: string, authorization: string, contractMoveData: ContractMoveData, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<ContractMoveData>;
    public postMoveContract(xTenant: string, authorization: string, contractMoveData: ContractMoveData, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ContractMoveData>>;
    public postMoveContract(xTenant: string, authorization: string, contractMoveData: ContractMoveData, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ContractMoveData>>;
    public postMoveContract(xTenant: string, authorization: string, contractMoveData: ContractMoveData, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling postMoveContract.');
        }
        if (authorization === null || authorization === undefined) {
            throw new Error('Required parameter authorization was null or undefined when calling postMoveContract.');
        }
        if (contractMoveData === null || contractMoveData === undefined) {
            throw new Error('Required parameter contractMoveData was null or undefined when calling postMoveContract.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }
        if (authorization !== undefined && authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/contract/move`;
        return this.httpClient.request<ContractMoveData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: contractMoveData,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
