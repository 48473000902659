import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { TenantService } from '../../../services/tenant.service';
import { WizardStep } from '../../../models/wizardStep';
import { StorageService } from '../../../services/storage.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'bdo-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnDestroy {

  public activeStep: number = 1;
  public wizardSteps: Array<WizardStep>;
  public subscriptions = new Subscription();

  constructor(
    public tenantService: TenantService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    if (activatedRoute?.data['_value']['wizardSteps']){
      this.wizardSteps = this.filterOfferWizardSteps(activatedRoute?.data['_value']['wizardSteps']);
      this.subscriptions.add(
        this.router.events
          .pipe(
            filter((event) => (event instanceof NavigationEnd)),
            map(() => this.activatedRoute.snapshot.firstChild.url[0].path),
            map((path) => this.wizardSteps.findIndex((step) => path === step.path)) // find wizard step based on current path
          )
          .subscribe({ next: indexOfCurrentStep => this.activeStep = indexOfCurrentStep + 1 }) //activeSteps start at 1
      );
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  private filterOfferWizardSteps(wizardSteps: WizardStep[]): WizardStep[] {
    return wizardSteps?.filter((step) => !step.optional || step.optional && !!StorageService.getOfferContext());
  }


}
