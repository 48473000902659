/**
 * NLB-GW
 * Diese fantastische API stellt verschiedene Services fuer den neuen RheinEnergie Online Service zur Verfuegung.  Insbesondere sind hier Services zum Zugriff auf das SAP iCos implementiert. 
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Object containing private address data
 */
export interface PrivateAddressData { 
    /**
     * id reflecting title attribute like \'Herr\', \'Frau\', \'keine Anrede\', \'Firma\' etc.
     */
    titleId?: string;
    /**
     * firstname of person
     */
    firstname?: string;
    /**
     * lastname of person
     */
    surname?: string;
    /**
     * Zip Code / PLZ
     */
    postCode?: string;
    cityName?: string;
    streetName?: string;
    houseNum?: string;
}

