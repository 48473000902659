let defaultConfig = require('../tailwind.default.config.js');

defaultConfig.theme.extend.colors = {
  'primary': '#e30613',
  'primary-hover': '#383838',
  'secondary': '#383838',
  'secondary-hover': '#e30613',
  'tertiary': '#6C6C6C',
  'tertiary-hover': '#e30613',
  'chart-bar-1': '#005F87',
  'chart-bar-1-hover': '#004c6c',
  'chart-bar-2': '#007EB3',
  'chart-bar-2-hover': '#00648f',
  'chart-bar-3': '#039EE0',
  'chart-bar-3-hover': '#027eb3',
  'chart-bar-4': '#4EBBE9',
  'chart-bar-4-hover': '#1ba3dd',
  'chart-bar-5': '#9AD8F3',
  'chart-bar-5-hover': '#53bcea',
};

module.exports = defaultConfig;

