/**
 * NLB-GW
 * Diese fantastische API stellt verschiedene Services fuer den neuen RheinEnergie Online Service zur Verfuegung.  Insbesondere sind hier Services zum Zugriff auf das SAP iCos implementiert. 
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Daten, welche für die Online-Registrierung benötigt werden
 */
export interface RegistrationData { 
    /**
     * Gewählter Benutzername
     */
    username?: string;
    /**
     * Gewählte E-Mail Adresse
     */
    mail?: string;
    /**
     * Gewähltes Passwort
     */
    password?: string;
    /**
     * id for identifying a competition uniquely
     */
    competitionId?: string;
    /**
     * accountId of existing contract, also knows as VK
     */
    accountId?: string;
    /**
     * Zählernummer ODER Registrierungscode eines bestehenden Vertrags (!) für den ein Online-Zugang erstellt wird
     */
    meterNumberOrRegisterCode?: string;
}

