/**
 * NLB-GW
 * Diese fantastische API stellt verschiedene Services fuer den neuen RheinEnergie Online Service zur Verfuegung.  Insbesondere sind hier Services zum Zugriff auf das SAP iCos implementiert. 
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Sends contact form and store it as Item in SAP
 */
export interface ContactMessage { 
    /**
     * accountId of existing contract, also knows as VK
     */
    accountId?: string;
    /**
     * Mail subject
     */
    subject?: string;
    /**
     * Topic subject
     */
    topic?: string;
    /**
     * Message subject
     */
    message?: string;
    /**
     * TitleId subject
     */
    titleId?: string;
    /**
     * NameOrg subject
     */
    nameOrg?: string;
}

