// DO NOT TOUCH
// copy of website https://git.rheinenergie.com/rheinenergie-com-relaunch/revue/blob/master/tailwind.config.js 2020-01-07

module.exports = {
  content: [
    './src/**/*.{html,ts}',
  ],
  safelist: [
    {
      pattern: /.*re-bg.*/,
    },
    {
      pattern: /.*re-w-.\/.*/
    }
  ],
  prefix: 're-',
  future: {
    hoverOnlyWhenSupported: true,
  },
  theme: {
    stroke: {
      none: 'none',
      current: 'currentColor'
    },
    spacing: {
      auto: 'auto',
      default: '15px',
      '-5': '-5px',
      '-30': '-30px',
      '-60': '-60px',
      '0': '0',
      '1': '1px',
      '2': '2px',
      '3': '3px',
      '5': '5px',
      '7': '7px',
      '10': '10px',
      '15': '15px',
      '20': '20px',
      '22': '22px',
      '24': '24px',
      '25': '25px',
      '27': '27px', // svg
      '30': '30px',
      '35': '35px',
      '36': '36px',
      '40': '40px',
      '45': '45px',
      '50': '50px',
      '55': '55px', // tariff-tile badgeless
      '60': '60px',
      '65': '65px',
      '75': '75px',
      '80': '80px',
      '90': '90px',
      '100': '100px',
      '105': '105px',
      '120': '120px',
      '150': '150px',
      '175': '175px', // datepicker,
      '230': '230px', // autosuggest
      '300': '300px',
      '350': '350px',
      '440': '440px',
      '580': '580px',
      '600': '600px',
      '660': '660px',
      '900': '900px',
      '1380': '1380px' // 2xl - 2 * 30 margin
    },

    colors: {
      'monochrome-dark': '#383838', // Mine Shaft
      'mineshaft': '#383838', // wanted duplicate: naming from website
      'monochrome-medium': '#6c6c6c', // Dove Grey
      'dove-gray': '#6c6c6c', // Dove Grey
      'brand-primary': '#6c6c6c', // Dove Grey
      'monochrome-medium-light': '#cccdcd', // Athens Grey
      'athens-grey': '#cccdcd', // wanted duplicate: naming from website
      'monochrome-light': '#eee', // White smoke
      'white-smoke': '#eee', // wanted duplicate: naming from website

      // Tertiary Colors
      'green': '#148700',
      'orange': '#f06f00',
      'blue': '#039ee0',

      'white': '#fff',
      'error': '#e30613',
      'alert': '#f06f00', // orange
      'success': '#148700', // green
      'info': '#CDECF9', // lightblue

      'transparent': 'transparent'
    },

    container: {
      center: true,
    },

    screens: {
      sm: '420px',
      md: '760px',
      lg: '980px',
      xl: '1200px',
      '2xl': '1440px',
    },

    extend: {
      borderWidth: {
        '2': '2px',
        '3': '3px',
        '5': '5px'
      },
      gridColumn: {
        'auto-1': 'auto / span 1',
        'auto-2': 'auto / span 2',
        'auto-3': 'auto / span 3',
        'auto-4': 'auto / span 4',
        'auto-6': 'auto / span 6',
        'auto-12': 'auto / span 12',
      },
      gridAutoRows: {
        '55': '55px',
        '100': '100px'
      },
      lineHeight: {
        xs: '16px', // orca-specific
        s: '24px',
        m: '27px',
        l: '29px',
        xl: '35px',
        '2xl': '36px',
        '3xl': '38px',
        '4xl': '44px',
        '5xl': '58px',
        '6xl': '90px',
        '50': '50px'
      },
      fontFamily: {
        lighten: 'Suisse Light', // manually edited, orca-specific
        regular: 'Suisse Medium', // manually edited, orca-specific
      },
      fontSize: {
        xxs: '14px', // manually added, orca-specific
        xs: '16px',
        sm: '18px',
        base: '24px',
        m: '26px',
        l: '32px',
        xl: '36px',
        '0': '0',
        '2xl': '40px',
        '3xl': '56px',
        '4xl': '62px',
        '5xl': '88px',
      },

      // manually removed colors, orca-specific
      height: {
        '3': '3px',
        '200': '200px',
        '250': '250px',
        '280': '280px',
        '300': '300px'
      },
      width: {
        '40': '40px'
      },
      minHeight: {
        '45': '45px',
        '250': '250px', // customer-tile
        '350': '350px',

      },
      maxHeight: {
        '0': '0',
        '50': '50px'
      },
      minWidth: {
        '0': '0',
        '1/4': '25%',
        '1/2': '50%',
        '3/4': '75%',
        '75': '75px',  // manually edited, orca-specific
        '120': '120px',
        '155': '155px',
        'mobile': '320px',
        '500': '500px',  // manually edited, orca-specific
        'full': '100%',
      },
      maxWidth: {
        '230': '230px',
        '290': '290px',
        '440': '440px',
        '700': '700px'
      },
      zIndex: {
        'minus': '-1',
        '3000': '3000', // slide-over
      },
      transitionTimingFunction: {
        default: 'cubic-bezier(0.5, 0.02, 0.5, 1)',
        'easing-cubic': 'cubic-bezier(0.5, 0.02, 0.5, 1)',
      },
      transitionDuration: {
        default: '0.25s',
        '0.25s': '0.25s'
      },
    },
  },
  transitionProperty: ['hover', 'focus']
};
