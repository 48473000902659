/**
 * NLB-GW
 * Diese fantastische API stellt verschiedene Services fuer den neuen RheinEnergie Online Service zur Verfuegung.  Insbesondere sind hier Services zum Zugriff auf das SAP iCos implementiert. 
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AnonymousMeterreading } from '../model/anonymousMeterreading';
// @ts-ignore
import { CheckIdentificationData } from '../model/checkIdentificationData';
// @ts-ignore
import { StatusResponse } from '../model/statusResponse';
// @ts-ignore
import { ZaehlerIdentifier } from '../model/zaehlerIdentifier';
// @ts-ignore
import { Zaehlerhistorie } from '../model/zaehlerhistorie';
// @ts-ignore
import { ZaehlerhistorieEuro } from '../model/zaehlerhistorieEuro';
// @ts-ignore
import { Zaehlerstand } from '../model/zaehlerstand';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ZaehlerstandService {

    protected basePath = 'http://localhost:8080/BdoSpiders/orca';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Loads meterreading anonymously
     * E2E test in LoadAndChangeZaehlerstandAnonymousPRIVTest
     * @param xTenant 
     * @param accountId 
     * @param meterNumber Valid characters are: \&#39;A-Z\&#39;,\&#39;0-9\&#39; and \&#39;-./_&#x60;Ä\&#39;
     * @param atDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMeterreadingAnonymously(xTenant: string, accountId: string, meterNumber: string, atDate: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<Zaehlerstand>;
    public getMeterreadingAnonymously(xTenant: string, accountId: string, meterNumber: string, atDate: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Zaehlerstand>>;
    public getMeterreadingAnonymously(xTenant: string, accountId: string, meterNumber: string, atDate: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Zaehlerstand>>;
    public getMeterreadingAnonymously(xTenant: string, accountId: string, meterNumber: string, atDate: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getMeterreadingAnonymously.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getMeterreadingAnonymously.');
        }
        if (meterNumber === null || meterNumber === undefined) {
            throw new Error('Required parameter meterNumber was null or undefined when calling getMeterreadingAnonymously.');
        }
        if (atDate === null || atDate === undefined) {
            throw new Error('Required parameter atDate was null or undefined when calling getMeterreadingAnonymously.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (atDate !== undefined && atDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>atDate, 'atDate');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/meterreading/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "meterNumber", value: meterNumber, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<Zaehlerstand>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Loads all meter information for given accountId
     * Loads all meter information for given accountId
     * @param xTenant 
     * @param accountId 
     * @param meterNumber Valid characters are: \&#39;A-Z\&#39;,\&#39;0-9\&#39; and \&#39;-./_&#x60;Ä\&#39;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMetersAnonymously(xTenant: string, accountId: string, meterNumber: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<Array<ZaehlerIdentifier>>;
    public getMetersAnonymously(xTenant: string, accountId: string, meterNumber: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<ZaehlerIdentifier>>>;
    public getMetersAnonymously(xTenant: string, accountId: string, meterNumber: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<ZaehlerIdentifier>>>;
    public getMetersAnonymously(xTenant: string, accountId: string, meterNumber: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getMetersAnonymously.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getMetersAnonymously.');
        }
        if (meterNumber === null || meterNumber === undefined) {
            throw new Error('Required parameter meterNumber was null or undefined when calling getMetersAnonymously.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/meters/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "meterNumber", value: meterNumber, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<Array<ZaehlerIdentifier>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt ein Zaehlerstandobjekt mit Werten zu einem bestimmten Datum zurueck um ein Formular zu generieren
     * 
     * @param xTenant 
     * @param authorization 
     * @param accountId 
     * @param meterNumber Valid characters are: \&#39;A-Z\&#39;,\&#39;0-9\&#39; and \&#39;-./_&#x60;Ä\&#39;
     * @param datum 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getZaehler(xTenant: string, authorization: string, accountId: string, meterNumber: string, datum?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<Zaehlerstand>;
    public getZaehler(xTenant: string, authorization: string, accountId: string, meterNumber: string, datum?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Zaehlerstand>>;
    public getZaehler(xTenant: string, authorization: string, accountId: string, meterNumber: string, datum?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Zaehlerstand>>;
    public getZaehler(xTenant: string, authorization: string, accountId: string, meterNumber: string, datum?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getZaehler.');
        }
        if (authorization === null || authorization === undefined) {
            throw new Error('Required parameter authorization was null or undefined when calling getZaehler.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getZaehler.');
        }
        if (meterNumber === null || meterNumber === undefined) {
            throw new Error('Required parameter meterNumber was null or undefined when calling getZaehler.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (datum !== undefined && datum !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>datum, 'datum');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }
        if (authorization !== undefined && authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/zaehlerstand/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "meterNumber", value: meterNumber, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<Zaehlerstand>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt die letzten x Zaehlerstaende zurueck
     * E2E test in LoadAndChangeZaehlerstandLoginPRIVTest and LoadAndChangeZaehlerstandLoginGEWETest
     * @param xTenant 
     * @param authorization 
     * @param accountId 
     * @param meterNumber Valid characters are: \&#39;A-Z\&#39;,\&#39;0-9\&#39; and \&#39;-./_&#x60;Ä\&#39;
     * @param equipment 
     * @param anzahl 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getZaehlerHistorie(xTenant: string, authorization: string, accountId: string, meterNumber: string, equipment: string, anzahl?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<Zaehlerhistorie>;
    public getZaehlerHistorie(xTenant: string, authorization: string, accountId: string, meterNumber: string, equipment: string, anzahl?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Zaehlerhistorie>>;
    public getZaehlerHistorie(xTenant: string, authorization: string, accountId: string, meterNumber: string, equipment: string, anzahl?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Zaehlerhistorie>>;
    public getZaehlerHistorie(xTenant: string, authorization: string, accountId: string, meterNumber: string, equipment: string, anzahl?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getZaehlerHistorie.');
        }
        if (authorization === null || authorization === undefined) {
            throw new Error('Required parameter authorization was null or undefined when calling getZaehlerHistorie.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getZaehlerHistorie.');
        }
        if (meterNumber === null || meterNumber === undefined) {
            throw new Error('Required parameter meterNumber was null or undefined when calling getZaehlerHistorie.');
        }
        if (equipment === null || equipment === undefined) {
            throw new Error('Required parameter equipment was null or undefined when calling getZaehlerHistorie.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (anzahl !== undefined && anzahl !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>anzahl, 'anzahl');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }
        if (authorization !== undefined && authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/zaehlerstand/historie/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "meterNumber", value: meterNumber, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "equipment", value: equipment, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/`;
        return this.httpClient.request<Zaehlerhistorie>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt die letzten x Zaehlerstaende zurueck
     * 
     * @param xTenant 
     * @param authorization 
     * @param accountId 
     * @param meterNumber Valid characters are: \&#39;A-Z\&#39;,\&#39;0-9\&#39; and \&#39;-./_&#x60;Ä\&#39;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getZaehlerHistorieEuro(xTenant: string, authorization: string, accountId: string, meterNumber: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<ZaehlerhistorieEuro>;
    public getZaehlerHistorieEuro(xTenant: string, authorization: string, accountId: string, meterNumber: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ZaehlerhistorieEuro>>;
    public getZaehlerHistorieEuro(xTenant: string, authorization: string, accountId: string, meterNumber: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ZaehlerhistorieEuro>>;
    public getZaehlerHistorieEuro(xTenant: string, authorization: string, accountId: string, meterNumber: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getZaehlerHistorieEuro.');
        }
        if (authorization === null || authorization === undefined) {
            throw new Error('Required parameter authorization was null or undefined when calling getZaehlerHistorieEuro.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getZaehlerHistorieEuro.');
        }
        if (meterNumber === null || meterNumber === undefined) {
            throw new Error('Required parameter meterNumber was null or undefined when calling getZaehlerHistorieEuro.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }
        if (authorization !== undefined && authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/zaehlerstand/historie/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "meterNumber", value: meterNumber, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/euro`;
        return this.httpClient.request<ZaehlerhistorieEuro>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sucht alle zur Kundenummer zugehoerigen Zaehlernummern zusammen
     * E2E test in LoadAndChangeZaehlerstandLoginPRIVTest and LoadAndChangeZaehlerstandLoginGEWETest
     * @param xTenant 
     * @param authorization 
     * @param accountId 
     * @param changeableOnly Gibt an, ob die Ergebnisliste gefiltert werden soll (wenn der Zaehler nicht mehr erfasst werden kann: 28-Tage-Regel)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getZaehlerIdentifier(xTenant: string, authorization: string, accountId: string, changeableOnly?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<Array<ZaehlerIdentifier>>;
    public getZaehlerIdentifier(xTenant: string, authorization: string, accountId: string, changeableOnly?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<ZaehlerIdentifier>>>;
    public getZaehlerIdentifier(xTenant: string, authorization: string, accountId: string, changeableOnly?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<ZaehlerIdentifier>>>;
    public getZaehlerIdentifier(xTenant: string, authorization: string, accountId: string, changeableOnly?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getZaehlerIdentifier.');
        }
        if (authorization === null || authorization === undefined) {
            throw new Error('Required parameter authorization was null or undefined when calling getZaehlerIdentifier.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getZaehlerIdentifier.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (changeableOnly !== undefined && changeableOnly !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>changeableOnly, 'changeableOnly');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }
        if (authorization !== undefined && authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/zaehlerstand/identifier/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<Array<ZaehlerIdentifier>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Prueft, ob die angegebene Kombination aus Zaehlernummer und Vertragskontonummer zum angegebenen Datum ein intelligentes Messsystem ist.
     * 
     * @param xTenant 
     * @param checkIdentificationData Combination for identification
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postCheckSmartMeter(xTenant: string, checkIdentificationData: CheckIdentificationData, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<StatusResponse>;
    public postCheckSmartMeter(xTenant: string, checkIdentificationData: CheckIdentificationData, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<StatusResponse>>;
    public postCheckSmartMeter(xTenant: string, checkIdentificationData: CheckIdentificationData, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<StatusResponse>>;
    public postCheckSmartMeter(xTenant: string, checkIdentificationData: CheckIdentificationData, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling postCheckSmartMeter.');
        }
        if (checkIdentificationData === null || checkIdentificationData === undefined) {
            throw new Error('Required parameter checkIdentificationData was null or undefined when calling postCheckSmartMeter.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/checkSmartMeter`;
        return this.httpClient.request<StatusResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: checkIdentificationData,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Saves meterreading anonymously
     * Saves meterreading anonymously
     * @param xTenant 
     * @param anonymousMeterreading zaehlerstand fuer nicht angemeldeten nutzer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postMeterreadingAnonymously(xTenant: string, anonymousMeterreading: AnonymousMeterreading, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<Zaehlerstand>;
    public postMeterreadingAnonymously(xTenant: string, anonymousMeterreading: AnonymousMeterreading, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Zaehlerstand>>;
    public postMeterreadingAnonymously(xTenant: string, anonymousMeterreading: AnonymousMeterreading, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Zaehlerstand>>;
    public postMeterreadingAnonymously(xTenant: string, anonymousMeterreading: AnonymousMeterreading, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling postMeterreadingAnonymously.');
        }
        if (anonymousMeterreading === null || anonymousMeterreading === undefined) {
            throw new Error('Required parameter anonymousMeterreading was null or undefined when calling postMeterreadingAnonymously.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/anonymous/meterreading`;
        return this.httpClient.request<Zaehlerstand>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: anonymousMeterreading,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fügt einen Zaehlerstand hinzu
     * E2E test in LoadAndChangeZaehlerstandLoginPRIVTest and LoadAndChangeZaehlerstandLoginGEWETest
     * @param xTenant 
     * @param authorization 
     * @param accountId 
     * @param zaehlerstand zaehlerstand
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postZaehler(xTenant: string, authorization: string, accountId: string, zaehlerstand: Zaehlerstand, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<Zaehlerstand>;
    public postZaehler(xTenant: string, authorization: string, accountId: string, zaehlerstand: Zaehlerstand, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Zaehlerstand>>;
    public postZaehler(xTenant: string, authorization: string, accountId: string, zaehlerstand: Zaehlerstand, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Zaehlerstand>>;
    public postZaehler(xTenant: string, authorization: string, accountId: string, zaehlerstand: Zaehlerstand, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling postZaehler.');
        }
        if (authorization === null || authorization === undefined) {
            throw new Error('Required parameter authorization was null or undefined when calling postZaehler.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling postZaehler.');
        }
        if (zaehlerstand === null || zaehlerstand === undefined) {
            throw new Error('Required parameter zaehlerstand was null or undefined when calling postZaehler.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }
        if (authorization !== undefined && authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/zaehlerstand/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<Zaehlerstand>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: zaehlerstand,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aendert einen Zaehlerstand
     * 
     * @param xTenant 
     * @param authorization 
     * @param accountId 
     * @param meterNumber Valid characters are: \&#39;A-Z\&#39;,\&#39;0-9\&#39; and \&#39;-./_&#x60;Ä\&#39;
     * @param zaehlerstand zaehlerstand
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putZaehler(xTenant: string, authorization: string, accountId: string, meterNumber: string, zaehlerstand: Zaehlerstand, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public putZaehler(xTenant: string, authorization: string, accountId: string, meterNumber: string, zaehlerstand: Zaehlerstand, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public putZaehler(xTenant: string, authorization: string, accountId: string, meterNumber: string, zaehlerstand: Zaehlerstand, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public putZaehler(xTenant: string, authorization: string, accountId: string, meterNumber: string, zaehlerstand: Zaehlerstand, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling putZaehler.');
        }
        if (authorization === null || authorization === undefined) {
            throw new Error('Required parameter authorization was null or undefined when calling putZaehler.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling putZaehler.');
        }
        if (meterNumber === null || meterNumber === undefined) {
            throw new Error('Required parameter meterNumber was null or undefined when calling putZaehler.');
        }
        if (zaehlerstand === null || zaehlerstand === undefined) {
            throw new Error('Required parameter zaehlerstand was null or undefined when calling putZaehler.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }
        if (authorization !== undefined && authorization !== null) {
            localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=UTF-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/zaehlerstand/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "meterNumber", value: meterNumber, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: zaehlerstand,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
