import { Inject, Injectable } from "@angular/core";
import { ContractDetails, ContractLevel } from "../../../assets/js/com/ts_api_client";
import { CUSTOMERMODE } from "../enums/customerMode";
import moment from 'moment';
import { PRICE_CAP_TOKEN, PRICE_CAP_VALID_UNTIL } from '../configurations/price-cap.configuration.token';
import { PriceCapValues } from '../configurations/price-cap.configuration';

@Injectable({
  providedIn: 'root'
})

export class PriceCapService {
  constructor(@Inject(PRICE_CAP_TOKEN) private priceCaps: Array<PriceCapValues>, @Inject(PRICE_CAP_VALID_UNTIL) private priceCapValidUntil: Date) {}

  public getPriceForDivision(division: string, customerMode: CUSTOMERMODE): number {
    const priceInfos: PriceCapValues = this.priceCaps.find(priceCap => priceCap.division === division);
    return customerMode === CUSTOMERMODE.PRIV ? priceInfos.priceBrutto : priceInfos.priceNetto;
  }

  public getContractAffectedByPriceCap(contract: ContractDetails, customerMode: CUSTOMERMODE): boolean {

    if (moment().isAfter(this.priceCapValidUntil)) {
      return false; // price cap is expired
    }

    const divisionPriceCap = this.priceCaps.find(
      priceCap => priceCap.division === contract.division,
    );

    if (!divisionPriceCap) {
      return false; // no price cap found or price cap is expired
    }

    const priceCustomerMode = customerMode === CUSTOMERMODE.PRIV ? divisionPriceCap.priceBrutto : divisionPriceCap.priceNetto;
    const currentLevel: ContractLevel = contract.contractLevels.find(level => level.contractLevelNumber === '1');

    if (!currentLevel) {
      return false; // no matching contract level found
    }

    const workPrice: number = customerMode === CUSTOMERMODE.PRIV ? currentLevel.workGross : currentLevel.workNet;

    // return true if work price is higher than price cap
    return workPrice > priceCustomerMode;
  }
}
