/**
 * NLB-GW
 * Diese fantastische API stellt verschiedene Services fuer den neuen RheinEnergie Online Service zur Verfuegung.  Insbesondere sind hier Services zum Zugriff auf das SAP iCos implementiert. 
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Username und Passwort, um sich anzumelden
 */
export interface ActivationData { 
    /**
     * IdP activation code
     */
    code?: string;
    token?: string;
    /**
     * SAP registration code
     */
    registerCode?: string;
}

