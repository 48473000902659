import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { TenantService } from '../../services/tenant.service';
import { DropdownItem } from '../../models/dropdownItem';
import { VerbrauchstypPipe } from '../../pipes/verbrauchstyp.pipe';
import { ZaehlerIdentifier, Zaehlerhistorie } from '../../../../assets/js/com/ts_api_client';
import { BdoApiService } from '../../services/bdo-api.service';
import { CustomerStoreService } from '../../services/customer-store.service';
import { VERBRAUCHSTYP } from '../../enums/verbrauchstyp.enum';
import { LOADING_STATE } from '../../enums/loadingState.enum';
import { TrackingService } from '../../services/tracking.service';
import { TRACKING } from '../../enums/trackingParts.enum';
import { NavigationState } from '../../models/navigationState';
import { Utilities } from '../../../shared/utils/utilities';
import { DOCUMENT, Location } from '@angular/common';
import { ReadonlyTenantsService } from '../../services/readonly-tenants.service';

@Component({
  selector: 'bdo-meterreadings',
  templateUrl: './meterreadings.component.html',
  styleUrls: ['./meterreadings.component.scss']
})
export class MeterreadingsComponent implements OnInit {
  @ViewChild('addReadingSection') addReadingSection: ElementRef;
  public state: LOADING_STATE = LOADING_STATE.LOADING;
  public LoadingState = LOADING_STATE;
  public accountId: string;
  public meterDropdown: Array<DropdownItem>;
  public selectedMeter: string;
  public meterHistory: Zaehlerhistorie;
  public sparteEnum = VERBRAUCHSTYP;
  public addMeterreadingShown: boolean = false;
  public submittedMeterNumber: string;
  public selectedDate: Date;
  public selectedItem: string;
  public isEditMode: boolean = false;
  public meterIdentifiers: ZaehlerIdentifier[];
  public disabled: boolean = this.readonlyTenantsService.isCurrentTenantReadonly();

  constructor(
    public tenantService: TenantService,
    private apiService: BdoApiService,
    private customerStore: CustomerStoreService,
    private verbrauchstypPipe: VerbrauchstypPipe,
    private trackingService: TrackingService,
    private location: Location,
    private readonlyTenantsService: ReadonlyTenantsService,
    @Inject(DOCUMENT) private document: any
  ) { }

  ngOnInit() {
    this.trackingService.postTracking(TRACKING.LOCATION.METERREADINGS, TRACKING.ACTION.ENTER);
    this.accountId = this.customerStore.getAccountId();
    const currentState: NavigationState = Utilities.getStateOfCurrentRoute(this.location);
    this.submittedMeterNumber = currentState?.meterNumber || '';
    this.getMeteridentifiers();
  }

  getMeteridentifiers() {
    this.state = LOADING_STATE.LOADING;
    this.apiService.getZaehlerIdentifiers(false)
      .subscribe(
        {
          next: (res) => {
            this.state = LOADING_STATE.IDLE;
            this.updateDropdown(res);
            this.meterIdentifiers = res.filter(item => !item.inaktiv);
            this.addMeterreadingShown = this.meterIdentifiers?.length > 0;
            // Open form directly when arriving from siminvoice
            const currentState: NavigationState = Utilities.getStateOfCurrentRoute(this.location);
            if (currentState?.context) {
              this.toggleAddMeterreading(false);
            }
          },
          error: () => {
            this.state = LOADING_STATE.ERROR;
          }
        });
  }

  /** Takes a list of identifiers and maps it to a list of Dropdown-Items with labels:  */
  updateDropdown(meteridentifiers: Array<ZaehlerIdentifier>) {
    if (!meteridentifiers || meteridentifiers.length === 0) {
      this.state = LOADING_STATE.IDLE;
      this.meterDropdown = [];
      return;
    }
    // if a meternumber was submitted, set this meternumber to selected meter
    let currentMeterNumber = meteridentifiers[0].meterNumber;
    let currentEquiment = meteridentifiers[0].equipment;
    if (this.submittedMeterNumber.length > 0) {
      const meteridentifier = meteridentifiers.find(meter => meter.meterNumber === this.submittedMeterNumber);
      currentMeterNumber = meteridentifier?.meterNumber;
      currentEquiment = meteridentifier?.equipment;
    }

    this.selectedItem = this.createDropdownBoxValue(currentMeterNumber, currentEquiment);
    this.updateMeters(this.selectedItem);

    this.meterDropdown = meteridentifiers.map(val => {
      let renderedLabel = this.verbrauchstypPipe.transform(val.sparte) + '-Nr. ' + val.meterNumber;
      if (val.inaktiv) {
        renderedLabel += ' (inaktiv)';
      }
      return { 'label': renderedLabel, 'value': this.createDropdownBoxValue(val.meterNumber, val.equipment) };
    });
  }

  createDropdownBoxValue(meterNumber: string, equipment: string): string {
    return meterNumber + '#' + equipment;
  }

  updateMeters(selectedValue: string, forced: boolean = false) {
    const meterUID = selectedValue.split('#', 2);
    const meterNumber = meterUID[0];
    const equipment = meterUID[1];

    // Prevent loop with same data
    if (this.selectedMeter === meterNumber && !forced) {
      return;
    }
    this.selectedItem = this.createDropdownBoxValue(meterNumber, equipment);
    this.selectedMeter = meterNumber;

    this.state = LOADING_STATE.LOADING;
    this.resetData();
    this.apiService.getZaehlerStandHistorie(meterNumber, equipment)
      .subscribe(
        {
          next: (res) => {
            this.meterHistory = res;
            this.state = LOADING_STATE.IDLE;
          },
          error: () => {
            this.state = LOADING_STATE.ERROR;
          }
        });
  }

  resetData() {
    this.meterHistory = {};
  }

  toggleAddMeterreading(meterreadingChanged: boolean) {
    this.isEditMode = !this.isEditMode;
    // Refresh history
    if (meterreadingChanged) {
      this.updateMeters(this.selectedItem, true);
    }
    // Refresh date
    if (!this.isEditMode) {
      this.selectedDate = new Date(Date.now());
    }
  }

  onEdit(readingDate: Date) {
    this.selectedDate = readingDate;
    this.isEditMode = true;

    // This only scrolls if element is already visible/there. For init we use the bdoScrollOnInitDirective
    this.document.getElementById('addReadingSection')?.scrollIntoView({ behavior: 'smooth' });
  }
}
