/**
 * NLB-GW
 * Diese fantastische API stellt verschiedene Services fuer den neuen RheinEnergie Online Service zur Verfuegung.  Insbesondere sind hier Services zum Zugriff auf das SAP iCos implementiert. 
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Base Tariff Information combined with extra info from new customers
 */
export interface TariffData { 
    /**
     * Zip Code / PLZ
     */
    postCode?: string;
    /**
     * energy contract id / strommodul-id (frontend: ext-id); will be written to PowerModID in SAP / ISU
     */
    powerModId?: string;
    /**
     * energy contract begin (frontend: begin); only necessary for power mod hash calculation
     */
    powerModBegin?: string;
    /**
     * tracking id for affiliate partner / affiliateId
     */
    resellerId?: string;
    /**
     * order id for affiliate partner
     */
    orderId?: string;
    /**
     * campaign id indicating through which channel the order was referred, e.g. seo, sea, display, direct, affiliate ...
     */
    campaignId?: string;
    /**
     * Hash: SHA256 key, generated from a constant string. Hash for electricity \'SALT\' + product-e value + tarif-e value. Hash for gas \'SALT\' + product-g value + tarif-g value.
     */
    hash?: string;
    /**
     * annual consumption for given tariff
     */
    annualConsumption?: string;
    /**
     * e -> electricity; g -> gas; type of contract
     */
    type?: string;
    /**
     * PRIV -> private customer; GEWE -> business customer
     */
    mode?: string;
    /**
     * division of product e.g. S4 for power, G6 for gas or T4 for water
     */
    divisionId?: string;
    /**
     * productId / productId for a given tariff
     */
    productId?: string;
    /**
     * human readable form of contract with options
     */
    tariff?: string;
    /**
     * web tariff name from Tarifberater for contract e.g. Tarif für Freiheitsliebende, Tarif für Heimatverbundene
     */
    tbTariffName?: string;
    /**
     * annual consumption for given tariff
     */
    annualConsumptionNt?: string;
}

