import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { BdoApiService } from '../../services/bdo-api.service';
import { TenantService } from '../../services/tenant.service';
import { CustomerStoreService } from '../../services/customer-store.service';
import { LOADING_STATE } from '../../enums/loadingState.enum';
import { LoginService } from '../../../login/login.service';
import { ALLOWED_KEYS, StorageService } from '../../services/storage.service';
import { AccountInfo, Address, AddressData, FullAddress } from '../../../../assets/js/com/ts_api_client';
import { AuthDataStorage } from '../../models/AuthData.storage';
import { TranslateService } from '@ngx-translate/core';
import { Environment } from '../../../../environments/environment';
import { TENANT } from '../../enums/tenant.enum';
import { Utilities } from '../../../shared/utils/utilities';

export interface AccountWithAddress {
  accountId: string;
  displayAddress: string;
}

@Component({
  selector: 'bdo-contract-overview',
  templateUrl: './contract-overview.component.html',
  styleUrls: ['./contract-overview.component.scss']
})
export class ContractOverviewComponent implements OnInit {
  public selectedAccount: AccountWithAddress;
  public state: LOADING_STATE = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;
  public errorMessage: string = null;
  public meterNumber: string = null;
  public address: AddressData;
  public canAddTariff: boolean = !(Environment.tenant === TENANT.StadtwerkeLeichlingen);
  public Utilities = Utilities;

  constructor(
    public tenantService: TenantService,
    private apiService: BdoApiService,
    private customerStore: CustomerStoreService,
    private loginService: LoginService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.state = LOADING_STATE.LOADING;
    const authData = StorageService.getAuthData();
    let selectedAccountId = this.customerStore.getAccountId();

    this.loginService.isLoggedIn$.pipe(
      first(),
    ).subscribe({ next: (loggedIn) => {
      if (loggedIn && authData) { // should not happen - clean up anonymous data if already loggedIn
        StorageService.clearValues([ALLOWED_KEYS.AUTH_DATA]);
      }
      if (loggedIn && selectedAccountId && this.customerStore.currentAccountIdIsInactive === false) {
        this.loggedInFlow(selectedAccountId);
      } else if (authData) {
        this.anonymousFlow(authData);
      } else {
        // no selected account id
        this.state = LOADING_STATE.ERROR;
        this.errorMessage = this.translateService.instant('contract.overview.error.noActiveAccount');
      }
    } });
  }

  setSelectedAccountWithAddress(accountId: string, address: Address) {
    this.address = {
      cityName: address.street?.city?.name,
      houseNum: address.houseNum,
      postCode: address.street?.city?.postCode,
      streetName: address.street?.name
    };
    this.selectedAccount = {
      'accountId': accountId,
      'displayAddress': address.street.name + ' ' + address.houseNum + ', ' +
        address.street.city.postCode + ' ' + address.street.city.name
    };
  }

  private anonymousFlow(authData: AuthDataStorage) {
    this.meterNumber = authData?.checkIdentificationData?.meterNumberOrRegisterCode;
    this.apiService.getAddressAnonymous(authData?.checkIdentificationData?.accountId, this.meterNumber)
      .subscribe({
        next: (address: FullAddress) => {
          this.setSelectedAccountWithAddress(authData?.checkIdentificationData?.accountId, address.premiseAddress);
          this.state = LOADING_STATE.IDLE;
        },
        error: () => {
          this.state = LOADING_STATE.ERROR;
          this.errorMessage = this.translateService.instant('general.error.error');
        }
    });
  }

  private loggedInFlow(selectedAccountId: string) {
      // user is logged in and selected an accountId on dashboard
      this.apiService.getAccountInfo(selectedAccountId)
        .subscribe({
          next: (resAccountInfo: AccountInfo) => {
            this.setSelectedAccountWithAddress(selectedAccountId, resAccountInfo.address);
            this.state = LOADING_STATE.IDLE;
          },
          error: () => {
            this.state = LOADING_STATE.ERROR;
            this.errorMessage = this.translateService.instant('general.error.error');
          }
      });
  }



}
