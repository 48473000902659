/**
 * NLB-GW
 * Diese fantastische API stellt verschiedene Services fuer den neuen RheinEnergie Online Service zur Verfuegung.  Insbesondere sind hier Services zum Zugriff auf das SAP iCos implementiert. 
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PollAnswer { 
    pollId?: number;
    /**
     * accountId of existing contract, also knows as VK
     */
    accountId?: string;
    /**
     * Path where the poll was triggered
     */
    path?: string;
    questionNo?: number;
    /**
     * Point in the application where the poll was triggered
     */
    triggerPoint?: string;
    /**
     * The user\'s answer to the question. May be a numeric value, a boolean value or a free text depending on the question type
     */
    answer?: string;
}

