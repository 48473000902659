
<h3 class="bdo-container bdo-space--bottom">
  {{ 'billing.plan.title' | translate }}
</h3>

<div class="bdo-container--single">
  <div class="re-copytext-with-margin">
    {{ 'billing.edit.description' | translate }}
  </div>
</div>

<div class="bdo-container__without-margin-small bdo-loading__wrapper re-mb-3"
    [class.bdo-loading__wrapper--height]="state === LoadingState.LOADING">
  <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
  <div class="re-bg-white re-px-20 re-py-30 md:re-px-30">
    <div class="re-summary-row">
      <div>{{ 'general.customerData.account' | translate }}</div>
      <div>{{accountId}}</div>
    </div>
    <div class="re-summary-row">
      <div>{{ 'general.tariff.deliveryAddress' | translate }}</div>
      <div>{{address?.streetName}} {{address?.houseNum}}, {{address?.postCode}} {{address?.cityName}}</div>
    </div>
    <div class="re-summary-row">
      <div>{{ 'billing.plan.cycle' | translate }}</div>
      <div>{{detailedBillingPlan?.billingPlanCycleText}}</div>
    </div>
    <div class="re-summary-row re-m-0">
      <div>{{ 'billing.plan.nextSettlement' | translate }}</div>
      <div>{{detailedBillingPlan?.validTo | date}}</div>
    </div>
  </div>

  <h4 class="bdo-container--single bdo-space--bottom re-mt-60">
    {{ 'billing.plan.monthlyAmount' | translate }}
  </h4>

  <!-- show errors on all backend errors -->
  <bdo-info-box class="bdo-container--single" *ngIf="state === LoadingState.ERROR">
    {{ 'general.error.noDataFound' | translate }}
  </bdo-info-box>

  <bdo-info-box *ngIf="detailedBillingPlan?.noChange"
    [type]="'info'"
    data-qa="info-box-noChange">
    <span [innerHTML]="'billing.check.error.amountNotChangeable' | translate"></span>
  </bdo-info-box>

  <!-- Abschlagsfälligkeiten und Preise -->
  <bdo-edit-card
    *ngIf="detailedBillingPlan?.billingPlanItemSet?.length > 0"
    data-qa="billingplan-edit"
    (edit)="isInEditMode = true"
    [isEditable]="!isInEditMode"
    [disabled]="detailedBillingPlan?.noChange">
    <ng-template [ngIf]="state !== LoadingState.ERROR && !isInEditMode">
      <!-- Abschlagsfälligkeit -->
      <div *ngIf="detailedBillingPlan">
        <h5 class="re-mb-20 md:re-mb-30">{{ 'billing.edit.title' | translate }}</h5>
        <div class="re-mb-30 re-flex re-flex-col md:re-flex-row">
          <div class="re-mr-10">{{ 'billing.budgetBillingDueDate' | translate }}</div>
          <div class="bold" [innerHTML]="'billing.plan.dayOfMonth' | translate: { day: detailedBillingPlan.billingPlanDay }"></div>
        </div>

        <!-- Sparten einzeln aufgelistet -->
        <div class="re-mb-30 md:re-mb-5 ">
          <div *ngFor="let item of detailedBillingPlan.billingPlanItemSet">
            <div class="re-mt-20 re-flex re-flex-col">
              <!-- show division with icon -->
              <div class="re-flex re-flex-row re-justify-start">
                <div class="re-w-20 re-h-20 re-mr-5">
                  <svg-icon [src]="tariffAdvisorService.getPathForIcon(item.division, true)"></svg-icon>
                </div>
                <div class="re-text-sm bold re-mb-5">{{item.division | verbrauchstyp}}</div>
                <div class="re-text-sm bold re-ml-auto">{{item.amount | currency:'EUR'}}</div>
              </div>
            </div>
          </div>
        </div>

        <hr class="re-mt-30 re-mb-20 md:re-mb-10 re-border-t re-border-monochrome-medium-light">

        <!-- Betrag ingesamt -->
        <div class="re-flex re-flex-col" [attr.data-qa]="'billing_sum'">
          <h5 class="re-ml-auto">{{total | currency:'EUR'}}</h5>
          <div class="re-text-xs re-ml-auto">{{ 'contract.details.billingPerMonth' | translate }}</div>
        </div>

        <div *ngIf="detailedBillingPlan.eligiblyChanged" class="re-mt-45">
          <bdo-info-box [type]="'info'" [marginBottom]="false">
            <span [innerHTML]="'billing.changeInFuture'
              | translate : { date:detailedBillingPlan.eligibleChangeDate | date }"
            ></span>
          </bdo-info-box>
        </div>

      </div>
    </ng-template>

    <bdo-billing-edit *ngIf="state !== LoadingState.ERROR && isInEditMode"
      [detailedBillingPlan]="detailedBillingPlan"
      (done)="onEditDone($event)"
    ></bdo-billing-edit>
  </bdo-edit-card>
</div>

<ng-template [ngIf]="state !== LoadingState.LOADING && state !== LoadingState.ERROR">
  <h4 class="bdo-container--single bdo-space--bottom re-mt-60">
    {{ 'billing.plan.nextMaturities' | translate }}
  </h4>

  <!-- DESKTOP VIEW -->
  <div class="bdo-table tablet-up-only">
    <table class="table--desktop">
      <thead>
      <tr>
        <th class="col--bigger">{{ 'billing.plan.claims' | translate }}</th>
        <th>{{ 'general.tariff.dueOn' | translate }}</th>
        <th class="bdo-text--right">{{ 'billing.plan.grossInEuro' | translate }}</th>
      </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="detailedBillingPlan?.nextBillingPlanPayments?.length">
          <tr *ngFor="let date of detailedBillingPlan.nextBillingPlanPayments" data-qa="future_billings">
            <!-- Abrechnungstyp -->
            <td>{{ 'billing.plan.amountClaims' | translate }}</td>
            <!-- Abrechnungsdatum -->
            <td>{{date.dateDue | date}}</td>
            <!-- Abrechnungsdatum -->
            <td class="bdo-text--right">{{date.amount | number:'1.2-2' }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <!-- MOBILE VIEW -->
  <div class="bdo-table mobile-only">
    <div class="bdo-tile re-mb-3" *ngFor="let date of detailedBillingPlan.nextBillingPlanPayments" data-qa="future_billings">
      <div class="bdo-container__content">

        <!-- Abrechnungstyp -->
        <div class="re-mb-15">
          <div class="bold">{{ 'billing.plan.claims' | translate }}</div>
          <div>{{ 'billing.plan.amountClaims' | translate }}</div>
        </div>

        <!-- Abrechnungsdatum -->
        <div class="re-mb-15">
          <div class="bold">{{ 'general.tariff.dueOn' | translate }}</div>
          <div>{{ date.dateDue | date }}</div>
        </div>

        <!-- Summe -->
        <div>
          <div class="bold">{{ 'billing.plan.grossInEuro' | translate }}</div>
          <div>{{ date.amount | number:'1.2-2' }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="bdo-container__without-margin-small">
    <bdo-no-data
      *ngIf="detailedBillingPlan?.nextBillingPlanPayments.length <= 0"
      [text]="'dashboard.paymentTile.noOpenPayments' | translate"
    ></bdo-no-data>
  </div>
</ng-template>


<div class="bdo-container--single">
  <bdo-promo-link-wrapper
    [promoLinkDataSet]="promoLinkDataSet"
    [inContainer]="true"
  ></bdo-promo-link-wrapper>
</div>

<bdo-faqs
  [keyName]="'billing.faqs'"
  [params]="{ contactFormLink: tenantService.getCurrentTenantData().hrefContactForm}"
></bdo-faqs>
