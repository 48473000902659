import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { of, Subscription, timer } from 'rxjs';
import { map, pluck, switchMap } from 'rxjs/operators';
import { LoginService } from '../../../../login/login.service';
import { defaultValidatorProxy } from '../../../../shared/validators/default-validator-proxy';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { RegistrationData } from '../../../../../assets/js/com/ts_api_client';
import { htmlTagValidator } from '../../../../shared/validators/htmltag-validator';
import { INPUT_TYPE } from '../../../enums/inputType.enum';
import { passwordValidator } from '../../../../shared/validators/password-validator';
import { QuickRegisterFormtype } from '../../../../shared/formtypes/quick-register.formtype';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-quick-register-form',
  templateUrl: './quick-register-form.component.html',
  styleUrls: ['./quick-register-form.component.scss']
})
export class QuickRegisterFormComponent implements OnInit, OnDestroy {
  @Input() personalDataForm: FormGroup;
  @Input() registrationData: RegistrationData;
  @Input() isRequired: boolean = false;
  public INPUT_TYPE = INPUT_TYPE;
  public state: LOADING_STATE = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;
  public registerForm = new FormGroup<QuickRegisterFormtype>({
    tosConfirmed: new FormControl(null, { }),
    username: new FormControl('', {
      asyncValidators: [
        (control) => {
          return timer(1000).pipe(
            switchMap(() => {
                if (!this.personalDataForm || !control.value) {
                  return of(null);
                }
                return this.loginService.checkUsernameAvailable(control.value).pipe(
                  pluck('available'),
                  map((available) => {
                    return available ? null : { notValid: this.translateService.instant('register.error.usernameAlreadyExists') };
                  }));
              },
            ));
        }
      ]
    }),
    passwordGroup: new FormGroup({
      password: new FormControl('', { }),
      confirmPassword: new FormControl('', { })
    })
  });
  private subscriptions = new Subscription();

  constructor(
    public loginService: LoginService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    if (this.registrationData) {
      this.registerForm.controls.username.setValue(this.registrationData.username);
      this.registerForm.controls.passwordGroup.controls.password.setValue(this.registrationData.password);
      this.registerForm.controls.passwordGroup.controls.confirmPassword.setValue(this.registrationData.password);
    }

    this.registerForm?.controls?.tosConfirmed?.setValidators((formControl: FormControl) => {
      return (this.registerForm?.controls?.username?.value || this.isRequired)
      ? Validators.requiredTrue(formControl)
      : null;
    });
    this.registerForm?.controls?.username?.setValidators(
      [
        defaultValidatorProxy(Validators.pattern('^[a-zA-Z0-9\._@-]+$'), this.translateService.instant('register.error.invalidCharacters')),
        defaultValidatorProxy(Validators.minLength(5), this.translateService.instant('general.validator.minLength', { numberOfCharacters: 5 })),
        defaultValidatorProxy(Validators.maxLength(40), this.translateService.instant('general.validator.maxLength', { numberOfCharacters: 40 })),
        this.isRequired ? defaultValidatorProxy(Validators.required, this.translateService.instant('register.username.required')) : null
      ].filter(Boolean),
    );
    this.registerForm?.controls?.passwordGroup?.controls?.password?.setValidators([
      htmlTagValidator(this.translateService),
      passwordValidator(this.translateService),
      (formControl) => {
        if (this.registerForm?.controls?.username.value || this.isRequired) {
          return !formControl.value ? { required: this.translateService.instant('register.password.required') } : null;
        }
      }
    ]);
    this.registerForm?.controls?.passwordGroup?.controls?.confirmPassword?.setValidators([
      (formControl) => {
        if ((this.isRequired || this.registerForm?.controls?.username?.value) && formControl.value?.length === 0) {
          return { required: this.translateService.instant('register.password.reenterRequired') };
        }
        return null;
      },
      () => {
        const pwGroup = this.registerForm?.controls.passwordGroup;
        if (pwGroup?.controls?.password?.value !== pwGroup?.controls?.confirmPassword?.value) {
          return { passwordsDoNotMatch: this.translateService.instant('register.error.passwordsDontMatch') };
        }
        return null;
      }
    ].filter(Boolean)); // remove all falsy values from an array

    this.subscriptions.add(this.registerForm.controls.username.valueChanges.subscribe({ next: () => {
      this.registerForm.controls.tosConfirmed.updateValueAndValidity();
      this.registerForm.controls?.passwordGroup?.controls?.password?.updateValueAndValidity();
    } }));
    this.subscriptions.add(this.registerForm?.controls?.passwordGroup?.controls?.password?.valueChanges.subscribe({ next: () => {
      this.registerForm.controls?.passwordGroup?.controls?.confirmPassword?.updateValueAndValidity();
    } }));

    this.registerForm.updateValueAndValidity();

    this.personalDataForm?.addControl('registrationData', this.registerForm);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
