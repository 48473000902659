import { AfterViewInit, Directive, ElementRef } from "@angular/core";

@Directive({
  selector: '[bdoScrollOnInit]'
})
export class ScrollOnInitDirective implements AfterViewInit {

  constructor(private elRef:ElementRef) {}

  ngAfterViewInit() {
    this.elRef.nativeElement.scrollIntoView();
  }
}
