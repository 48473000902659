/**
 * NLB-GW
 * Diese fantastische API stellt verschiedene Services fuer den neuen RheinEnergie Online Service zur Verfuegung.  Insbesondere sind hier Services zum Zugriff auf das SAP iCos implementiert. 
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TariffTileInfo { 
    /**
     * overline text for tariff tile e.g. \'mehr sparen\'
     */
    overline?: string;
    /**
     * indicates of tariff tile is highlighted
     */
    isHighlighted?: boolean;
    /**
     * badge text of tile e.g. \'unsere Empfehlung\'
     */
    badgeText?: string;
    /**
     * color string of badge of tariff tile e.g. \'green\' | \'red\' | \'blue\'
     */
    badgeColor?: string;
    /**
     * divider text of tile e.g. \'inklusive Neukundenbonus\'
     */
    dividerText?: string;
    /**
     * flag text of tile e.g. \'100 Euro Bonus\'
     */
    flagText?: string;
    /**
     * path to frontend icon
     */
    iconPath?: string;
    /**
     * path to frontend icon mobile
     */
    iconMobilePath?: string;
}

