<form #editBillingForm="ngForm"
      bdoScrollToInvalidControl
      [bdoScrollToInvalidControlForm]="editBillingForm"
      (ngSubmit)="saveBilling()"
      >
  <div class="re-form-item-container">
    <bdo-info-box *ngIf="stateGlobal === LoadingState.ERROR">
      {{ 'general.error.error' | translate }}
    </bdo-info-box>
    <bdo-info-box  *ngIf="detailedBillingPlan?.noChange">
      {{ 'billing.error.noChange' | translate }}
    </bdo-info-box>
  </div>

  <div class="re-form-item-container">
    <div class="re-relative" [class.bdo-loading__wrapper--height]="stateGlobal === LoadingState.LOADING">
      <bdo-loading *ngIf="stateGlobal === LoadingState.LOADING"></bdo-loading>
      <div *ngIf="stateGlobal !== LoadingState.LOADING">
        <div class="re-relative re-mb-3"
            *ngIf="detailedBillingPlan && !detailedBillingPlan.noChange">

          <div data-qa="billingDayDropdown">
            <h5 class="re-mb-30">{{ 'billing.edit.title' | translate }}</h5>
            <div class="re-form-item-container">
              {{ 'billing.edit.changeable' | translate }}
            </div>
            <div class="bdo-form-item-container">
              <bdo-dropdown
                *ngIf="curSelectedDay"
                [data]="billingDayDropdown"
                [selected]="curSelectedDay"
                [labelText]="'billing.budgetBillingDueDate' | translate"
                (itemSelected)="curSelectedDay = $event"
              >
              </bdo-dropdown>
            </div>
          </div>

          <div *ngFor="let billingByDivision of detailedBillingPlan.billingPlanItemSet">
            <hr class="re-border-t re-border-monochrome-medium-light re-my-45">
            <bdo-billing-edit-division
              [billingByDivision]="billingByDivision"
              [billingPlan]="detailedBillingPlan"
              [parentFormgroup]="formgroup"
              [globalLoadingState]="stateGlobal"
            ></bdo-billing-edit-division>
          </div>

          <div *ngIf="detailedBillingPlan?.billingPlanItemSet.length > 1">
            <hr class="re-mt-45 re-mb-15 re-border-t re-border-monochrome-medium-light">
            <div class="re-flex">
              <span class="re-flex-grow">{{ 'billing.plan.total' | translate }}</span>
              <span>{{newTotalAmount | currency: 'EUR'}}</span>
            </div>
          </div>
        </div>

        <div class="re-mt-45 md:re-mt-60 re-mb-15 re-clear-both">
          <button class="bdo-button-primary bdo-button--text" type="submit">
            {{ 'general.action.save' | translate }}
          </button>
        </div>

        <button class="bdo-nolink" type="button" (click)="done.emit(false)">
          <span class="bold">{{ 'general.action.cancel' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</form>

<bdo-simple-text-popup [isVisible]="savingDone" *ngIf="savingDone"
  data-qa="success"
  (closePopupEvent)="closeMessage()"
  [title]="'general.action.changesSaved' | translate"
  [icon]="'success'"
>
  <bdo-customer-satisfaction-poll
  [pollId]="1"
  [triggerPoint]="'Abschlag_anpassen'"
  >
    <div class="re-copytext-with-margin">
      {{ 'billing.success.description' | translate }}
    </div>
    <bdo-home-link></bdo-home-link>

  </bdo-customer-satisfaction-poll>
</bdo-simple-text-popup>
