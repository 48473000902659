/**
 * NLB-GW
 * Diese fantastische API stellt verschiedene Services fuer den neuen RheinEnergie Online Service zur Verfuegung.  Insbesondere sind hier Services zum Zugriff auf das SAP iCos implementiert. 
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * person data
 */
export interface Person { 
    /**
     * firstname of person
     */
    firstname?: string;
    /**
     * lastname of person
     */
    lastname?: string;
    /**
     * birthdate of person
     */
    birthdate?: string;
    /**
     * id for title of academic title of that person
     */
    academicTitleId?: string;
}

