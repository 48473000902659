import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { ApiModule, Configuration, ConfigurationParameters } from '../assets/js/com/ts_api_client';
import { Environment, URLs } from '../environments/environment';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { AppComponent } from './app.component';
import { BDOModule } from './bdo/bdo.module';
import { UnitPrettyPipe } from './bdo/pipes/unit-pretty.pipe';
import { ZaehlwerktypPipe } from './bdo/pipes/zaehlwerktyp.pipe';
import { checkMaintenance } from './initializer/check-maintenance.initializer';
import { LoginModule } from './login/login.module';
import { SharedModule } from './shared/shared.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateModule } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { NgxTranslateModule } from './translate/translate.module';
import { translationsReady } from './initializer/translate-ready.initializer';
import { DateDeserializerInterceptor } from "./shared/interceptors/date-deserializer.interceptor";
import { WebComponentsWrapperModule } from './web-components-wrapper/web-components-wrapper.module';

registerLocaleData(localeDe);

// configuring backend basePath for generated Code
export function apiConfigFactory (): Configuration {
  const params: ConfigurationParameters = {
    // set configuration parameters here.
    'basePath': URLs.backend
  };
  return new Configuration(params);
}

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    BDOModule,
    FormsModule,
    NativeDateModule,
    MatDatepickerModule,
    BrowserAnimationsModule,
    LoginModule,
    ApiModule.forRoot(apiConfigFactory),
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    NgxTippyModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxTranslateModule,
    WebComponentsWrapperModule,
    ],
  providers: [
    ZaehlwerktypPipe,
    UnitPrettyPipe,
    {
      provide: LOCALE_ID, useValue: 'de-DE'
    },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['LL', 'DD.MM.YYYY', 'D.M.YYYY'],
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'EEEEE',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
    { provide: APP_BASE_HREF, useValue: Environment.baseHref },
    {
      provide: APP_INITIALIZER,
      useFactory: checkMaintenance,
      deps: [Injector],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: translationsReady,
      deps: [Injector],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateDeserializerInterceptor,
      multi: true
    },
    provideEnvironmentNgxMask(maskConfig)
  ],
  bootstrap: [AppComponent]
})

export class AppModule {

}
