<div class="re-mt-60 lg:re-min-h-350" *ngIf="showPoll && maxQuestions > 0; else overwrittenContent">
  <p [class.re-invisible]="activeQuestion !== 0"
    [innerHTML]="'satisfactionPoll.description' | translate">
  </p>

  <div class="re-my-10 re-flex">
    <hr class="re-border-t re-border-primary re-w-{{activeQuestion + 1}}/{{maxQuestions}}" [class.re-w-full]="activeQuestion+1 === maxQuestions">
    <hr class="re-border-t re-border-monochrome-medium-light re-w-{{maxQuestions - (activeQuestion + 1)}}/{{maxQuestions}}">
  </div>

  <div *ngIf="activeQuestion < maxQuestions && poll; else thanks">
    <div class="re-mb-10">
      {{ 'satisfactionPoll.questionNumber' | translate:
         { currentQuestion: (activeQuestion + 1).toString(), maxQuestion: maxQuestions.toString() } }}
    </div>

    <bdo-poll-question
      *ngIf="activeQuestion >= 0"
      [questionData]="poll?.questions[activeQuestion]"
      (sendAnswer)="onQuestionComplete($event)"
    ></bdo-poll-question>
  </div>
  <ng-template #thanks>
    <bdo-poll-done>
      <ng-container *ngTemplateOutlet="overwrittenContent"></ng-container>
    </bdo-poll-done>
  </ng-template>
</div>

<ng-template #overwrittenContent>
  <ng-content></ng-content>
</ng-template>
